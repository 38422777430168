import React, { FC, useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import FormGroupDate from '../../../form/FormGroupDate'
import RangeStepSection from '../../../form/RangeStepSection'
import SelectInput from '../../../form/SelectInput'
import {
  VISIT_ACTION_TYPE_ID,
  VISIT_CUSTOMER_CARE,
  VISIT_DURATION,
  VISIT_MARKET,
  VISIT_MARKET_OPTIONS,
  VISIT_PERSON_ID,
  VISIT_REPORT_CUSTOMER_CARE_TYPES,
  VISIT_REPORT_RANGE_INPUT_MAX,
  VISIT_VISIT_DATE,
} from '../../../../constants/visitReport'
import DynamicFormattedMessage from '../../../common/ui/DynamicFormattedMessage'
import Checkbox from '../../../form/Checkbox'
import WysiwygSection from '../../../form/WysiwygSection'
import { useIsTabDisabled } from '../../../../hooks/clientVisitReport/UseIsTabDisabled'
import {
  DATEPICKER_TIME_FORMAT,
  DYNAMIC_BUTTONS_INPUT_TYPE,
  RANGE_INPUT_DEFAULT,
  RANGE_INPUT_MIN,
  RANGE_INPUT_STEP,
} from '../../../../enums/common'
import { useLoadData } from '../../../../hooks/UseLoadData'
import { getContactTypes } from '../../../../services/visitReportsService'
import { getContactTypesValue } from '../../../../store/selectors/sharedDataSelectors'
import { getContactPersons } from '../../../../services/appointmentsService'
import { getClientContactPersons } from '../../../../store/selectors/clientContactPersonsSelectors'
import {
  getMinHour,
  isGreaterThanOpeningHours,
  isSmallerThanClosingHours,
} from '../../../../utils/appointments'
import { dateInterval, getClosingHours } from '../../../../utils/helpers'
import { ICustomTabForm, TMarket } from '../../../../interfaces/IVisitReports'
import Alerts from '../../../alerts/Alerts'
import DynamicButtons from '../../../form/DynamicButtons'

export const VisitReportDefaultFields: FC<ICustomTabForm> = ({
  clientId,
  defaultData,
  visitDate,
  setVisitDate,
}) => {
  const intl = useIntl()
  const isDisabled = useIsTabDisabled()
  const { register, errors, control, setValue, getValues } = useFormContext()

  const [visiteDuration, setVisiteDuration] = useState(RANGE_INPUT_DEFAULT)
  const [maxDuration, setMaxDuration] = useState(VISIT_REPORT_RANGE_INPUT_MAX)
  const [hoursBeforeClose, setHoursBeforeClose] = useState(
    VISIT_REPORT_RANGE_INPUT_MAX
  )

  const contactTypes = useLoadData(() => getContactTypes(), {
    fetchFromRedux: true,
    reduxSelector: getContactTypesValue,
  })
  const contactPersons = useLoadData(() => getContactPersons(clientId), {
    fetchFromRedux: true,
    reduxSelector: getClientContactPersons,
    reduxStorePath: clientId?.toString(),
  })

  const invalidTime =
    !isGreaterThanOpeningHours(new Date()) ||
    !isSmallerThanClosingHours(new Date())
  const currentTime = invalidTime ? getMinHour(new Date()) : new Date()

  const date = {
    id: VISIT_VISIT_DATE,
    value: visitDate.startDate !== '' ? visitDate.startDate : currentTime,
    label: `form.field.${VISIT_VISIT_DATE}`,
    setValue: setVisitDate,
    classes: 'inputField inputDate datepickerLeft',
    error: errors.visitDate,
    displayTime: true,
    format: DATEPICKER_TIME_FORMAT,
    maxDate: new Date(),
    control,
    rules: {
      required: true,
    },
  }

  const visiteDurationFinal = {
    id: VISIT_DURATION,
    name: VISIT_DURATION,
    value: visiteDuration,
    setValue: setVisiteDuration,
    defValue: RANGE_INPUT_DEFAULT,
    step: RANGE_INPUT_STEP,
    minValue: RANGE_INPUT_MIN,
    maxValue: maxDuration,
    hasLabel: true,
    control,
    error: errors.duration,
    rules: {
      required: false,
    },
  }

  useEffect(() => {
    const closingHours = date.value && getClosingHours(date.value as Date)

    setHoursBeforeClose(dateInterval(date.value, closingHours))
  }, [date.value])

  useEffect(() => {
    setMaxDuration(Math.min(hoursBeforeClose, VISIT_REPORT_RANGE_INPUT_MAX))
  }, [hoursBeforeClose])

  useEffect(() => {
    if (getValues(visiteDurationFinal.id) > maxDuration) {
      setValue(visiteDurationFinal.id, maxDuration)
      setVisiteDuration(maxDuration)
    }
  }, [maxDuration, setValue, visiteDurationFinal.id, getValues])

  const marketOptions = useMemo(
    () =>
      VISIT_MARKET_OPTIONS.map((option: TMarket) => ({
        value: intl.formatMessage({
          id: `form.field.market.${option}.label`,
        }),
        label: intl.formatMessage({
          id: `form.field.market.${option}.label`,
        }),
      })),
    [intl]
  )

  return (
    <section className="defaultFieldsSection customBorderTop pt2 mx0">
      <Alerts status={contactPersons.status} message={contactPersons.message} />
      <div className="row">
        <div className="colMd4 mb15 mandatory">
          <FormGroupDate startDate={date} startDateOnly={true} />
        </div>
        <div className="colMd4 relative inputRange pl3 pr4">
          <RangeStepSection range={visiteDurationFinal} />
        </div>
        <div className="colMd4 mb15">
          <SelectInput
            id={VISIT_PERSON_ID}
            name={VISIT_PERSON_ID}
            options={contactPersons.data}
            register={register}
            defaultValue={defaultData.contactPersonId}
          />
        </div>
      </div>
      <div className="row my15">
        <div className="px15 mb15 mr2">
          <DynamicButtons
            optionsArray={contactTypes.data}
            register={register({ required: true })}
            error={errors.contactTypeId}
            name={VISIT_ACTION_TYPE_ID}
            customClass="actionButtons mandatory"
            defaultValue={defaultData.contactTypeId || ''}
          />
        </div>
        <div className="px15 mb15">
          <DynamicButtons
            optionsArray={marketOptions}
            register={register}
            error={errors.marche}
            name={VISIT_MARKET}
            customClass="actionButtons"
            defaultValue={(defaultData.marche as string[])[0] || ''}
            inputType={DYNAMIC_BUTTONS_INPUT_TYPE.checkbox}
          />
        </div>
      </div>
      <div className={`${isDisabled ? 'dBlock mb2' : 'dNone'}`}>
        <DynamicFormattedMessage
          className="textPrimary mb1"
          id={`form.visitReport.${VISIT_CUSTOMER_CARE}`}
          tag="p"
        />
        <div>
          {VISIT_REPORT_CUSTOMER_CARE_TYPES.map((item) => (
            <Checkbox
              key={item}
              name={item}
              id={item}
              register={register}
              defaultValue={false}
              customClass="mb1 customCheckbox"
            />
          ))}
        </div>
      </div>
      <div className="row mb2">
        <div className="col12">
          <WysiwygSection
            control={control}
            errors={errors}
            defaultValue={defaultData.comment}
          />
        </div>
      </div>
    </section>
  )
}
