import { format } from 'date-fns'
import {
  IFilters,
  IOption,
  ISelectOption,
  ISelectOptionTypes,
} from '../interfaces/IFilters'
import {
  DATEPICKER_FORMAT2,
  GEOLOCATION,
  ITEMS_PER_PAGE,
} from '../enums/common'
import { CLIENT_SOURCE } from '../constants/userData'
import { IFormSelectOption, IMultipleSelectOption } from '../interfaces/IForms'
import {
  FILTER_DATE_FIELD,
  FILTER_LAST_VISIT_END_FIELD,
  FILTER_LAST_VISIT_START_FIELD,
  FILTER_RANGE_FIELD,
} from '../constants/filters'

export const createFiltersQuery = (
  filters: IFilters,
  offset: number,
  size: number,
  source?: CLIENT_SOURCE
) => {
  let query = `?size=${size}&offset=${offset * ITEMS_PER_PAGE}`
  let filterValue = ''

  if (source) {
    query += `&source=${source}`
  }

  Object.entries(filters).map(([key, val]) => {
    filterValue = val
    if (!val || val.length === 0) {
      return query
    }
    if (key === FILTER_DATE_FIELD) {
      query += `&date=${format(new Date(val), DATEPICKER_FORMAT2)}`

      return query
    }

    if (
      key === FILTER_LAST_VISIT_START_FIELD ||
      key === FILTER_LAST_VISIT_END_FIELD
    ) {
      filterValue = format(new Date(val), DATEPICKER_FORMAT2)
    }

    if (key === FILTER_RANGE_FIELD) {
      const geolocation = localStorage.getItem(GEOLOCATION)
        ? JSON.parse(localStorage.getItem(GEOLOCATION) || '')
        : null

      if (geolocation) {
        const { latitude, longitude } = geolocation

        query += `&currentLocationLatitude=${latitude}&currentLocationLongitude=${longitude}`
      }
    }

    if (Array.isArray(val)) {
      query += getQueryFromArray(val, key)

      return query
    }

    query += `&filters[${key}][]=${filterValue}`

    return query
  })

  return query
}

const getQueryFromArray = (array: (ISelectOption | string)[], key: string) => {
  return array
    .map(
      (item: ISelectOption | string) =>
        `&filters[${key}][]=${(item as ISelectOption).value || item}`
    )
    .join('')
}

export const prepareOptions = (options: IOption[]) => {
  return options.map(({ id, name }) => ({
    value: id.toString(),
    label: name,
  }))
}

export const prepareCappingOptions = (): IFormSelectOption[] => {
  const cappingOptions = []
  for (let i = 0; i <= 10; i += 1) {
    cappingOptions.push({
      name: `C${i}`,
      value: `${i}`,
    })
  }

  return cappingOptions
}

export const fieldHasValue = (
  field: string | Array<IMultipleSelectOption>
): boolean => {
  return (
    (typeof field !== 'object' && !!field) ||
    (typeof field === 'object' && field != null && !!field.length) ||
    field instanceof Date
  )
}

export const setGeolocationToLocalStorage = (data: Position) => {
  const geolocation = {
    latitude: data.coords.latitude.toFixed(4),
    longitude: data.coords.longitude.toFixed(4),
  }

  localStorage.setItem(GEOLOCATION, JSON.stringify(geolocation))
}

export const getGeolocationCurrentPosition = async (
  successCallback: PositionCallback,
  errorCallback?: PositionErrorCallback | undefined
) => navigator.geolocation.getCurrentPosition(successCallback, errorCallback)

export const prepareFieldSelectOptions = (
  field: ISelectOptionTypes[],
  values: string,
  filterByValue: boolean = true
) => {
  return field.filter(({ id, value, label, name }: ISelectOptionTypes) =>
    values
      .split(',')
      .includes(
        filterByValue
          ? value
            ? value.toString()
            : ''
          : label || id || name
          ? label?.toString() || id?.toString() || name?.toString()
          : ''
      )
  )
}

export const isPage = (pathname: string, page: string) =>
  pathname.indexOf(page) >= 0

export const clearSimpleSearchInput = (inputName: string) => {
  const simpleSearchInput = document.getElementsByClassName(
    inputName
  )[0] as HTMLInputElement
  simpleSearchInput.value = ''
}

export const isFiltersSet = (filters: IFilters) =>
  !!Object.entries(filters).find(([, value]) => fieldHasValue(value)) ||
  !!filters?.search
