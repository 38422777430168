import React, { FC } from 'react'
import Button from '../../common/ui/Button'
import DynamicFormattedMessage from '../../common/ui/DynamicFormattedMessage'

const ImageDeleteButton: FC<{
  showSimpleUploadButton: boolean
  onImageRemove: (index: number) => void
  index: number
  className?: string
  onImageDeleteCallback?: () => void
}> = ({
  showSimpleUploadButton,
  onImageRemove,
  index,
  className,
  onImageDeleteCallback,
}) => {
  return showSimpleUploadButton ? (
    <Button
      className="btn btnDelete btnDeleteImage dBlock"
      onClick={() => {
        onImageRemove(index)
      }}
    />
  ) : (
    <DynamicFormattedMessage
      id="clearPhoto.button.label"
      tag={Button}
      className={`btn btnDeleteRegular btnDeleteImageRegular dBlock ${className}`}
      onClick={() => {
        onImageRemove(index)
        if (onImageDeleteCallback) {
          onImageDeleteCallback()
        }
      }}
    />
  )
}

export default ImageDeleteButton
