import React, { FC } from 'react'
import Button from '../../common/ui/Button'
import DynamicFormattedMessage from '../../common/ui/DynamicFormattedMessage'

const ImageDeleteButton: FC<{
  labelId: string
  showSimpleUploadButton: boolean
  onClick: () => void
  className?: string
  isDisabled?: boolean
}> = ({ showSimpleUploadButton, onClick, className, labelId, isDisabled }) => {
  return showSimpleUploadButton ? (
    <Button
      className={`btn btnDelete btnDeleteImage dBlock ${className}`}
      onClick={onClick}
      disabled={isDisabled}
    />
  ) : (
    <DynamicFormattedMessage
      id={labelId}
      tag={Button}
      className={`btn btnDeleteRegular btnDeleteImageRegular dBlock ${className}`}
      onClick={onClick}
      disabled={isDisabled}
    />
  )
}

export default ImageDeleteButton
