import { IDashboardCaFilters, TQueryParams } from '../interfaces/IDashboard'
import { ISelectOption } from '../interfaces/IFilters'

export const isValidSelection = (values: IDashboardCaFilters) => {
  const selected = Object.entries(values).filter((value) => value[1].length > 0)

  return selected.length > 0
}

export const prepareFieldSelectOptions = (
  field: ISelectOption[],
  values: string,
  filterByValue: boolean = true
) => {
  return field.filter(({ value, label }) =>
    values.split(',').includes(filterByValue ? value : label)
  )
}

export const getQueryParams = (
  values: TQueryParams,
  labelFields: string[] = []
) =>
  Object.entries(values).reduce((acc, item) => {
    if (Array.isArray(item[1])) {
      if (!item[1]?.length) {
        return acc
      }

      const selectedOptions = item[1].map((group: any) =>
        !!labelFields?.length && labelFields.includes(item[0])
          ? group.label
          : group.value
      )
      acc.push(`${item[0]}=[${selectedOptions}]`)

      return acc
    }

    acc.push(`${item[0]}=${item[1]}`)
    return acc
  }, [] as string[])

export const setUrlParams = (values: TQueryParams) => {
  const query = getQueryParams(values).join('&')

  return new URLSearchParams(query)
}

export const getContactTypesFieldName = (label: string) =>
  label.split(' ').join('').toLowerCase()
