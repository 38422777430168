import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getJwtUserDetails, userIsAdmin } from '../utils/api'
import { useCachedData } from './UseCachedData'
import {
  loginSelectors,
  sharedDataSelectors,
  statusNetworkSelector,
} from '../store/selectors'
import { OFFLINE_EVENT_TAG } from '../enums/common'
import { statusNetworkReducerActions } from '../store/reducers/statusNetworkReducer'
import { sharedDataActions } from '../store/reducers/sharedDataReducer'
import { getLoggedUsername } from '../utils/user'
import { IUser } from '../interfaces/IUsers'

export const useAppHook = () => {
  const jwt = getJwtUserDetails()
  const eventFired = useRef(false)
  const { synchroData } = useCachedData()
  const { hasNetwork } = useSelector(
    statusNetworkSelector.getStatusNetworkValue
  )

  const isAuthenticated = useSelector(loginSelectors.getLoginValue)
  const selectedUserId = useSelector(sharedDataSelectors.getUserSavedData)
    ?.selectedUser
  const users = useSelector(sharedDataSelectors.getUsers)
  const dispatch = useDispatch()

  useEffect(() => {
    const synchroDataUnsynced =
      hasNetwork &&
      isAuthenticated &&
      'serviceWorker' in navigator &&
      'SyncManager' in window &&
      synchroData?.length
    if (!synchroDataUnsynced) return

    navigator.serviceWorker.ready.then((registration) => {
      setTimeout(() => {
        if (!eventFired.current) {
          registration.sync.register(OFFLINE_EVENT_TAG.backgroundForceSync)
          dispatch(statusNetworkReducerActions.resetOfflineMsg(true))
          eventFired.current = true
        }
      }, 3000)
    })

    if (!hasNetwork) eventFired.current = false
  }, [hasNetwork, synchroData, dispatch, isAuthenticated])

  useEffect(() => {
    if (
      !isAuthenticated ||
      !userIsAdmin() ||
      !users?.length ||
      selectedUserId ||
      !jwt
    )
      return

    dispatch(sharedDataActions.setSelectedUser(jwt?.id?.toString()))
  }, [dispatch, selectedUserId, jwt, users, isAuthenticated])

  useEffect(() => {
    if (!userIsAdmin() || !users?.length) return

    const loggedUsername = getLoggedUsername(users as IUser[], selectedUserId)
    dispatch(sharedDataActions.setLoggedUsername(loggedUsername))
  }, [dispatch, selectedUserId, users])
}
