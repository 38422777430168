import React, { FC } from 'react'
import DynamicFormattedMessage from '../../../common/ui/DynamicFormattedMessage'
import { IInformationDataRow } from '../../../../interfaces/IVisitReports'

export const InformationDataRow: FC<IInformationDataRow> = ({
  label,
  customClass = '',
  children,
}) => {
  return (
    <div className={`row mx0 mb1 wordBreakAll ${customClass}`}>
      <DynamicFormattedMessage
        id={`form.visitReport.information.${label}.label`}
        tag="p"
        className="colMd3 px0 py05 textPrimary fontBold"
      />
      {children}
    </div>
  )
}
