import { format } from 'date-fns'
import { FieldName, SetFieldValue, SetValueConfig } from 'react-hook-form'
import {
  DATE_REQUEST_FORMAT,
  INPUT_TYPE,
  RANGE_INPUT_MINUTES,
  REPORT_DATETIME_FORMAT,
} from '../enums/common'
import { getJwtUserDetails } from './api'
import {
  ISectionRowData,
  IViewReportValue,
  IVisitReport,
  IVisitReportForm,
  IVisitReportInformation,
  IVisitReportReset,
  TViewVisitReportRow,
  TVisitReportCurrentVersion,
} from '../interfaces/IVisitReports'
import { IOption } from '../interfaces/IFilters'
import {
  VISIT_ACTION_TYPE_ID,
  VISIT_DURATION,
  VISIT_PERSON_ID,
  VISIT_REPORT_CUSTOMER_CARE_TYPES,
  VISIT_ACTION_TYPE_3,
  VISIT_REPORT_RESET_EMPTY,
  VISIT_REPORT_RESET_NULL,
  VISIT_REPORT_RESET_FALSE,
  VISIT_REPORT_INPUT_MAX,
  VISIT_REPORT_CHECKBOXES,
  VISIT_REPORT_MAIN_CHECKBOXES,
  VISIT_REPORT_INT_INPUTS,
  VISIT_REPORT_TEXT_INPUTS,
  VISIT_DATE,
  VISIT_REPORT_DEFAULT,
  VISIT_SELLOUT_TYPE_3_INPUT_VISION,
  VISIT_SELLOUT_TYPE_3_INPUT_NEO,
  VISIT_SELLOUT_TYPE_4_INPUT,
  VISIT_REPORT_SUBSCRIBED,
  VISIT_DOCUMENTS_TYPE_8_INPUT,
  VISIT_REPORT_FORTH_PANEL_VALIDATION,
  VISIT_REPORT_THIRD_PANEL_VALIDATION,
  VISIT_REPORT_SECOND_PANEL_VALIDATION,
  VISIT_REPORT_RESET_EMPTY_ARRAY,
  VISIT_REPORT_CURRENT_VERSION,
  VISIT_SELLOUT_TYPE_3_INPUT_FSL,
  VISIT_SELLOUT_TYPE_5_INPUT,
  VISIT_REPORT_INPUT_MIN,
  VISIT_SELLOUT_TYPE_6_INPUT_FSL,
  VISIT_SELLOUT_TYPE_3_INPUT_QAC,
  VISIT_SELLOUT_TYPE_6_INPUT_FSL_IDENTIFICATION,
} from '../constants/visitReport'
import { filterClientData } from './clientData'
import {
  capitaliseString,
  getNWord,
  isEmptyObj,
  isFloatOrOutOfRange,
  timeConvert,
} from './helpers'
import { convertYesNoAnswer } from './data'

export const prepareRequestForNewVisitReport = (values: IVisitReportForm) => {
  const version: TVisitReportCurrentVersion = VISIT_REPORT_CURRENT_VERSION
  const actionType = values.contactTypeId
  const tabsEnabled = actionType !== VISIT_ACTION_TYPE_3

  const defaultStrToIntFields = [
    VISIT_PERSON_ID,
    VISIT_DURATION,
    VISIT_ACTION_TYPE_ID,
  ]

  const otherTabsStrToIntFields: (keyof IVisitReport)[] = [
    ...VISIT_REPORT_INT_INPUTS,
  ]
  const otherTabsStrFields: (keyof IVisitReport)[] = [
    ...VISIT_REPORT_TEXT_INPUTS,
  ]
  const otherTabsBoolFields: (keyof IVisitReport)[] = [
    ...VISIT_REPORT_CHECKBOXES,
  ]

  let strFields: (keyof IVisitReport)[] = []
  let strToIntFields = [...defaultStrToIntFields]
  let boolFields: (keyof IVisitReport)[] = []

  if (!tabsEnabled) boolFields = VISIT_REPORT_CUSTOMER_CARE_TYPES

  if (tabsEnabled) {
    strFields = [...otherTabsStrFields]
    strToIntFields = [...strToIntFields, ...otherTabsStrToIntFields]
    boolFields = [...otherTabsBoolFields]
  }

  const formFields = [...strToIntFields, ...strFields]

  const bodyValues: any = []
  formFields
    .filter(
      (key) =>
        values[key as keyof typeof values] !== '' &&
        values[key as keyof typeof values] !== null
    )
    .forEach((key) => {
      bodyValues[key] = strToIntFields.includes(key)
        ? parseInt(values[key as keyof typeof values] as string, 10)
        : values[key as keyof typeof values]
    })

  boolFields
    .filter((key) => !!values[key as keyof typeof values])
    .forEach((key) => {
      bodyValues[key] = values[key as keyof typeof values]
    })

  if (values.duration) {
    bodyValues.duration = +values.duration * 60
  }

  if (values.marche?.length) {
    bodyValues.marche = values.marche[0] as string
  }

  bodyValues.comment = values.comment
  bodyValues.visitDate = format(new Date(values.visitDate), DATE_REQUEST_FORMAT)

  return {
    ...bodyValues,
    userId: getJwtUserDetails().id,
    version,
  }
}

/**
 * Used to convert javascript datetime object from new visit report form for redux state.
 */
export const convertFormDatetime = (visitReport: IVisitReportForm) => {
  if (visitReport.visitDate) {
    return { ...visitReport, visitDate: JSON.stringify(visitReport.visitDate) }
  }

  return {
    ...visitReport,
  }
}

export const prepareOptions = (options: IOption[]) => {
  return options.map(({ id, name }) => ({
    value: id.toString(),
    name,
  }))
}

export const dataURItoFile = (dataURI: string, filename: string) => {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString
  if (dataURI.split(',')[0].indexOf('base64') >= 0)
    byteString = atob(dataURI.split(',')[1])
  else byteString = unescape(dataURI.split(',')[1])

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length)
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  return new File([ia], filename, { type: mimeString })
}

export const prepareContactTypesOptions = (options: IOption[]) =>
  options.map(({ name, id }) => ({
    label: name,
    value: id.toString(),
  }))

export const getActiveTab = (errorField: string) => {
  if (!errorField) return 0
  if (VISIT_REPORT_FORTH_PANEL_VALIDATION.includes(errorField)) return 3
  if (VISIT_REPORT_THIRD_PANEL_VALIDATION.includes(errorField)) return 2
  if (VISIT_REPORT_SECOND_PANEL_VALIDATION.includes(errorField)) return 1
  return 0
}

export const resetVisitReportFields: IVisitReportReset = (
  reset,
  setVisitDate,
  setValue
) => {
  reset()
  setVisitDate('')
  VISIT_REPORT_RESET_EMPTY.map((item) => setValue(item, ''))
  VISIT_REPORT_RESET_EMPTY_ARRAY.map((item) => setValue(item, []))
  VISIT_REPORT_RESET_NULL.map((item) => setValue(item, null))
  VISIT_REPORT_RESET_FALSE.map((item) => setValue(item, false))
}

export const validateNumberInput = (
  value: string,
  hasToBeValidated: boolean,
  inputMaxNo: number = VISIT_REPORT_INPUT_MAX,
  inputMinNo: number = VISIT_REPORT_INPUT_MIN
) => {
  if (!hasToBeValidated) return
  if (isFloatOrOutOfRange(value, inputMinNo, inputMaxNo))
    return `number.interval.${inputMinNo}-${inputMaxNo}`
  if (!!value && !(+value > 0 && +value <= inputMaxNo)) return 'number'
}

export const validateTextInput = (
  value: string,
  hasToBeValidated: boolean,
  inputMaxNo: number = VISIT_REPORT_INPUT_MAX
) => {
  if (hasToBeValidated && value?.length > inputMaxNo) return 'maxLength'
}

export const resetFieldsWithParentUnchecked = (
  values: IVisitReportForm,
  setValue: (
    name: FieldName<IVisitReportForm>,
    value: SetFieldValue<IVisitReportForm>,
    config?: SetValueConfig
  ) => void
) => {
  const uncheckedFields = VISIT_REPORT_MAIN_CHECKBOXES.filter(
    (checkbox) => !values[checkbox as keyof typeof values]
  )

  const resetValues: { [key: string]: any } = {
    [INPUT_TYPE.checkbox]: false,
    [INPUT_TYPE.hidden]: null,
    [INPUT_TYPE.text]: '',
  }

  uncheckedFields.forEach((uncheckedField) => {
    const fieldsWithParentUnchecked = document.querySelectorAll(
      `[data-parent=${uncheckedField}]`
    )

    fieldsWithParentUnchecked.forEach((field) => {
      const name =
        (field?.getAttribute('name') as keyof IVisitReportForm) ||
        (field?.getAttribute('data-name') as keyof IVisitReportForm)

      if (!name) return

      const type = field?.getAttribute('type')

      setValue(name, resetValues[type ?? INPUT_TYPE.text])
    })
  })
}

export const getViewVisitReportValues = (
  data: React.PropsWithChildren<TViewVisitReportRow>,
  items: (keyof TViewVisitReportRow)[],
  itemsType?: string
): IViewReportValue[] => {
  const reportItems = filterClientData(
    data,
    items,
    false
  ) as (keyof TViewVisitReportRow)[]

  return reportItems?.map((item) => {
    if (itemsType === VISIT_REPORT_DEFAULT) {
      return getViewVisitReportDefaultValues(data, item as keyof IVisitReport)
    }

    if (
      typeof data[item] === 'boolean' &&
      !item?.includes(VISIT_REPORT_SUBSCRIBED)
    ) {
      return {
        renderedValue: capitaliseString(convertYesNoAnswer(data, item)),
        item,
      }
    }

    return {
      renderedValue: data[item],
      item,
    }
  })
}

export const getViewVisitReportDefaultValues = (
  data: React.PropsWithChildren<IVisitReport>,
  item: keyof IVisitReport
) => {
  switch (item) {
    case VISIT_DATE:
      return {
        renderedValue: format(
          new Date(data[item] as string),
          REPORT_DATETIME_FORMAT
        ),
        item,
      }

    case VISIT_DURATION:
      return {
        renderedValue: timeConvert(+`${data[item]}` / RANGE_INPUT_MINUTES),
        item,
      }

    default:
      return {
        renderedValue: data[item],
        item,
      }
  }
}

export const getRowItemLabel = (
  item: keyof IVisitReport | keyof IVisitReportInformation,
  firstSectionRowItem: keyof IVisitReport | keyof IVisitReportInformation
) => {
  switch (item) {
    case firstSectionRowItem:
      return `form.visitReport.row.${firstSectionRowItem}.label`
    case VISIT_SELLOUT_TYPE_3_INPUT_NEO:
      return 'form.visitReport.row.productNeo.label'
    case VISIT_SELLOUT_TYPE_3_INPUT_VISION:
      return 'form.visitReport.row.productVision.label'
    case VISIT_SELLOUT_TYPE_3_INPUT_FSL:
      return `form.visitReport.row.${VISIT_SELLOUT_TYPE_3_INPUT_FSL}.label`
    case VISIT_SELLOUT_TYPE_3_INPUT_QAC:
      return `form.visitReport.row.${VISIT_SELLOUT_TYPE_3_INPUT_QAC}.label`
    case VISIT_SELLOUT_TYPE_6_INPUT_FSL:
      return `form.visitReport.row.${VISIT_SELLOUT_TYPE_6_INPUT_FSL}.label`
    case VISIT_SELLOUT_TYPE_6_INPUT_FSL_IDENTIFICATION:
      return `form.visitReport.row.${VISIT_SELLOUT_TYPE_6_INPUT_FSL_IDENTIFICATION}.label`
    case VISIT_SELLOUT_TYPE_4_INPUT:
    case VISIT_SELLOUT_TYPE_5_INPUT:
      return `form.field.visitReport.${VISIT_SELLOUT_TYPE_4_INPUT}.label`
    case VISIT_DOCUMENTS_TYPE_8_INPUT:
      return `form.field.visitReport.${VISIT_DOCUMENTS_TYPE_8_INPUT}.label`
    default:
      return `form.field.visitReport.${getNWord(item)
        .replace(/[0-9]/g, '')
        .toLowerCase()}.label`
  }
}

export const getLastVisitReportFieldValue = (
  informationData: IVisitReportInformation,
  typeOfField: keyof ISectionRowData
) =>
  informationData &&
  !isEmptyObj(informationData) &&
  Object.entries(informationData as IVisitReportInformation)
    .find((item) => item[0] === typeOfField)
    ?.pop()

export const prepareRequestForUploadImage = (
  values: IVisitReportForm,
  type: string
) => {
  const image = values[type as keyof typeof values] as string
  const imageContent = image?.length && JSON.parse(image)

  if (!imageContent || !imageContent?.dataURL?.length) return

  return {
    file: imageContent.dataURL
      ? dataURItoFile(imageContent.dataURL, imageContent.fileName)
      : undefined,
    type,
  }
}
