import { DatesSetArg } from '@fullcalendar/react'
import { api, getAuthHeader } from '../utils/api'
import { IRequest } from '../interfaces/IRequest'
import { IFilters, ISelectOption } from '../interfaces/IFilters'
import { createFiltersQuery, prepareOptions } from '../utils/filters'
import { IClientCalendarEvent } from '../interfaces/IClientDetails'
import {
  createAppointmentsFiltersQuery,
  createReportsFiltersQuery,
  prepareAppointmentsResponseForCalendar,
  prepareReportsResponseForCalendar,
} from '../utils/agenda'
import { API_PATHS } from '../enums/apiPaths'
import { formatDate } from '../utils/helpers'
import { CURRENT_DAY_DATETIME_FORMAT, INITIAL_OFFSET } from '../enums/common'
import { CLIENT_SOURCE } from '../constants/userData'

export const getClientsListService = (
  filters: IFilters,
  offset: number = INITIAL_OFFSET,
  size: number,
  source?: CLIENT_SOURCE
) => {
  const query = createFiltersQuery(filters, offset, size, source)

  const params: IRequest = {
    endpoint: `${API_PATHS.clients}${query}`,
    method: 'GET',
    headers: getAuthHeader(),
  }

  return api(params)
}

export const getClientsListServiceData = async (
  filters: IFilters,
  offset: number = 0,
  size: number
) => {
  const {
    body: { data },
  } = await getClientsListService(filters, offset, size)

  return data
}

export const getClientDetailsService = (clientId: string) => {
  const params: IRequest = {
    endpoint: `${API_PATHS.clients}/${clientId}`,
    method: 'GET',
    headers: getAuthHeader(),
  }

  return api(params)
}

export const getClientAppointments = (clientId: string) => {
  const params: IRequest = {
    endpoint: `${API_PATHS.clients}/${clientId}${API_PATHS.appointments}`,
    method: 'GET',
    headers: getAuthHeader(),
  }

  return api(params)
}

export const getClientReports = (clientId: string) => {
  const params: IRequest = {
    endpoint: `${API_PATHS.clients}/${clientId}${API_PATHS.reports}`,
    method: 'GET',
    headers: getAuthHeader(),
  }

  return api(params)
}

export const getLastOrderDate = async (clientId: string): Promise<string> => {
  const params: IRequest = {
    endpoint: `${API_PATHS.clients}/${clientId}${API_PATHS.lastOrder}`,
    method: 'GET',
    headers: getAuthHeader(),
  }

  const {
    body: { orderDate },
  } = await api(params)

  return formatDate(
    orderDate && new Date(orderDate),
    CURRENT_DAY_DATETIME_FORMAT,
    ''
  )
}

export async function getClientAppointmentsList(
  rangeInfo: DatesSetArg,
  userId: number | undefined
): Promise<IClientCalendarEvent[]> {
  const query = createAppointmentsFiltersQuery(rangeInfo)
  const params: IRequest = {
    endpoint: `${API_PATHS.users}/${userId}${API_PATHS.appointments}${query}`,
    method: 'GET',
    headers: getAuthHeader(),
  }

  const {
    body: { data },
  }: any = await api(params)

  return prepareAppointmentsResponseForCalendar(data)
}

export async function getClientReportsList(
  rangeInfo: DatesSetArg,
  userId: number | undefined
): Promise<IClientCalendarEvent[]> {
  const query = createReportsFiltersQuery(rangeInfo)
  const params: IRequest = {
    endpoint: `${API_PATHS.users}/${userId}${API_PATHS.visitReport}${query}`,
    method: 'GET',
    headers: getAuthHeader(),
  }

  const {
    body: { data },
  }: any = await api(params)

  return prepareReportsResponseForCalendar(data)
}

export async function getOptionsService(
  endpoint: string
): Promise<ISelectOption[]> {
  const params: IRequest = {
    endpoint: `/${endpoint}`,
    method: 'GET',
    headers: getAuthHeader(),
  }

  const {
    body: { data },
  }: any = await api(params)

  return prepareOptions(data)
}
