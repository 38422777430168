import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { useUserMedia } from '../../../hooks/UseUserMedia'
import {
  VISIT_DOCUMENTS,
  VISIT_DOCUMENTS_ROWS,
  VISIT_DOCUMENTS_TYPE_2,
  VISIT_DOCUMENTS_TYPE_2_INPUTS_1,
  VISIT_DOCUMENTS_TYPE_2_INPUTS_2,
  VISIT_DOCUMENTS_TYPE_2_ROW,
} from '../../../constants/visitReport'
import { ITabForm } from '../../../interfaces/IVisitReports'
import { VisitReportSection } from './commonComponents/VisitReportSection'
import { VisitReportSectionRow } from './commonComponents/VisitReportSectionRow'
import { VisitReportExtraFields } from './forthPanelComponents/VisitReportExtraFields'
import { useLoadData } from '../../../hooks/UseLoadData'
import { getContractTypes } from '../../../services/contractsServices'
import { getClientContractsData } from '../../../store/selectors/clientContractsSelectors'
import { statusNetworkSelector } from '../../../store/selectors'
import { UserMediaSection } from '../../common/ui/UserMediaSection'

export const VisitReportForthPanel: FC<ITabForm> = ({
  clientId,
  defaultData,
  updateForm,
}) => {
  const [isCameraOpen, setIsCameraOpen] = useState(false)
  const camera = useUserMedia()
  const { hasNetwork } = useSelector(
    statusNetworkSelector.getStatusNetworkValue
  )

  const { data: contractTypes } = useLoadData(
    () => getContractTypes(clientId),
    {
      dependencies: [clientId],
      fetchFromRedux: !!clientId && !hasNetwork,
      reduxSelector: getClientContractsData,
      reduxStorePath: `${clientId}.contractTypes`,
    }
  )

  return (
    <>
      <UserMediaSection
        camera={camera}
        isCameraOpen={isCameraOpen}
        setIsCameraOpen={setIsCameraOpen}
      />
      <VisitReportSection
        section={VISIT_DOCUMENTS}
        rows={VISIT_DOCUMENTS_ROWS}
        contractTypes={contractTypes}
        camera={camera}
        isCameraOpen={isCameraOpen}
        defaultData={defaultData}
        updateForm={updateForm}
      >
        <VisitReportSectionRow
          data={VISIT_DOCUMENTS_TYPE_2_ROW}
          customClass={VISIT_DOCUMENTS_TYPE_2}
          camera={camera}
          isCameraOpen={isCameraOpen}
          defaultData={defaultData}
          updateForm={updateForm}
        >
          <VisitReportExtraFields
            fields={VISIT_DOCUMENTS_TYPE_2_INPUTS_1}
            defaultData={defaultData}
            dataType={VISIT_DOCUMENTS_TYPE_2}
          />
          <VisitReportExtraFields
            fields={VISIT_DOCUMENTS_TYPE_2_INPUTS_2}
            defaultData={defaultData}
            dataType={VISIT_DOCUMENTS_TYPE_2}
          />
        </VisitReportSectionRow>
      </VisitReportSection>
    </>
  )
}
