import React from 'react'
import DynamicFormattedMessage from '../../../common/ui/DynamicFormattedMessage'
import { IVisitReportInformation } from '../../../../interfaces/IVisitReports'
import { InformationTarget } from './InformationTarget'
import { InformationFutureRdv } from './InformationFutureRdv'
import { InformationComment } from './InformationComment'
import { InformationLastVisitReport } from './InformationLastVisitReport'

export const LastVisitReportInformation = ({
  data,
  customClass = '',
  renderRdvButton = true,
}: {
  data: IVisitReportInformation
  customClass?: string
  renderRdvButton?: boolean
}) => {
  const {
    numberOfAppels,
    numberOfVisite,
    rdvDate,
    kamComment,
    dcrComment,
    lastVisiteDate,
    lastVisiteType,
    lastVisiteComment,
  } = data

  return (
    <section
      className={`informationSection customBorderTop textPrimary mb1 ${customClass}`}
    >
      <DynamicFormattedMessage
        id="form.section.information.title.label"
        tag="h3"
        className="wFull textUppercase mb15 pt1"
      />
      <InformationTarget
        numberOfAppels={numberOfAppels}
        numberOfVisite={numberOfVisite}
      />
      <InformationFutureRdv
        rdvDate={rdvDate}
        renderRdvButton={renderRdvButton}
      />
      <InformationComment label="commentKam" comment={kamComment as string} />
      <InformationComment
        label="commentDcr"
        comment={dcrComment as string}
        customClass="hasBorderBottom pb2"
      />
      <InformationLastVisitReport
        lastVisiteDate={lastVisiteDate}
        lastVisiteType={lastVisiteType}
        lastVisiteComment={lastVisiteComment}
      />
    </section>
  )
}
