import React, { ChangeEvent, FC, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import Checkbox from '../form/Checkbox'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'
import Button from '../common/ui/Button'
import { IVisitReportContactTypesForm } from '../../interfaces/IDashboard'
import { getContactTypesFieldName } from '../../utils/dashboard'

export const VisitReportsContactTypesForm: FC<IVisitReportContactTypesForm> = ({
  contactTypes,
  setIsErrorMessageVisible,
  setShouldRefetchUserActivity,
}) => {
  const form = useFormContext()
  const { handleSubmit, register, getValues } = form
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false)

  const checkIfFieldCheck = (values: { [key: string]: boolean }) => {
    const isFieldCheck = Object.values(values).some(
      (fieldValue) => fieldValue === true
    )

    setDisableSubmit(!isFieldCheck)
    setIsErrorMessageVisible(!isFieldCheck)
  }

  const onSubmit = () => {
    const fieldsValue = getValues()
    checkIfFieldCheck(fieldsValue)
    setShouldRefetchUserActivity((prevState) => !prevState)
  }

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    checkIfFieldCheck({
      ...getValues(),
      [`${event.target.name}`]: event.target.checked,
    })
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="dFlex alignItemsCenter flexWrap flexMdNowrap pr15"
    >
      <div className="dFlex flexWrap flexMdNowrap">
        {contactTypes?.data.map(({ value, label }) => {
          const fieldName = getContactTypesFieldName(label)

          return (
            <Checkbox
              key={value}
              name={fieldName}
              id={fieldName}
              register={register}
              defaultValue={true}
              customClass="py05"
              isLabelTranslated={false}
              labelText={label}
              onChange={handleCheckboxChange}
            />
          )
        })}
      </div>
      <DynamicFormattedMessage
        id="form.button.confirmation"
        tag={Button}
        type="submit"
        className={`btn btnPrimary my1 submitContactType ${
          disableSubmit ? 'btnDisabled' : ''
        }`}
        data-qa="submitContactType"
        disabled={disableSubmit}
      />
    </form>
  )
}
