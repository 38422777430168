import React, { FC } from 'react'
import { format } from 'date-fns-tz'
import { TModalFilters } from '../../interfaces/IFilters'
import { IMultipleSelectOption } from '../../interfaces/IForms'
import { REPORT_DATETIME_FORMAT } from '../../enums/common'
import {
  FILTER_APPOINTMENT_FIELD,
  FILTER_CAPPING_FIELD,
  FILTER_CLUSTER_FIELD,
  FILTER_DATE_FIELD,
  FILTER_APPOINTMENTS_FIELD,
  FILTER_LAST_VISIT_START_FIELD,
  FILTER_RANGE_FIELD,
  FILTER_SUBSCRIBE_COACH_FIELD,
  FILTER_SUBSCRIBE_COMPTOIR_FIELD,
  FILTER_TARGET_TYPE_2_FIELD,
  FILTER_VISITE_REPORT_FIELD,
  ADVANCED_SEARCH_SELECT_INPUT_FIELDS,
} from '../../constants/filters'
import {
  FILTERS_RADIO_OPTIONS_LIST,
  NON,
  OUI,
  OUI_NUMBER_VALUE,
  RANGE_LABEL,
} from '../../constants/form'
import { fieldHasValue } from '../../utils/filters'
import { useFiltersHook } from '../../hooks/accountsPortfolio/UseFiltersHook'

import Button from '../common/ui/Button'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'
import {
  FILTER_CLIENT_CURRENT_YEAR,
  FILTER_CLIENT_PREVIOUS_YEAR,
} from '../../constants/dashboard'
import { currentYearValue } from '../../utils/helpers'
import { TARGET_TYPE_2 } from '../../constants/userData'

export const ActiveFiltersSection: FC<
  TModalFilters<typeof ADVANCED_SEARCH_SELECT_INPUT_FIELDS>
> = ({
  setFilters,
  changePage,
  filters,
  fieldsData,
  resetRoute,
  containerClassName,
  callback,
}) => {
  const { resetFilters, resetFiltersOnSimpleSearch } = useFiltersHook(
    { setFilters, changePage, filters, fieldsData },
    callback
  )
  /**
   * Retrieve display filter value based on filter type
   *
   * @param filter
   * @param selectedValue
   */
  const filterValueDisplay = (
    filter: string,
    selectedValue: string | Array<IMultipleSelectOption>
  ) => {
    switch (filter) {
      case FILTER_APPOINTMENTS_FIELD:
      case FILTER_APPOINTMENT_FIELD:
      case FILTER_VISITE_REPORT_FIELD:
        return selectedValue ? '' : selectedValue
      case FILTER_RANGE_FIELD:
        return `${selectedValue} ${RANGE_LABEL}`
      case FILTER_LAST_VISIT_START_FIELD:
      case FILTER_DATE_FIELD:
        return format(
          new Date(selectedValue.toString()),
          REPORT_DATETIME_FORMAT
        )
      case FILTER_SUBSCRIBE_COMPTOIR_FIELD:
      case FILTER_SUBSCRIBE_COACH_FIELD:
        return selectedValue[0] === OUI_NUMBER_VALUE ? OUI : NON
      case FILTER_CLUSTER_FIELD:
      case FILTER_TARGET_TYPE_2_FIELD:
        return Array.isArray(selectedValue)
          ? selectedValue.map((item) => item.label).join(', ')
          : selectedValue
      case FILTER_CAPPING_FIELD:
        return `C${selectedValue}`
      case FILTER_CLIENT_CURRENT_YEAR:
      case FILTER_CLIENT_PREVIOUS_YEAR:
        return FILTERS_RADIO_OPTIONS_LIST.filter(
          (option) => option.value === selectedValue[0]
        )[0].label
      default:
        return selectedValue
    }
  }

  const date = new Date()
  const currentMonth = date.getMonth()
  const currentYear =
    currentMonth < 9 ? date.getFullYear() : date.getFullYear() + 1

  return (
    <div
      className={`pageFiltersActiveSearch col12 colXl10 colMd12 ${containerClassName}`}
    >
      <Button
        className="btn btnLink clearFilters pl0"
        data-qa="ctaClearFilters"
        onClick={() =>
          filters?.search
            ? resetFiltersOnSimpleSearch(resetRoute)
            : resetFilters(resetRoute)
        }
      >
        <DynamicFormattedMessage id="form.button.resetFilters" />
      </Button>
      <div className="filters">
        {filters &&
          Object.entries(filters).map(([filter, searchValue]) => {
            const fieldNotEmpty = fieldHasValue(searchValue)
            const display =
              fieldNotEmpty && filterValueDisplay(filter, searchValue)

            const filterWithYear = filter === TARGET_TYPE_2
            const yearValue = filterWithYear ? currentYear : currentYearValue

            const yearDisplayValue =
              filterWithYear || filter === FILTER_CLIENT_CURRENT_YEAR
                ? yearValue
                : yearValue - 1

            return (
              fieldNotEmpty && (
                <span className="filter" key={`${filter}_key`}>
                  <DynamicFormattedMessage
                    id={`form.field.${filter}${
                      filter === FILTER_APPOINTMENTS_FIELD ? 'Alt' : ''
                    }.label`}
                    values={{ year: yearDisplayValue }}
                  />
                  {display ? `: ${display};` : ';'}
                </span>
              )
            )
          })}
      </div>
    </div>
  )
}
