import React, { FC } from 'react'
import DynamicFormattedMessage from './DynamicFormattedMessage'
import { IPlaceholderMessageProps } from '../../../interfaces/ICommonComponents'
import { STATUS } from '../../../enums/common'

export const PlaceholderMessage: FC<IPlaceholderMessageProps<any>> = ({
  status,
  data,
  extraMessageLabel,
}) => {
  if (!data?.length && status !== STATUS.PENDING) {
    return (
      <section className="placeholderMessage textCenter pt3 pt5Sm">
        <span className="placeholderMessageIcon mb2" />
        <DynamicFormattedMessage
          id="noResults.label"
          tag="p"
          className="placeholderMessageLabel"
        />
        {extraMessageLabel && (
          <DynamicFormattedMessage
            id={extraMessageLabel}
            tag="p"
            className="placeholderMessageLabel"
          />
        )}
      </section>
    )
  }

  return <span />
}
