import React, { FC, useMemo } from 'react'
import { PageTitleSection } from '../components/common/ui/PageTitleSection'
import ClientCardsSection from '../components/clientsCardsSection/ClientsCardsSection'
import { ITEMS_PER_PAGE, STATUS } from '../enums/common'
import Loader from '../components/loader/Loader'
import Alerts from '../components/alerts/Alerts'
import { PageFiltersSection } from '../components/PageFiltersSection'
import { PaginationWrapper } from './PaginationWrapper'
import ModalContainer from '../components/modal/Modal'
import { FiltersModal } from '../components/modal/FiltersModal'
import { ACCOUNTS_PORTFOLIO } from '../constants/routes'
import { PlaceholderMessage } from '../components/common/ui/PlaceholderMessage'
import { StickyWrapper } from '../components/common/ui/StickyWrapper'
import { useLoadClientsHook } from '../hooks/UseLoadClientsHook'
import { ROUTES } from '../enums/routes'

const AccountsPortfolio: FC = () => {
  const {
    filters,
    totalCount,
    status,
    message,
    setFilters,
    changePage,
    toggleModal,
    isModalOpen,
    fieldsData,
    slicedData,
    offset,
    currentPage,
    waitToLoadClientData,
    placeholderMessageProps,
    clients,
    showClientsList,
    items,
    backRoute,
    getMapRoute,
  } = useLoadClientsHook()

  const isPaginationVisible = useMemo(
    () => !!clients?.length && items > ITEMS_PER_PAGE,
    [clients, items]
  )

  return (
    <section className="pageContentWrapper portfolioPage pl75">
      <div className="px3 py3">
        <PageTitleSection
          pageName={ACCOUNTS_PORTFOLIO}
          totalCount={totalCount}
        />
        <StickyWrapper>
          <Alerts status={status} message={message} />
        </StickyWrapper>
        <PageFiltersSection
          filters={filters}
          setFilters={setFilters}
          changePage={changePage}
          toggleModal={toggleModal}
          isModalOpen={isModalOpen}
          fieldsData={fieldsData}
          offset={offset}
          currentPage={currentPage}
          routes={{
            map: getMapRoute(),
          }}
          resetRoute={ROUTES.accountsPortfolio}
        />
        {slicedData && slicedData.length > 0 && (
          <ClientCardsSection
            data={slicedData}
            offset={offset}
            currentPage={currentPage}
            backRoute={backRoute}
          />
        )}
        {(waitToLoadClientData || (status && status === STATUS.PENDING)) && (
          <Loader />
        )}
        {!waitToLoadClientData && (
          <PlaceholderMessage {...placeholderMessageProps} />
        )}
        {isPaginationVisible && showClientsList && (
          <PaginationWrapper
            currentPage={currentPage}
            totalItemsCount={items}
            onChange={changePage}
            itemsPerPage={ITEMS_PER_PAGE}
          />
        )}
        <ModalContainer isOpen={isModalOpen}>
          <FiltersModal
            setFilters={setFilters}
            changePage={changePage}
            toggleModal={toggleModal}
            filters={filters}
            fieldsData={fieldsData}
          />
        </ModalContainer>
      </div>
    </section>
  )
}

export default AccountsPortfolio
