import React, { useState, useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'
import { TRAINING_TABLE_HEADERS } from '../../constants/tables'
import { Row } from '../../interfaces/IClientDetails'
import { IFormation } from '../../interfaces/IVisitReports'

interface VisitReportFormationTableProps {
  onFormationChange?: (isChecked: boolean) => void
  errorTrigger?: boolean
  onFormationSubmit?: (rows: IFormation[]) => void
  isDisabled?: boolean
}

const VisitReportFormationTable = ({
  onFormationChange,
  errorTrigger,
  onFormationSubmit,
  isDisabled,
}: VisitReportFormationTableProps) => {
  const [rows, setRows] = useState<Row[]>([])
  const [message, setMessage] = useState<string | null>(null)
  const [messageType, setMessageType] = useState<'Success' | 'Danger' | null>(
    null
  )
  const intl = useIntl()
  const prevRowsRef = useRef<IFormation[]>([])

  useEffect(() => {
    const storedFormations = localStorage.getItem('formations')

    if (storedFormations) {
      const parsedRows: Row[] = JSON.parse(storedFormations)
      setRows(parsedRows)
    }
  }, [])

  const handleCheckboxChange = (index: number) => {
    setRows((prevRows) => {
      const newRows = prevRows.map((row, i) =>
        i === index ? { ...row, checkboxChecked: !row.checkboxChecked } : row
      )
      const anyChecked = newRows.some(
        (row) => row.checkboxPresent && row.checkboxChecked
      )

      if (onFormationChange) {
        onFormationChange(anyChecked)
      }

      return newRows
    })
  }

  const processFormations = (formationRows: Row[]): IFormation[] => {
    const phaseCounter: { [key: string]: number } = {}

    const formations: IFormation[] = formationRows
      .filter(
        (row) => row.checkboxPresent && row.checkboxChecked && row.date === ''
      )
      .map((row) => {
        let phase = parseInt(row.phase, 10)
        const phaseKey = phase.toString()

        if (!phaseCounter[phaseKey]) {
          phaseCounter[phaseKey] = 0
        }

        phaseCounter[phaseKey] += 1

        let state
        if (phaseCounter[phaseKey] === 1) {
          state = 'partiel'
        } else if (phaseCounter[phaseKey] === 2) {
          state = 'complete'
        } else {
          state = row.state || 'complete'
        }

        const labelKey = `training.phase${row.phase}.label`
        const labelTranslation = intl.formatMessage({ id: labelKey })

        phase = phase === 0 ? 5 : phase

        return {
          label: labelTranslation,
          phase,
          state,
        }
      })

    const existingPhasesCount: { [key: string]: number } = {}
    formationRows.forEach((row) => {
      if (row.date && row.state) {
        const phase = parseInt(row.phase, 10)
        if (!existingPhasesCount[phase]) {
          existingPhasesCount[phase] = 0
        }
        existingPhasesCount[phase] += 1
      }
    })

    return formations.map((formation) => {
      const phase = formation.phase.toString()
      const existingCount = existingPhasesCount[phase] || 0
      const totalCount = phaseCounter[phase] + existingCount

      return {
        ...formation,
        state: totalCount === 1 ? 'partiel' : 'complete',
      }
    })
  }

  useEffect(() => {
    if (onFormationSubmit) {
      const processedRows = processFormations(rows)

      if (
        JSON.stringify(prevRowsRef.current) !== JSON.stringify(processedRows)
      ) {
        onFormationSubmit(processedRows)
        prevRowsRef.current = processedRows
      }
    }
    // eslint-disable-next-line
  }, [onFormationSubmit, rows])

  useEffect(() => {
    if (errorTrigger) {
      setMessage('form.submit.required.error')
      setMessageType('Danger')

      window.scrollTo({
        top: 0,
        behavior: 'auto',
      })
    }
  }, [errorTrigger])

  return (
    <div>
      {message && (
        <div
          className={`message alert${messageType} dFlex textPrimary textCenter alert alertNetwork justifyContentBetween mb15`}
        >
          <DynamicFormattedMessage id={message} />
        </div>
      )}
      <DynamicFormattedMessage
        id="table.header.trainingProgramme.label"
        tag="h3"
        className="dInlineBlock textCustomSm textUppercase textPrimary mb15 pb05"
      />
      <table className="customTable wFull mb3">
        <thead className="customTableHeader">
          <tr>
            {TRAINING_TABLE_HEADERS.map((headerName) => (
              <td key={headerName}>
                <DynamicFormattedMessage
                  id={`table.header.${headerName}.label`}
                  className="fontBold textUppercase textPrimary textCustomXs textLeft"
                />
              </td>
            ))}
          </tr>
        </thead>
        <tbody className="customTableBody">
          {rows.map((row, index) => (
            <tr key={row.id}>
              <td className="fontBold textUppercase textPrimary textCustomXs textLeft">
                {`${row.label} `}
                <DynamicFormattedMessage
                  id={`training.phase${row.phase}.label`}
                  className="fontBold textUppercase textPrimary textCustomXs textLeft"
                />
              </td>
              <td className="fontBold textUppercase textPrimary textCustomXs textLeft">
                {row.date
                  ? row.date
                  : row.checkboxPresent && (
                      <input
                        type="checkbox"
                        checked={row.checkboxChecked}
                        onChange={() => handleCheckboxChange(index)}
                        disabled={isDisabled}
                      />
                    )}
              </td>
              <td className="fontBold textUppercase textPrimary textCustomXs textLeft">
                {row.phase === '0' ? '' : row.state}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default VisitReportFormationTable
