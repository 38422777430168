import {
  generatePath,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useClientsDetails } from './UseClientDetails'
import { useClientAppointments } from './UseClientAppointments'
import { useClientReports } from './UseClientReports'
import { useLoadData } from '../UseLoadData'
import { getLastOrderDate } from '../../services/getClientsListService'
import { useInitiateVisitReport } from './UseInitiateVisitReport'
import { useInitiateContracts } from './UseInitiateContracts'
import { useInitiateHistory } from './UseInitiateHistory'
import { useInitiateAppointment } from './UseInitiateAppointment'
import { advancedFiltersInitialState } from '../../constants/filters'
import { IPageTitleProps } from '../../interfaces/ICommonComponents'
import {
  clientDetailsSelectors,
  statusNetworkSelector,
} from '../../store/selectors'
import { getCachedData, getCachedDataIds } from '../../utils/offline'
import { API_PATHS } from '../../enums/apiPaths'
import { IClientDetailsInfoProps } from '../../interfaces/IClientDetails'
import { ROUTES } from '../../enums/routes'
import { joinData } from '../../utils/helpers'
import {
  ACTION_FORMULAIRES,
  ACTION_HISTORIQUE,
  ACTION_NOUVEAU_RAPPORT,
  ACTION_NOUVEAU_RENDEZ_VOUS,
} from '../../constants/userData'

export const useClientDetailsPage = () => {
  const { clientId } = useParams()
  const { state } = useLocation()
  const history = useHistory()
  const { hasNetwork } = useSelector(
    statusNetworkSelector.getStatusNetworkValue
  )

  const [isClientCached, setIsClientCached] = useState(true)

  const { clientDetailsData, status, message } = useClientsDetails(
    clientId,
    setIsClientCached
  )
  const { clientAppointments } = useClientAppointments(clientId)
  const { clientReports } = useClientReports(clientId)
  const clientLastOrder = useLoadData(() => getLastOrderDate(clientId))

  // Initiate all calls for offline support
  const { isAppointmentDataStatusPending } = useInitiateAppointment(
    advancedFiltersInitialState
  )
  const {
    contactPersons,
    isVisitReportDataStatusPending,
  } = useInitiateVisitReport(clientId)
  const { isHistoryDataStatusPending } = useInitiateHistory(clientId)
  const { isContractsDataStatusPending } = useInitiateContracts(clientId)

  const [isModalOpen, setIsModalOpen] = useState(state?.uploadImageState)
  const [pageTitleProps, setPageTitleProps] = useState<IPageTitleProps>({})

  const [cachedData, setCachedData] = useState([])
  const cachedVisitReports = useMemo(
    () => getCachedDataIds(cachedData, API_PATHS.visitReport) as string[],
    [cachedData]
  )
  const cachedAppointments = useMemo(
    () => getCachedDataIds(cachedData, API_PATHS.appointments) as string[],
    [cachedData]
  )

  const {
    name,
    cipCode,
    address1,
    phoneNumber,
    gpsLatitude,
    gpsLongitude,
    zipCode,
    city,
    potentiel2,
  } = clientDetailsData

  const clientInfo: IClientDetailsInfoProps = {
    name,
    address1,
    phoneNumber,
    cipCode,
    zipCode,
    gpsLatitude,
    gpsLongitude,
    city,
    potentiel2,
  }

  const clientLocation = joinData([address1, zipCode, city])

  const hasOnlyOneInfoColumn = useMemo(() => potentiel2 === null, [potentiel2])

  const lastClientDetailsData = useSelector(
    clientDetailsSelectors.getClientDetailsValue
  )

  useEffect(() => {
    if (state?.offset >= 0) {
      setPageTitleProps({
        backRoute: state?.backRoute ?? generatePath(ROUTES.accountsPortfolio),
        backData: state?.offset,
      })
    }
  }, [state])

  useEffect(() => {
    if (hasNetwork) return

    getCachedData('api-cache', setCachedData)
  }, [hasNetwork])

  useEffect(() => {}, [pageTitleProps])

  const dataStatusPendingByAction = useMemo(() => {
    return {
      [ACTION_NOUVEAU_RENDEZ_VOUS]: isAppointmentDataStatusPending,
      [ACTION_NOUVEAU_RAPPORT]: isVisitReportDataStatusPending,
      [ACTION_HISTORIQUE]: isHistoryDataStatusPending,
      [ACTION_FORMULAIRES]: isContractsDataStatusPending,
    }
  }, [
    isAppointmentDataStatusPending,
    isVisitReportDataStatusPending,
    isHistoryDataStatusPending,
    isContractsDataStatusPending,
  ])

  return {
    history,
    state,
    clientId,
    name,
    cipCode,
    gpsLatitude,
    gpsLongitude,
    status,
    message,
    clientInfo,
    clientLocation,
    clientAppointments,
    clientReports,
    clientLastOrder,
    contactPersons,
    pageTitleProps,
    isClientCached,
    cachedVisitReports,
    cachedAppointments,
    lastClientDetailsData,
    isModalOpen,
    setIsModalOpen,
    hasOnlyOneInfoColumn,
    dataStatusPendingByAction,
  }
}
