import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import {
  IAdvancedSearchFormData,
  IFilters,
  IMapFilters,
} from '../../interfaces/IFilters'
import { clientsFiltersActions } from '../../store/reducers/filtersReducer'
import { convertFiltersDatetime } from '../../utils/data'
import { DATEPICKER_FORMAT, INITIAL_ACTIVE_PAGE } from '../../enums/common'
import {
  advancedFiltersInitialState,
  FILTER_APPOINTMENT_FIELD,
  FILTER_DATE_FIELD,
  FILTER_VISITE_REPORT_FIELD,
} from '../../constants/filters'
import { NO_ACTION_ERROR } from '../../constants/map'
import { useUrlParamsHook } from '../UseUrlParamsHook'

export const useMapFiltersForm = (
  filters: IFilters,
  setFilters: Dispatch<SetStateAction<IFilters>>
) => {
  const {
    register,
    handleSubmit,
    errors,
    control,
    getValues,
    reset,
    setError,
    watch,
    clearErrors,
  } = useForm<IAdvancedSearchFormData>({
    mode: 'onChange',
  })

  const dispatch = useDispatch()
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const urlValueAppointment = Boolean(
    params.has(FILTER_APPOINTMENT_FIELD)
      ? params.get(FILTER_APPOINTMENT_FIELD)
      : filters?.appointment
  )
  const urlValueVisiteReport = Boolean(
    params.has(FILTER_VISITE_REPORT_FIELD)
      ? params.get(FILTER_VISITE_REPORT_FIELD)
      : filters?.visiteReport
  )
  const urlValueDate = params.has(FILTER_DATE_FIELD)
    ? params.get(FILTER_DATE_FIELD)
    : filters?.date
  const [mapDate, setMapDate] = useState({
    startDate: urlValueDate ? new Date(urlValueDate) : '',
    endDate: '',
  })

  useEffect(() => {
    setMapDate({
      startDate: filters?.date ? new Date(filters?.date) : '',
      endDate: '',
    })
  }, [filters])

  const minDate = new Date()
  minDate.setMonth(minDate.getMonth() - 6)
  const maxDate = new Date()
  maxDate.setMonth(maxDate.getMonth() + 6)
  const mapStartDate = useMemo(() => {
    return {
      id: 'date',
      value: mapDate.startDate ? new Date(mapDate.startDate) : '',
      setValue: setMapDate,
      classes: 'inputField inputDate datepickerLeft',
      control,
      minDate,
      maxDate,
      filterTime: true,
      displayTime: false,
      format: DATEPICKER_FORMAT,
      error: errors.date,
    }
  }, [mapDate.startDate, control, minDate, maxDate, errors.date])

  const { setUrlQueryParams } = useUrlParamsHook()

  const onSubmit = async (values: IMapFilters) => {
    if (!values.date) {
      return
    }

    if (!values.appointment && !values.visiteReport) {
      return setFilters(() => {
        dispatch(
          clientsFiltersActions.setFilters(
            convertFiltersDatetime(advancedFiltersInitialState)
          )
        )

        return advancedFiltersInitialState
      })
    }

    setFilters(() => {
      const newFilters = {
        ...filters,
        date: values.date,
        appointment: values.appointment ? 1 : 0,
        visiteReport: values.visiteReport ? 1 : 0,
      }

      dispatch(
        clientsFiltersActions.setFilters(
          convertFiltersDatetime(newFilters as IFilters)
        )
      )
      dispatch(clientsFiltersActions.setSelectedPage(INITIAL_ACTIVE_PAGE))

      return newFilters as IFilters
    })

    setUrlQueryParams(
      {
        ...filters,
        ...{
          appointment: values.appointment ? 1 : 0,
          visiteReport: values.visiteReport ? 1 : 0,
          date: values.date,
        },
      },
      false
    )
  }

  const triggerSubmit = () => {
    clearErrors(NO_ACTION_ERROR)
    handleSubmit(onSubmit)()
  }

  const onDateChange = () => {
    if (getValues().visiteReport || getValues().appointment) {
      clearErrors(NO_ACTION_ERROR)
      handleSubmit(onSubmit)()
    }
  }

  return {
    onSubmit,
    triggerSubmit,
    mapStartDate,
    register,
    errors,
    handleSubmit,
    onDateChange,
    reset,
    setMapDate,
    getValues,
    mapDate,
    setError,
    watch,
    clearErrors,
    urlValueAppointment,
    urlValueVisiteReport,
  }
}
