import { useMemo } from 'react'
import { useLoadData } from '../UseLoadData'
import {
  getClientLastVisitReportData,
  getContactTypes,
} from '../../services/visitReportsService'
import { getContactPersons } from '../../services/appointmentsService'
import { clientContactPersonsActions } from '../../store/reducers/clientContactPersonsReducer'
import { sharedDataActions } from '../../store/reducers/sharedDataReducer'
import { getClientContactPersons } from '../../store/selectors/clientContactPersonsSelectors'
import { getContactTypesValue } from '../../store/selectors/sharedDataSelectors'
import { clientLastVisitReportActions } from '../../store/reducers/clientLastVisitReportReducer'
import { STATUS } from '../../enums/common'

export const useInitiateVisitReport = (clientId: string) => {
  const { data: contactTypes, status: contactTypesStatus } = useLoadData(
    () => getContactTypes(),
    {
      fetchFromRedux: true,
      storeInRedux: true,
      reduxAction: sharedDataActions.setContactTypes,
      reduxSelector: getContactTypesValue,
    }
  )

  const dependencies = useMemo(() => [clientId], [clientId])
  const { data: contactPersons, status: contactPersonsStatus } = useLoadData(
    () => getContactPersons(+clientId),
    {
      dependencies,
      fetchFromRedux: true,
      storeInRedux: true,
      reduxAction:
        clientContactPersonsActions.replaceAllWithClientContactPersons,
      reduxSelector: getClientContactPersons,
      reduxStorePath: clientId?.toString(),
    }
  )

  const { data: lastVisitReport, status: lastVisitReportStatus } = useLoadData(
    () => getClientLastVisitReportData(parseInt(clientId, 10)),
    {
      fetchFromRedux: false,
      storeInRedux: true,
      reduxAction:
        clientLastVisitReportActions.replaceAllWithClientLastVisitReport,
      reduxStorePath: clientId?.toString(),
    }
  )

  const visitReportDataStatus = useMemo(
    () => [contactTypesStatus, contactPersonsStatus, lastVisitReportStatus],
    [contactTypesStatus, contactPersonsStatus, lastVisitReportStatus]
  )

  const isVisitReportDataStatusPending = useMemo(
    () =>
      visitReportDataStatus.reduce(
        (acc, status) => acc || status === STATUS.PENDING,
        false
      ),
    [visitReportDataStatus]
  )

  return {
    contactTypes,
    contactPersons,
    lastVisitReport,
    isVisitReportDataStatusPending,
  }
}
