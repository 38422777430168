import React, { FC, useState } from 'react'
import { Controller } from 'react-hook-form'
import { IWysiwygSection } from '../../interfaces/IForms'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'
import ErrorMessage from './ErrorMessage'
import WysiwygEditor from './WysiwygEditor'
import { validateTextInput } from '../../utils/visitReports'
import { WYSIWYG_INPUT_MAX_LENGTH } from '../../enums/common'

const WysiwygSection: FC<IWysiwygSection> = ({
  control,
  errors,
  defaultValue = '',
  name = 'comment',
  shouldValidate = true,
}) => {
  const [inputPlainText, setInputPlainText] = useState(defaultValue)

  return (
    <div className="editor">
      <DynamicFormattedMessage
        id="form.field.comment.label"
        className="inputLabel textPrimary mb1"
        tag="label"
        htmlFor={name}
      />
      <Controller
        render={({ onChange }) => (
          <WysiwygEditor
            onChange={onChange}
            defaultValue={defaultValue}
            setInputPlainText={setInputPlainText}
          />
        )}
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={{
          required: false,
          validate: () =>
            validateTextInput(
              inputPlainText,
              shouldValidate,
              WYSIWYG_INPUT_MAX_LENGTH
            ),
        }}
      />
      <ErrorMessage error={errors[name]} />
    </div>
  )
}

export default WysiwygSection
