import React, { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import DynamicFormattedMessage from '../../../common/ui/DynamicFormattedMessage'
import { VISIT_REPORT_SELLOUT_INPUT_MAX } from '../../../../constants/visitReport'
import TextInput from '../../../form/TextInput'
import {
  ISectionRowInput,
  IVisitReportForm,
} from '../../../../interfaces/IVisitReports'
import { useIsTabDisabled } from '../../../../hooks/clientVisitReport/UseIsTabDisabled'
import { validateNumberInput } from '../../../../utils/visitReports'

export const VisitReportEngagementExtraFields = ({
  parentSection,
  extraFields,
  defaultData,
  dataType = '',
}: {
  parentSection: keyof IVisitReportForm
  extraFields: ISectionRowInput[]
  defaultData: IVisitReportForm
  dataType?: string
}) => {
  const isDisabled = useIsTabDisabled()
  const { register, errors, watch } = useFormContext()
  const watchedFields = watch([parentSection])

  const isVisible = useMemo(() => !!watchedFields[parentSection], [
    watchedFields,
    parentSection,
  ])

  return (
    <>
      {extraFields.map(({ input: { key, label, required } }) => {
        const isRequired = required && isVisible && !isDisabled

        return (
          <div key={key} className="col12 px0 dFlex flexWrap alignItemsCenter">
            <DynamicFormattedMessage
              id={`form.visitReport.row.${key}.label`}
              tag="p"
              className="w200 textPrimary textXs fontBold mb15 pt1"
            />
            <TextInput
              key={key}
              dataType={dataType}
              name={key!}
              id={key!}
              register={register({
                required: isRequired,
                validate: (value) =>
                  validateNumberInput(
                    value,
                    isVisible && !isDisabled,
                    VISIT_REPORT_SELLOUT_INPUT_MAX
                  ),
              })}
              error={errors[key!]}
              placeholder="typeIn"
              defaultValue={
                defaultData[key as keyof IVisitReportForm] as string
              }
              customClass={`rowInputField mx1 my1 textXs ${
                isRequired ? 'mandatory' : ''
              }`}
              labelText={label || key}
            />
          </div>
        )
      })}
    </>
  )
}
