import React, { FC } from 'react'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'
import {
  MONTHS_PERIOD,
  NEXT_YEAR_JANUARY,
  NEXT_YEAR_FEBRUARY,
  NEXT_YEAR_MARCH,
} from '../../constants/tables'
import { MonthlyTableContent } from '../common/ui/tables/MonthlyTableContent'
import { Table } from '../common/ui/tables/Table'
import { IActivityReports } from '../../interfaces/IUsers'
import Alerts from '../alerts/Alerts'
import Loader from '../loader/Loader'
import { STATUS } from '../../enums/common'
import { processUserActivityData } from '../../utils/user'
import { CLIENTS_ACTIVITY } from '../../constants/dashboard'
import { ITableTranslateValues } from '../../interfaces/ITables'
import { getNextCalendarYear } from '../../utils/helpers'

export const ActivityReports: FC<IActivityReports> = ({
  data,
  status,
  message,
  type = CLIENTS_ACTIVITY,
}) => {
  const activityData = processUserActivityData(data?.activity, type)

  return (
    <section className="appointmentsReports overflowAuto withBackgroundColorDefault hasBorderRadius px15 py15 mb2">
      <Alerts status={status} message={message} />
      <div className="pb1">
        <DynamicFormattedMessage
          id={`dashboard.${type}.label`}
          tag="h3"
          className="textPrimary fontRegular"
        />
      </div>
      {status && status === STATUS.PENDING && <Loader />}
      {data?.activity && (
        <div className="appointmentsReportsData">
          <Table
            tableHeaders={MONTHS_PERIOD}
            translateValues={
              {
                [NEXT_YEAR_JANUARY]: { year: getNextCalendarYear().toString() },
                [NEXT_YEAR_FEBRUARY]: {
                  year: getNextCalendarYear().toString(),
                },
                [NEXT_YEAR_MARCH]: { year: getNextCalendarYear().toString() },
              } as ITableTranslateValues
            }
          >
            <MonthlyTableContent {...activityData} />
          </Table>
        </div>
      )}
    </section>
  )
}
