import { addHours, format } from 'date-fns'
import {
  IClientAppointmentData,
  IClientDetailsActions,
  IClientDetailsData,
  IClientFieldsConst,
  IClientReportData,
} from '../interfaces/IClientDetails'
import {
  IAppointmentDate,
  IAppointmentType,
  IContactPerson,
} from '../interfaces/IAppointments'
import { ROUTES } from '../enums/routes'
import { ICustomObject } from '../interfaces/ICommonComponents'
import {
  DATE_REQUEST_FORMAT,
  DCR_EMAIL,
  FORM_TYPES,
  KAM_EMAIL,
} from '../enums/common'

export const clientDetailDataInitialState: IClientDetailsData = {
  id: 0,
  cipCode: '',
  clientCode: '',
  name: '',
  email: '',
  address1: '',
  address2: '',
  zipCode: '',
  city: '',
  phoneNumber: '',
  faxNumber: '',
  phoneAgentName: '',
  phoneAgentComment: '',
  fieldAgentName: '',
  fieldAgentComment: '',
  gpsLongitude: 0,
  gpsLatitude: 0,
  potentiel1: '',
  potentiel2: '',
  potentiel3: '',
  qualification3: '',
  targetType2: '',
  sectoLabo: '',
  typeCible: '',
  cluster: '',
  uga: '',
  palier2022: '',
  palier2021: '',
  objNbVisites: 0,
  decile: 0,
  potentialCa: '',
  potentialBoites: '',
  capping: 0,
  quantiteBgm2021Fy: 0,
  quantiteBgm2020Fy: 0,
  subscribeComptoir: null,
  subscribeCoach: null,
  withAppointment: false,
  withVisitReport: false,
  titulaire: {
    civiliteTitulaire: '',
    prenomTitulaire: '',
    nomTitulaire: '',
  },
  kamEmail: '',
}

export const CLIENT_FIELDS: IClientFieldsConst = {
  titulaire: 'titulaire',
}

export const clientDetailsDataFieldsOrder = [
  'id',
  'cipCode',
  'clientCode',
  'sectoLabo',
  'typeCible',
  'cluster',
  'objNbVisites',
  'targetType2',
  'name',
  'email',
  'address1',
  'address2',
  'zipCode',
  'city',
  'uga',
  'phoneNumber',
  'faxNumber',
  'titulaire',
  'phoneAgentName',
  'phoneAgentComment',
  'fieldAgentName',
  'fieldAgentComment',
  'gpsLongitude',
  'gpsLatitude',
  'potentiel1',
  'potentiel2',
  'potentiel3',
  'qualification3',
  'palier2022',
  'palier2021',
  'decile',
  'capping',
  'potentialCa',
  'potentialBoites',
  'quantiteBgm2021Fy',
  'quantiteBgm2020Fy',
  'subscribeComptoir',
  'subscribeCoach',
  'withAppointment',
  'withVisitReport',
  'kamEmail',
]

export const clientAppointmentDataInitialState: IClientAppointmentData[] = [
  {
    id: 0,
    startDate: '',
    eventType: '',
    contactPerson: '',
  },
]

export const clientReportsDataInitialState: IClientReportData[] = [
  {
    id: 0,
    startDate: '',
    eventType: '',
    formationType: '',
    outcome: '',
    contactPerson: '',
  },
]

export const appointmentTypesDataInitialState: IAppointmentType[] = [
  {
    id: 0,
    eventType: '',
    isVisible: false,
  },
]

export const contactPersonsDataInitialState: IContactPerson[] = [
  {
    id: 0,
    firstName: '',
    lastName: '',
  },
]

export const datesInitialState: IAppointmentDate = {
  startDate: format(addHours(new Date(), 1), DATE_REQUEST_FORMAT),
  endDate: format(addHours(new Date(), 2), DATE_REQUEST_FORMAT),
}

export const ACTION_NOUVEAU_RENDEZ_VOUS: string = 'newAppointment'
export const ACTION_NOUVEAU_RAPPORT = 'raport'
const ACTION_FICHE_CLIENT: string = 'ficheClient'
export const ACTION_FORMULAIRES: string = 'formularies'
export const ACTION_HISTORIQUE: string = 'historique'

// For the moment, only the client file page / route is implemented
// TODO Update remaining rountes, when the page is created
export const USER_DETAILS_ACTION_LIST: IClientDetailsActions[] = [
  {
    actionName: ACTION_NOUVEAU_RAPPORT,
    actionUrl: ROUTES.newVisitReport,
  },
  {
    actionName: ACTION_NOUVEAU_RENDEZ_VOUS,
    actionUrl: ROUTES.newAppointmentAgenda,
  },
  {
    actionName: ACTION_FICHE_CLIENT,
    actionUrl: ROUTES.clientFile,
  },
  {
    actionName: ACTION_FORMULAIRES,
    actionUrl: ROUTES.clientForms,
  },
  {
    actionName: ACTION_HISTORIQUE,
    actionUrl: ROUTES.history,
  },
]

export const TARGET_TYPE_2 = 'targetType2'
export const KAM = 'fieldAgentName'
export const DCR = 'phoneAgentName'

export const IDENTIFICATION_DATA_FIELDS: (keyof IClientDetailsData)[] = [
  'id',
  'cipCode',
  'cipCode',
  'sectoLabo',
  'sectoLabo',
  'typeCible',
  'cluster',
  'objNbVisites',
]

export const COORDONNEES_DATA_FIELDS: (keyof IClientDetailsData)[] = [
  'name',
  'email',
  'address1',
  'address2',
  'zipCode',
  'city',
  'uga',
  'phoneNumber',
  'faxNumber',
  'titulaire',
]

export const SELLIN_DATA_FIELDS: (keyof IClientDetailsData)[] = [
  'palier2022',
  'palier2021',
  'decile',
  'capping',
  'potentialCa',
  'potentialBoites',
  'quantiteBgm2021Fy',
  'quantiteBgm2020Fy',
  'subscribeComptoir',
  'subscribeCoach',
]

export const SYNERGIE_DATA_FIELDS: (keyof IClientDetailsData)[] = [KAM, DCR]

const MONDAY = 'monday'
const TUESDAY = 'tuesday'
const WEDNESDAY = 'wednesday'
const THURSDAY = 'thursday'
const FRIDAY = 'friday'
const SATURDAY = 'saturday'
const SUNDAY = 'sunday'

export const WEEK_DAYS_LIST = [
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
  SUNDAY,
]

// RangeStepInput default, min, max and step for visit report first panel numberOfPersons
export const RANGE_PERSONS_DEFAULT = 1
export const RANGE_PERSONS_MIN = 1
export const RANGE_PERSONS_MAX = 20
export const RANGE_PERSONS_STEP = 1

export const VISIT_ACTION_TYPE_APPEL = 'Appel bref'
export const VISIT_OUTCOME_VISITE_DISTANCE = 'Visite argu a Distance'

const CLIENT_NAME = 'CLIENT'
const KAM_NAME = 'KAM'
const DCR_NAME = 'DCR'

export const EMAIL_CONTACT: { [key: string]: ICustomObject } = {
  [FORM_TYPES.contract]: {
    email: CLIENT_NAME,
    [KAM_EMAIL]: KAM_NAME,
    [DCR_EMAIL]: DCR_NAME,
  },
  [FORM_TYPES.destructionCertificate]: {
    email: CLIENT_NAME,
    [KAM_EMAIL]: KAM_NAME,
  },
}

// Offline sync page actions list
export const ACTION_TYPES = [
  'appointments',
  'visit-reports',
  'reclamations',
  'destructionCertificates',
  'contracts',
  'sendEmail',
  'clients',
  'upload',
] as const

export enum CLIENT_SOURCE {
  MAP = 'map',
}
