const TAB_ITEM_STANDARD = 'tabStandard'
const TAB_ITEM_ACTION_1 = 'tabAction1'
const TAB_ITEM_ACTION_2 = 'tabAction2'
const TAB_ITEM_ADMIN = 'tabAdmin'
const TAB_ITEM_RECAP = 'tabRecap'
const TAB_ITEM_ORDER_HISTORY = 'tabOrderHistory'
const TAB_ITEM_ENGAGEMENT_HISTORY = 'tabEngagementHistory'

export const NEW_VISIT_REPORT_TABS: string[] = [
  TAB_ITEM_STANDARD,
  TAB_ITEM_ACTION_1,
  TAB_ITEM_ACTION_2,
  TAB_ITEM_ADMIN,
]

export const HISTORY_TABS: string[] = [
  TAB_ITEM_RECAP,
  TAB_ITEM_ORDER_HISTORY,
  TAB_ITEM_ENGAGEMENT_HISTORY,
]
