import React, { FC, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { PHOTO_FILE_NAME } from '../../../../enums/common'
import {
  IImportFilesSection,
  IVisitReportForm,
} from '../../../../interfaces/IVisitReports'
import { PhotoCapture } from '../../../form/PhotoCapture'
import { CustomImageUpload } from '../../../form/CustomImageUpload'
import { ImagePlaceholder } from '../../../clientVisitReport/v1/ImagePlaceholder'
import { newVisitReportActions } from '../../../../store/reducers/newVisitReportReducer'
import { convertFormDatetime } from '../../../../utils/visitReports'

export const VisitReportImportFileSection: FC<IImportFilesSection> = ({
  imgKey,
  isVisible,
  dataType = '',
  camera,
  defaultData,
  isCameraOpen,
  updateForm,
}) => {
  const dispatch = useDispatch()
  const { register, getValues } = useFormContext()
  const { photoRef, hasUserMedia, grantedPermission, isVideoPlaying } = camera

  const imageValue = getValues(imgKey)
  const image = imageValue?.length && JSON.parse(imageValue)
  const isImgDisabled = useMemo(
    () =>
      (image?.fileName && image?.fileName !== PHOTO_FILE_NAME) ||
      !hasUserMedia ||
      !grantedPermission ||
      !isVideoPlaying,
    [image, hasUserMedia, grantedPermission, isVideoPlaying]
  )
  const defaultDataValue = useMemo(
    () => `${defaultData[imgKey as keyof IVisitReportForm]}`,
    [defaultData, imgKey]
  )

  return (
    <div className={isVisible ? 'px15 mb05' : 'dNone'} key={imgKey}>
      <input
        type="hidden"
        name={imgKey}
        id={imgKey}
        data-parent={dataType}
        ref={register}
        defaultValue={defaultDataValue}
      />
      <div className="row mx0 flexMdNowrap justifyContentStart alignItemsCenter">
        <div className="row mx0 flexMdNowrap alignItemsStart mr2">
          <CustomImageUpload
            type={imgKey}
            updateForm={updateForm}
            isDisabled={image?.fileName && image?.fileName === PHOTO_FILE_NAME}
            showUploadBtn={!isImgDisabled}
            onUploadCallback={() => {
              dispatch(
                newVisitReportActions.setNewVisitReport({
                  ...convertFormDatetime(getValues() as IVisitReportForm),
                })
              )
            }}
            customImageUploadWrapper="mr1"
          />
          <PhotoCapture
            type={imgKey}
            isCameraOpen={isCameraOpen}
            photoRef={photoRef}
            isDisabled={isImgDisabled}
            onPhotoUpdateCallback={() => {
              dispatch(
                newVisitReportActions.setNewVisitReport({
                  ...convertFormDatetime(getValues() as IVisitReportForm),
                })
              )
            }}
          />
        </div>
        {!image?.dataURL ? (
          <div>
            <ImagePlaceholder />
          </div>
        ) : (
          <div className="customImageUploadItem">
            <div className="customImageUploadImagePreview dFlex alignItemsCenter justifyContentCenter">
              <img src={image?.dataURL} alt="Capture Preview" />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
