import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { TabPanel } from 'react-tabs'
import { ValidationErrors } from 'final-form'
import { FormProvider } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { NEW_VISIT_REPORT_TABS } from '../../constants/tabs'
import { VisitReportFirstPanel } from './newVisitReportComponents/VisitReportFirstPanel'
import { VisitReportSecondPanel } from './newVisitReportComponents/VisitReportSecondPanel'
import { VisitReportThirdPanel } from './newVisitReportComponents/VisitReportThirdPanel'
import { VisitReportForthPanel } from './newVisitReportComponents/VisitReportForthPanel'
import { useVisitReportFormHook } from '../../hooks/clientVisitReport/UseVisitReportForm'
import Alerts from '../alerts/Alerts'
import { newVisitReportSelectors } from '../../store/selectors'
import { newVisitReportActions } from '../../store/reducers/newVisitReportReducer'
import { convertFormDatetime, getActiveTab } from '../../utils/visitReports'
import { VisitReportFormNav } from '../clientVisitReport/VisitReportFormContent'
import { mockNewVisitReportFormInitialState } from '../../__mocks__/dataMock'
import { CustomTabsForm } from './newVisitReportComponents/commonComponents/CustomTabsForm'
import { useLoadData } from '../../hooks/UseLoadData'
import { getClientLastVisitReportData } from '../../services/visitReportsService'
import { getClientLastVisitReport } from '../../store/selectors/clientLastVisitReportSelectors'
import Loader from '../loader/Loader'
import { StickyWrapper } from '../common/ui/StickyWrapper'
import { IFormation } from '../../interfaces/IVisitReports'

function VisitReportForm({
  clientId,
  setIsModalOpen,
}: {
  clientId: number
  setIsModalOpen: Dispatch<SetStateAction<boolean>>
}): ReactElement {
  const { state } = useLocation()
  const dispatch = useDispatch()
  const defaultData = useSelector(
    newVisitReportSelectors.getNewVisitReportValues
  )

  const [visitDate, setVisitDate] = useState({
    startDate: defaultData.visitDate,
  })
  const [activeTab, setActiveTab] = useState(0)
  const [formIsChanged, setFormIsChanged] = useState(false)
  const [errorField, setErrorField] = useState({
    field: '',
    triggerFocus: true,
  })
  const [isAnyFormationChecked, setIsAnyFormationChecked] = useState(false)
  const [errorTrigger, setErrorTrigger] = useState<boolean>(false)
  const [formations, setFormations] = useState<IFormation[]>([])

  const handleFormationChange = (isChecked: boolean) => {
    setIsAnyFormationChecked(isChecked)
  }

  const methods = useVisitReportFormHook(
    clientId,
    setVisitDate,
    setIsModalOpen,
    state
  )
  const {
    form: { handleSubmit, getValues },
    formState: { status, message },
    onSubmit,
  } = methods

  const setActiveTabOnError = (errors: ValidationErrors) => {
    setErrorField(({ triggerFocus }) => ({
      field: Object.keys(errors)[0],
      triggerFocus: !triggerFocus,
    }))
  }

  const { data } = useLoadData(() => getClientLastVisitReportData(clientId), {
    fetchFromRedux: true,
    reduxSelector: getClientLastVisitReport,
    reduxStorePath: clientId.toString(),
  })

  const loadedData = useMemo(() => data && Object.keys(data).length !== 0, [
    data,
  ])

  const storeFormData = () => {
    dispatch(
      newVisitReportActions.setNewVisitReport({
        ...convertFormDatetime({
          ...mockNewVisitReportFormInitialState,
          ...getValues(),
        }),
      })
    )
  }

  const updateForm = () => {
    setFormIsChanged(!formIsChanged)
  }

  const handleFormationSubmit = (formationData: IFormation[]) => {
    setFormations(formationData)
  }

  const handleFormSubmit = (formData: any) => {
    if (!isAnyFormationChecked) {
      setActiveTab(1)
      setErrorTrigger(true)
      return
    }

    const submitData = {
      ...formData,
      formations,
    }

    onSubmit(submitData)
  }

  useEffect(() => {
    setActiveTab(getActiveTab(errorField.field))
  }, [errorField.field, errorField.triggerFocus])

  return (
    <FormProvider {...methods.form}>
      <form
        onSubmit={handleSubmit(handleFormSubmit, setActiveTabOnError)}
        onChange={() => {
          updateForm()
        }}
      >
        <CustomTabsForm
          tabList={NEW_VISIT_REPORT_TABS}
          activeTab={activeTab}
          onClick={storeFormData}
          setActiveTab={setActiveTab}
        >
          <div className="newVisitReportForm col dFlex py25 px25">
            <StickyWrapper customClass="px0">
              <Alerts status={status} message={message} />
            </StickyWrapper>
            <TabPanel forceRender={true}>
              {loadedData ? (
                <VisitReportFirstPanel
                  informationData={data}
                  clientId={clientId}
                  defaultData={defaultData}
                  visitDate={visitDate}
                  setVisitDate={setVisitDate}
                />
              ) : (
                <Loader />
              )}
            </TabPanel>
            <TabPanel forceRender={true}>
              {loadedData ? (
                <VisitReportSecondPanel
                  informationData={data}
                  defaultData={defaultData}
                  updateForm={updateForm}
                  clientId={clientId.toString()}
                  onFormationChange={handleFormationChange}
                  errorTrigger={errorTrigger}
                  onFormationSubmit={handleFormationSubmit}
                />
              ) : (
                <Loader />
              )}
            </TabPanel>
            <TabPanel forceRender={true}>
              {loadedData ? (
                <VisitReportThirdPanel
                  defaultData={defaultData}
                  updateForm={updateForm}
                />
              ) : (
                <Loader />
              )}
            </TabPanel>
            <TabPanel forceRender={true}>
              {loadedData ? (
                <VisitReportForthPanel
                  clientId={clientId}
                  defaultData={defaultData}
                  updateForm={updateForm}
                />
              ) : (
                <Loader />
              )}
            </TabPanel>
            <VisitReportFormNav
              status={status}
              formIsChanged={formIsChanged}
              setIsModalOpen={setIsModalOpen}
            />
          </div>
        </CustomTabsForm>
      </form>
    </FormProvider>
  )
}

export default VisitReportForm
