import React, { FC } from 'react'
import {
  iconIosStats,
  iconLocationWhite,
  iconPersonWhite,
  iconPhoneWhite,
} from '../../assets/images/images'
import { MAP, PHONE } from '../../enums/common'
import { IClientDetailsInfoProps } from '../../interfaces/IClientDetails'
import ClientCipCode from '../clientCipcode/ClientCipcode'
import LinkTo from '../common/ui/LinkTo'

export const ClientDetailsInfo: FC<IClientDetailsInfoProps> = ({
  name,
  address1,
  phoneNumber,
  cipCode,
  contactPerson,
  hasOnlyOneColumn,
  zipCode,
  gpsLatitude,
  gpsLongitude,
  city,
  potentiel2,
}) => {
  const addressBlock = `${address1}${zipCode ? `, ${zipCode}` : ''}${
    city ? `, ${city}` : ''
  }`
  const hasCoordinates = gpsLatitude && gpsLongitude

  return (
    <div className="clientDetailsPageClientData hasBoxShadow withBackgroundColorPrimary">
      <p className="mb3 title">
        <span>
          {`${name} `}
          {cipCode && <ClientCipCode cipCode={cipCode} />}
        </span>
      </p>
      <div className="dFlex">
        <div
          className={`px1 flex ${hasOnlyOneColumn ? '' : 'hasRightSeparator'}`}
        >
          <p className="mb15 pb1 dFlex hasSeparator">
            <LinkTo
              type={MAP}
              hrefContent={
                hasCoordinates ? `${gpsLatitude},${gpsLongitude}` : addressBlock
              }
              customClass="fontMedium"
            >
              <img
                src={iconLocationWhite}
                alt="user location icon"
                className="mr1"
              />
              <span>{addressBlock}</span>
            </LinkTo>
          </p>
          <p className="mb15 pb1 dFlex hasSeparator">
            <LinkTo
              type={PHONE}
              hrefContent={phoneNumber}
              customClass="fontMedium"
            >
              <img src={iconPhoneWhite} alt="user phone icon" className="mr1" />
              <span>{phoneNumber}</span>
            </LinkTo>
          </p>
          <p>
            <img src={iconPersonWhite} alt="user person icon" className="mr1" />
            <span>{!contactPerson || contactPerson[0]?.name || '-'}</span>
          </p>
        </div>
        {!hasOnlyOneColumn && (
          <div className="flex px1">
            {potentiel2 && (
              <p className="mb15 pb1 dFlex hasSeparator">
                <img src={iconIosStats} alt="ios stats icon" className="mr1" />
                <span>{potentiel2}</span>
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
