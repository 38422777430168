import React, { FC } from 'react'
import DynamicFormattedMessage from '../DynamicFormattedMessage'
import { ACTIVITY_REPORT_WITH_PERCENTAGE } from '../../../../constants/dashboard'
import { IActivityItemsData } from '../../../../interfaces/IUsers'

export const MonthlyTableContent: FC<IActivityItemsData> = (activityData) => {
  return (
    <>
      {Object.entries(activityData)?.length &&
        Object.entries(activityData).map((rowActivity) => {
          const showPercentage = ACTIVITY_REPORT_WITH_PERCENTAGE.some(
            (item) => item === rowActivity[0]
          )

          return (
            <tr key={`tr-${rowActivity[0]}`} className="textPrimary textXs">
              <th scope="row" className="fontMedium textLeft">
                <DynamicFormattedMessage
                  id={`dashboard.${rowActivity[0]}.label`}
                />
              </th>
              {rowActivity[1].map((value: number, index: number) => (
                <td
                  key={`td-${rowActivity[0]}-${index.toString()}`}
                  className={`textCenter ${
                    showPercentage ? 'symbolPercentagePrimary' : ''
                  }`}
                >
                  {value}
                </td>
              ))}
            </tr>
          )
        })}
    </>
  )
}
