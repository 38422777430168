import React, { FC } from 'react'
import { FieldError } from 'react-hook-form'
import { useDashboardVisitsCoverageFilters } from '../../../hooks/dashboard/dashboardVisitsCoverage/UseDashboardVisitsCoverageFilters'
import FormGroupDate from '../../form/FormGroupDate'
import { FILTERS_RADIO_OPTIONS_LIST } from '../../../constants/form'
import {
  FILTER_CLIENT,
  FILTER_CLIENT_YEARS,
  GROUPMENT_FILTER,
} from '../../../constants/dashboard'
import MultipleSelect from '../../form/MultipleSelect'
import { FiltersActionButtons } from '../common/FiltersActionButtons'
import { IFiltersVisitsCoverage } from '../../../interfaces/IDashboard'
import DynamicButtons from '../../form/DynamicButtons'

export const Filters: FC<IFiltersVisitsCoverage> = ({
  userId,
  setFormState,
  usersStatusPending,
  setIsUserNotAvailableError,
  shouldUseLoggedUserId,
}) => {
  const {
    form: { handleSubmit, control, errors, register },
    onSubmit,
    resetFilters,
    groupIdsResponse,
    groupIdsStatusPending,
    currentYear,
    startDate,
    endDate,
    filters,
    hasNetwork,
    disabledActionButtons,
  } = useDashboardVisitsCoverageFilters(
    userId,
    setFormState,
    usersStatusPending,
    setIsUserNotAvailableError,
    shouldUseLoggedUserId
  )

  return (
    <section className="filtersSection">
      <form
        onSubmit={handleSubmit(onSubmit)}
        onReset={resetFilters}
        className="row mx0 withBackgroundColorDefault hasBorderRadius py15 mb2"
      >
        <div className="colLg3 mb2 mb0lg">
          <FormGroupDate
            startDate={startDate}
            endDate={endDate}
            startDateOnly={false}
            allowWeekend={true}
            customClass="col12 colSm6 colLg12 alignSelfStart"
          />
        </div>
        <div className="colLg9 px0">
          <div className="row mx0">
            {FILTER_CLIENT_YEARS.map((filterClient, index) => (
              <DynamicButtons
                key={filterClient}
                optionsArray={FILTERS_RADIO_OPTIONS_LIST}
                register={register({ required: true })}
                error={errors[filterClient] as FieldError}
                name={filterClient}
                defaultValue={filters[filterClient] as string}
                labelName={FILTER_CLIENT}
                labelValues={{ year: currentYear - index }}
                customClass="px15 mb2"
              />
            ))}
          </div>
          <div className="col12 colMd6 colLg8">
            <MultipleSelect
              id={GROUPMENT_FILTER}
              options={groupIdsResponse.data}
              defaultValue={filters?.groupIds || []}
              hideMultiRemove={false}
              className={`${GROUPMENT_FILTER} customSearchInput`}
              control={control}
              error={errors[GROUPMENT_FILTER] as FieldError | undefined}
              disabled={!hasNetwork || groupIdsStatusPending}
            />
          </div>
        </div>
        <FiltersActionButtons disabled={disabledActionButtons} />
      </form>
    </section>
  )
}
