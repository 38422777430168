import React, { FC } from 'react'
import DynamicFormattedMessage from '../DynamicFormattedMessage'
import {
  IHistoryTableData,
  IHistoryTableRow,
} from '../../../../interfaces/IHistory'
import {
  ANNUAL_RECAP_TABLE_HEADER,
  TYPE_SUMMARY,
} from '../../../../constants/tables'
import { roundTwoDecimals, getCurrentYear } from '../../../../utils/helpers'

export const CustomTableHistoryAnnual: FC<IHistoryTableData> = ({ data }) => {
  return (
    <table className="customTable customTableRecap wFull">
      <thead className="customTableHeader">
        <tr>
          {ANNUAL_RECAP_TABLE_HEADER.map(
            (headerName: string, index: number) => (
              <td key={headerName} className={`${index !== 0 && 'textCenter'}`}>
                <DynamicFormattedMessage
                  id={`table.header.${headerName}.label`}
                  className="fontBold textUppercase textPrimary textCustomXs textLeft"
                  values={{
                    year: getCurrentYear(),
                    previousYear: getCurrentYear() - 1,
                  }}
                />
              </td>
            )
          )}
        </tr>
      </thead>
      <tbody className="customTableBody">
        {data &&
          data.length !== 0 &&
          data.map(
            (
              { type, name, currentYear, previousYear }: IHistoryTableRow,
              index: number
            ) => (
              <tr
                key={index.toString()}
                className={`textPrimary textCustomXs ${
                  type === TYPE_SUMMARY && 'fontBold backgroundNeutralLight'
                }`}
              >
                <td>{name}</td>
                <td className="textCenter">
                  {currentYear?.TOTAL2?.quantity || '-'}
                </td>
                <td className="textCenter">
                  {currentYear?.TOTAL2?.value || '-'}
                </td>
                <td className="textCenter">
                  {previousYear?.TOTAL1?.quantity || '-'}
                </td>
                <td className="textCenter">
                  {previousYear?.TOTAL1?.value || '-'}
                </td>
                <td className="textCenter">
                  {roundTwoDecimals(
                    (previousYear?.TOTAL1?.value || 0) -
                      (currentYear?.TOTAL2?.value || 0)
                  )}
                </td>
              </tr>
            )
          )}
      </tbody>
    </table>
  )
}
