import { _DeepPartialObject } from 'chart.js/types/utils'
import { Interaction, TooltipItem } from 'chart.js'
import { useIntl } from 'react-intl'
import {
  DASHBOARD_CA_LAST_YEAR_COLOR,
  DEFAULT_CHART_DATA_VISIT_COVERAGE,
} from '../../../constants/dashboard'
import {
  CUSTOM_COLOR_1,
  SECONDARY_COLOR,
  SECONDARY_COLOR_LIGHT,
  TRANSPARENT,
} from '../../../constants/colors'

export const usePieChartHook = (
  labels: string[],
  chartData: number[],
  shouldChartRender: boolean
) => {
  const intl = useIntl()
  Interaction.modes.noData = () => {
    return []
  }

  const getChartData = () => {
    return shouldChartRender ? chartData : DEFAULT_CHART_DATA_VISIT_COVERAGE
  }
  const data = {
    labels: shouldChartRender
      ? labels
      : [
          intl.formatMessage({
            id: 'dashboard.visitsCoverage.chart.noData.label',
          }),
          '',
        ],
    datasets: [
      {
        data: getChartData(),
        data1: getChartData(),
        backgroundColor: [
          shouldChartRender ? SECONDARY_COLOR_LIGHT : CUSTOM_COLOR_1,
          shouldChartRender ? DASHBOARD_CA_LAST_YEAR_COLOR : TRANSPARENT,
        ],
      },
    ],
  }

  const options = {
    responsive: true,
    interaction: {
      mode: shouldChartRender ? ('dataset' as const) : ('noData' as const),
    },
    plugins: {
      title: {
        display: false,
      },
      subtitle: {
        display: true,
        fullSize: true,
        text: '',
        color: SECONDARY_COLOR,
        font: {
          size: 16,
          weight: 'bold',
        },
        position: 'bottom' as const,
        padding: 10,
      },
      tooltip: {
        events: ['click', 'mousemove', 'mouseout'] as _DeepPartialObject<
          TooltipItem<'pie'>
        >,
      },
    },
  }

  return { data, options }
}
