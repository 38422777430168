import React, { FC } from 'react'
import { getWorkingDays } from '../../utils/helpers'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'

export const WorkingDays: FC = () => {
  const businessDays = getWorkingDays()
  const objectiveDays = businessDays * 5

  return (
    <section className="objectiveDays col12 hasBorderRadius px15 py1 mx0 mb2">
      <div className="row mx0">
        <div className="colMd4 colSm12 pl0 py05 dFlex alignItemsCenter">
          <DynamicFormattedMessage
            id="dashboard.objectiveDays.label"
            tag="h3"
            className="textPrimary fontRegular"
          />
        </div>
        <div className="colMd8 colSm12 px0 dFlex alignItemsCenter">
          <span className="textSecondary fontMedium">{objectiveDays}</span>
        </div>
      </div>
    </section>
  )
}
