import React, { FC } from 'react'
import { IContractDetailsFieldData } from '../../../interfaces/IContracts'
import TextInput from '../../form/TextInput'
import { CONTRACT_DETAILS_TEXT_MAX_LENGTH } from '../../../constants/form'

export const TextInputField: FC<IContractDetailsFieldData> = ({
  field,
  register,
  errors,
  validation,
}) => {
  const { id, name, mandatory, label } = field

  return (
    <div className="row">
      <TextInput
        name={name}
        id={id.toString()}
        customClass="colMd12"
        register={register({
          required: mandatory,
          maxLength: CONTRACT_DETAILS_TEXT_MAX_LENGTH,
          pattern: validation,
        })}
        error={errors[name]}
        hasLabel={!!label}
        isRequired={mandatory}
      />
    </div>
  )
}
