import React, { FC } from 'react'
import { Link, generatePath } from 'react-router-dom'
import {
  ACTION_NOUVEAU_RAPPORT,
  ACTION_NOUVEAU_RENDEZ_VOUS,
  USER_DETAILS_ACTION_LIST,
} from '../../constants/userData'
import { IClientDetailsAction } from '../../interfaces/IClientDetails'
import { useClientDetailsActions } from '../../hooks/clientDetails/UseClientDetailsActions'
import { DANGER_COLOR, SECONDARY_COLOR_DARKER } from '../../constants/colors'
import { ButtonWithIcon } from '../common/ui/ButtonWithIcon'
import { OfflineIcon } from '../common/ui/icons/OfflineIcon'

export const ClientDetailsActions: FC<IClientDetailsAction> = ({
  clientId,
  client,
  dataStatusPendingByAction,
  offset,
  currentPage,
  fetchFormations,
}) => {
  const { resetContractsSelectedYear, hasNetwork } = useClientDetailsActions()

  const handleClick = (actionName: string) => {
    if (actionName === ACTION_NOUVEAU_RAPPORT && fetchFormations) {
      fetchFormations()
    }
    resetContractsSelectedYear()
  }

  return (
    <div className="clientDetailsPageClientActionsWrapper">
      <ul className="unstyledList clientDetailsPageClientActionsList">
        {USER_DETAILS_ACTION_LIST.map((userAction) => {
          const { actionName, actionUrl } = userAction
          const isLinkDisabledInOffline =
            dataStatusPendingByAction[actionName] && !hasNetwork
          const isLinkPendingInOnline =
            dataStatusPendingByAction[actionName] && hasNetwork
          const iconColor = dataStatusPendingByAction[actionName]
            ? DANGER_COLOR
            : SECONDARY_COLOR_DARKER
          const iconClassName = `fa-stack-1x ${
            isLinkPendingInOnline ? 'fa-fade' : ''
          }`

          return (
            <li
              key={actionName}
              className="clientDetailsPageClientActionsListItem"
            >
              <Link
                to={{
                  pathname: generatePath(
                    !isLinkDisabledInOffline ? actionUrl : '',
                    {
                      clientId,
                    }
                  ),
                  state:
                    actionName === ACTION_NOUVEAU_RENDEZ_VOUS ||
                    actionName === ACTION_NOUVEAU_RAPPORT
                      ? {
                          client,
                          prevLocation: window.location.pathname,
                          offset,
                          currentPage,
                        }
                      : {
                          prevLocation: window.location.pathname,
                          offset,
                          currentPage,
                        },
                }}
                onClick={() => handleClick(actionName)}
                className={`textUnderlineNone ${
                  isLinkDisabledInOffline ? 'btnLinkDisabled' : ''
                }`}
              >
                <ButtonWithIcon
                  name={actionName}
                  label={actionName}
                  disabled={isLinkDisabledInOffline}
                >
                  <OfflineIcon color={iconColor} className={iconClassName} />
                </ButtonWithIcon>
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
