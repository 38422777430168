import React, { FC, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { generatePath, useParams } from 'react-router-dom'
import { VisitReportImportFileSection } from './VisitReportImportFileSection'
import {
  ISectionRowData,
  IVisitReportForm,
  IVisitReportInformation,
  IVisitReportSectionRow,
} from '../../../../interfaces/IVisitReports'
import Checkbox from '../../../form/Checkbox'
import TextInput from '../../../form/TextInput'
import { getNWord } from '../../../../utils/helpers'
import DynamicFormattedMessage from '../../../common/ui/DynamicFormattedMessage'
import { useIsTabDisabled } from '../../../../hooks/clientVisitReport/UseIsTabDisabled'
import {
  getLastVisitReportFieldValue,
  validateNumberInput,
  validateTextInput,
} from '../../../../utils/visitReports'
import { VisitReportNewTabButton } from './VisitReportNewTabButton'
import { ROUTES } from '../../../../enums/routes'
import { INPUT_TYPE } from '../../../../enums/common'
import { InfoIconWithTooltip } from '../../../common/Tooltip/InfoIconWithTooltip'
import WysiwygSection from '../../../form/WysiwygSection'

export const VisitReportSectionRow: FC<IVisitReportSectionRow> = ({
  data,
  contractTypes,
  customClass = '',
  camera,
  isCameraOpen,
  defaultData,
  updateForm,
  informationData,
  children,
}) => {
  const { clientId } = useParams()
  const isDisabled = useIsTabDisabled()
  const { register, errors, watch, control } = useFormContext()
  const {
    check,
    checkLabel,
    checkDisabled,
    imgKey,
    input,
    subscribed,
    wysiwygKey,
  } = data
  const watchedFields = watch([check as string])

  const isVisible = useMemo(() => !!watchedFields[check as string], [
    check,
    watchedFields,
  ])

  const subscribedValue: boolean = useMemo(
    () =>
      getLastVisitReportFieldValue(
        informationData as IVisitReportInformation,
        subscribed as keyof ISectionRowData
      ),
    [subscribed, informationData]
  )

  const lastVisitReportWysiwygValue = useMemo(
    () =>
      getLastVisitReportFieldValue(
        informationData as IVisitReportInformation,
        wysiwygKey as keyof ISectionRowData
      ),
    [wysiwygKey, informationData]
  )

  const contractType = useMemo(() => {
    if (contractTypes?.length) {
      return contractTypes.find(
        (contract) => !checkDisabled && contract?.docType === check
      )
    }

    return undefined
  }, [contractTypes, check, checkDisabled])

  const openNewRoute = useMemo(
    () =>
      contractType?.id
        ? generatePath(ROUTES.newEngagement, {
            clientId,
            contractId: contractType.id,
          })
        : generatePath(ROUTES.clientForms, {
            clientId,
          }),
    [clientId, contractType]
  )

  const dataSectionVisible = useMemo(
    () =>
      (isVisible && input?.key) ||
      (isVisible && children) ||
      (isVisible && imgKey),
    [isVisible, input, children, imgKey]
  )

  return (
    <div
      className={`visitReportRow ${check}Row row mx0 py15 flexColumn hasBorderBottom`}
    >
      <div className="col12 dFlex flexWrap alignItemsCenter">
        <div className="py05">
          <DynamicFormattedMessage
            id={`form.visitReport.row.${check}.label`}
            tag="h4"
            className="textPrimary fontBold wordBreakAll w200"
          />
        </div>
        <div className="dFlex flexWrap alignItemsCenter">
          {subscribed && (
            <DynamicFormattedMessage
              id="form.field.subscribed.label"
              tag="p"
              className={`textPrimary wordBreakAll ${
                subscribedValue ? 'checked' : 'unchecked'
              }`}
            />
          )}
          <Checkbox
            name={check}
            id={check}
            register={register}
            defaultValue={false}
            customClass={`my05 customCheckbox dFlex noBorder ${
              !checkLabel ? 'noLabel' : ''
            }`}
            labelText={checkLabel || ''}
            hasLabel={!!checkLabel}
            disabled={checkDisabled || (!!subscribed && !subscribedValue)}
          />
          {checkDisabled && (
            <InfoIconWithTooltip
              tooltipLabel="info.tooltipCheckDisabled.label"
              customClass="row"
            />
          )}
          {isVisible && contractType && (
            <VisitReportNewTabButton name="newContract" route={openNewRoute} />
          )}
        </div>
      </div>
      <div
        className={`col12 px0 dFlex flexWrap alignItemsCenter ${
          dataSectionVisible ? 'mt1' : ''
        } ${customClass || subscribed}`}
      >
        {input?.key && (
          <TextInput
            dataType={check}
            name={input?.key}
            id={input?.key}
            register={register({
              required: isVisible && !isDisabled,
              validate: (value) =>
                input?.type === INPUT_TYPE.text
                  ? validateTextInput(
                      value,
                      isVisible && !isDisabled,
                      input?.max
                    )
                  : validateNumberInput(
                      value,
                      isVisible && !isDisabled,
                      input?.max
                    ),
            })}
            error={errors[input?.key]}
            placeholder="typeIn"
            defaultValue={
              defaultData[input?.key as keyof IVisitReportForm] as string
            }
            customClass={
              isVisible ? 'rowInputField mandatory mx15 my05 textXs' : 'dNone'
            }
            labelText={`visitReport.${
              input?.label || getNWord(input?.key).toLowerCase()
            }`}
          />
        )}
        <div className={isVisible && children ? 'row mx15 col12 px0' : 'dNone'}>
          {children}
        </div>
      </div>
      {!!imgKey && (
        <VisitReportImportFileSection
          dataType={check}
          imgKey={imgKey}
          isVisible={isVisible}
          camera={camera}
          isCameraOpen={isCameraOpen}
          defaultData={defaultData}
          updateForm={updateForm}
        />
      )}
      {wysiwygKey && (
        <div
          className={isVisible ? 'col12 colLg6 mt1 mb05' : 'dNone'}
          data-parent={check}
          data-name={wysiwygKey}
        >
          <WysiwygSection
            name={wysiwygKey}
            control={control}
            errors={errors}
            defaultValue={lastVisitReportWysiwygValue}
            shouldValidate={isVisible && !isDisabled}
          />
        </div>
      )}
    </div>
  )
}
