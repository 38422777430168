import React from 'react'
import ClientDetailsCard from '../clientDetailsCard/ClientDetailsCard'
import { IClients, TClientDetailsItem } from '../../interfaces/IClientDetails'

const ClientsCardsSection = ({
  data,
  offset,
  currentPage,
  backRoute,
}: IClients) => {
  return (
    <section className="clientDetailsSection">
      <ul className="clientDetailsList unstyledList">
        {data.map((client: TClientDetailsItem) => (
          <ClientDetailsCard
            key={client?.id}
            client={client}
            offset={offset}
            currentPage={currentPage}
            backRoute={backRoute ?? ''}
          />
        ))}
      </ul>
    </section>
  )
}

export default ClientsCardsSection
