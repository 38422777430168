import { useEffect, useState } from 'react'
import { INITIAL_ACTIVE_PAGE } from '../enums/common'

/**
 * Hook used to set the pagination
 */
export const usePagination = <T,>(
  data: T[],
  itemsPerPage: number,
  page: number = INITIAL_ACTIVE_PAGE
) => {
  const [currentPage, setCurrentPage] = useState(page)
  const [slicedData, setSlicedData] = useState([])
  const maxPage = data?.length ? Math.ceil(data?.length / itemsPerPage) : 1

  useEffect(() => {
    const currentData = (items: any) => {
      const begin = (currentPage - 1) * itemsPerPage
      const end = begin + itemsPerPage

      if (items?.length > itemsPerPage) {
        return items.slice(begin, end)
      }

      return data
    }

    setSlicedData(currentData(data))
  }, [currentPage, data, itemsPerPage])

  return { setCurrentPage, slicedData, currentPage, maxPage }
}
