import React, { FC } from 'react'
import ImageUploading, { ImageListType } from 'react-images-uploading'
import { useImageUploadHook } from '../../hooks/UseImageUploadHook'
import Button from '../common/ui/Button'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'
import { ICustomImageUpload } from '../../interfaces/IVisitReports'
import ImageUploadButton from './customImageUpload/ImageUploadButton'
import ImageDeleteButton from './customImageUpload/ImageDeleteButton'

export const CustomImageUpload: FC<ICustomImageUpload> = ({
  type,
  updateForm,
  isDisabled,
  showUploadBtn,
  showSimpleUploadButton = true,
  onUploadCallback,
  btnDeleteClass,
  onImageDeleteCallback,
  customImageUploadWrapper,
}) => {
  const { images, maxNumber, onChange } = useImageUploadHook()

  return (
    <div
      className={`customImageUpload dInlineBlock ${customImageUploadWrapper}`}
    >
      <ImageUploading
        multiple={false}
        value={images}
        onChange={(imageList: ImageListType) => {
          onChange(imageList, type)
          if (onUploadCallback) {
            onUploadCallback()
          }
          updateForm()
        }}
        maxNumber={maxNumber}
        acceptType={['jpg', 'jpeg', 'png', 'heic', 'heif']}
        maxFileSize={4000000}
      >
        {({ imageList, onImageUpload, onImageRemove, errors }) => (
          <div className="customImageUploadWrapper dFlex alignItemsStart justifyContentStart mb2">
            {!imageList.length ? (
              <ImageUploadButton
                isSimpleUploadButton={showSimpleUploadButton}
                isDisabled={isDisabled}
                onImageUpload={onImageUpload}
              />
            ) : (
              imageList.map((image, index) => (
                <div
                  key={image.dataURL}
                  className="customImageUploadBtnWrapper"
                >
                  <ImageDeleteButton
                    showSimpleUploadButton={showSimpleUploadButton}
                    onImageRemove={onImageRemove}
                    index={index}
                    className={btnDeleteClass}
                    onImageDeleteCallback={onImageDeleteCallback}
                  />
                  <DynamicFormattedMessage
                    id="upload.button.label"
                    tag={Button}
                    className="btn btnPrimary btnUpload btnUploadImage mr15 btnDisabled mt2"
                    shouldRender={showUploadBtn}
                    disabled={true}
                  />
                </div>
              ))
            )}
            {errors && (
              <div>
                {errors.acceptType && (
                  <DynamicFormattedMessage
                    tag="span"
                    id="form.validation.imagesType"
                    className="errorMessage"
                  />
                )}
                {errors.maxFileSize && (
                  <DynamicFormattedMessage
                    tag="span"
                    id="form.validation.maxFileSize"
                    className="errorMessage"
                  />
                )}
              </div>
            )}
          </div>
        )}
      </ImageUploading>
    </div>
  )
}
