import React, { FC } from 'react'
import {
  IHistoryTableData,
  IHistoryTableRow,
} from '../../../../interfaces/IHistory'
import { TYPE_SUMMARY } from '../../../../constants/tables'

export const CustomTableHistoryQuarter: FC<IHistoryTableData> = ({ data }) => {
  return (
    <>
      {data &&
        data.length !== 0 &&
        data.map(
          (
            { type, name, currentYear, previousYear }: IHistoryTableRow,
            index
          ) => (
            <tr
              key={index.toString()}
              className={`textPrimary textCustomXs ${
                type === TYPE_SUMMARY && 'fontBold backgroundNeutralLight'
              }`}
            >
              <td>{name}</td>
              <td className="textCenter">{currentYear?.Q1?.quantity || '-'}</td>
              <td className="textCenter">{currentYear?.Q2?.quantity || '-'}</td>
              <td className="textCenter">{currentYear?.Q3?.quantity || '-'}</td>
              <td className="textCenter">{currentYear?.Q1?.value || '-'}</td>
              <td className="textCenter">{currentYear?.Q2?.value || '-'}</td>
              <td className="textCenter">{currentYear?.Q3?.value || '-'}</td>
              <td className="textCenter">
                {previousYear?.Q1?.quantity || '-'}
              </td>
              <td className="textCenter">
                {previousYear?.Q2?.quantity || '-'}
              </td>
              <td className="textCenter">
                {previousYear?.Q3?.quantity || '-'}
              </td>
              <td className="textCenter">{previousYear?.Q1?.value || '-'}</td>
              <td className="textCenter">{previousYear?.Q2?.value || '-'}</td>
              <td className="textCenter">{previousYear?.Q3?.value || '-'}</td>
            </tr>
          )
        )}
    </>
  )
}
