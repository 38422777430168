import {
  IChartUser,
  IDashboardCa,
  IDashboardCaFilters,
  IDashboardVisitsCoverageFilters,
  IFiltersClientCollaboration,
  ITurnoverData,
  IVisitsCoverageChartData,
  IVisitsCoverageData,
} from '../interfaces/IDashboard'
import { IActivityItems } from '../interfaces/IUsers'

export const SELECTED_USER_PARAM = 'selectedUser'
export const GROUPMENT_QUERY_PARAM = 'groupIds'
export const MARQUE_QUERY_PARAM = 'productLineNames'
export const CIBLE2_QUERY_PARAM = 'targetTypeIds'

export const SELECTED_USER_FILTER = 'userFilter'
export const GROUPMENT_FILTER = 'groupIds'
export const MARQUE_FILTER = 'marque'
export const CIBLE2_FILTER = 'targetType2Ids'

export const CLIENTS_ACTIVITY = 'clientsActivity'
export const PROSPECTS_ACTIVITY = 'prospectsActivity'

export const ACTIVITY_REPORT_WITH_PERCENTAGE: (keyof IActivityItems)[] = [
  'appointmentsWithVisitReportPercentage',
  'appointmentsWithoutVisitReportPercentage',
  'visitsPercentage',
  'opportunityVisitsPercentage',
]

export const FIELDS_WITH_LABEL = [MARQUE_QUERY_PARAM]

export const DASHBOARD_CA_FILTER_QUERY_MAPPING = {
  userFilter: SELECTED_USER_PARAM,
  groupIds: GROUPMENT_QUERY_PARAM,
  marque: MARQUE_QUERY_PARAM,
  targetType2Ids: CIBLE2_QUERY_PARAM,
} as const

export const DASHBOARD_CA_QUERY_FILTER_PARAMS_MAPPING = {
  selectedUser: SELECTED_USER_FILTER,
  groupIds: GROUPMENT_FILTER,
  productLineNames: MARQUE_FILTER,
  targetTypeIds: CIBLE2_FILTER,
}

export const DASHBOARD_CA_QUERY_PARAMS_MAPPING = {
  groupIds: GROUPMENT_QUERY_PARAM,
  productLineNames: MARQUE_QUERY_PARAM,
  targetTypeIds: CIBLE2_QUERY_PARAM,
}

export const dashboardCaFiltersInitialState: IDashboardCaFilters = {
  marque: [],
  groupIds: [],
  targetType2Ids: [],
}

export type TDashboardCaFilterItem = {
  [key in keyof typeof DASHBOARD_CA_FILTER_QUERY_MAPPING]: string
}

export const DASHBOARD_CA_LAST_YEAR_COLOR = 'rgba(255, 99, 132, 0.5)'
export const DASHBOARD_CA_CURRENT_YEAR_COLOR = 'rgba(53, 162, 235, 0.5)'

// Dashboard Visits Coverage
export const FILTER_START_DATE = 'startDate'
export const FILTER_END_DATE = 'endDate'
export const FILTER_CLIENT = 'colaborationCode'
export const FILTER_CLIENT_DEFAULT_OPTION = '3'
export const FILTER_CLIENT_CURRENT_YEAR = 'recentColaborationCode'
export const FILTER_CLIENT_PREVIOUS_YEAR = 'previousColaborationCode'
export const FILTER_TARGET_TYPE = 'targetTypeIds'

export const FILTER_CLIENT_YEARS: (keyof IFiltersClientCollaboration)[] = [
  FILTER_CLIENT_CURRENT_YEAR,
  FILTER_CLIENT_PREVIOUS_YEAR,
]

export const dashboardVisitsCoverageFiltersInitialState: IDashboardVisitsCoverageFilters = {
  startDate: '',
  endDate: '',
  recentColaborationCode: FILTER_CLIENT_DEFAULT_OPTION,
  previousColaborationCode: FILTER_CLIENT_DEFAULT_OPTION,
  groupIds: [],
}

export const dashboardCaUserDefault: IChartUser = {
  id: '',
  name: '',
}

export const dashboardCaInitialState: IDashboardCa = {
  filtersCa: dashboardCaFiltersInitialState,
  defaultSelection: true,
  clearQuery: false,
  currentChartUser: dashboardCaUserDefault,
  chartData: {} as ITurnoverData,
}

export const DEFAULT_CHART_DATA_VISIT_COVERAGE: IVisitsCoverageChartData = [
  100,
  0,
]

export const DEFAULT_VISIT_COVERAGE_DATA_PER_TYPE: IVisitsCoverageData = {
  visitedPharmacies: 0,
  unvisitedPharmacies: 0,
  visits: 0,
  visitsFrequency: 0,
}
