import React, { FC, useEffect } from 'react'
import FormGroupDate from '../form/FormGroupDate'
import Checkbox from '../form/Checkbox'
import { IFilterSection } from '../../interfaces/IMap'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'
import { InfoIconWithTooltip } from '../common/Tooltip/InfoIconWithTooltip'
import { MapDirectionsSection } from '../map/MapDirectionsSection'
import ErrorMessage from '../form/ErrorMessage'
import {
  FIELD_ACTION_APPOINTMENT,
  FIELD_ACTION_DATE,
  FIELD_ACTION_VISITE_REPORT,
  NO_ACTION_ERROR,
} from '../../constants/map'
import { ADVANCED_SEARCH_SELECT_INPUT_FIELDS } from '../../constants/filters'
import { useMapFiltersForm } from '../../hooks/map/UseMapFiltersForm'

export const MapFiltersForm: FC<
  IFilterSection<typeof ADVANCED_SEARCH_SELECT_INPUT_FIELDS>
> = ({ filters, setFilters }) => {
  const {
    onSubmit,
    triggerSubmit,
    mapStartDate,
    register,
    errors,
    handleSubmit,
    onDateChange,
    mapDate,
    setError,
    watch,
    clearErrors,
    urlValueVisiteReport,
    urlValueAppointment,
  } = useMapFiltersForm(filters, setFilters)

  const date = watch(FIELD_ACTION_DATE)
  const appointment = watch(FIELD_ACTION_APPOINTMENT)
  const visiteReport = watch(FIELD_ACTION_VISITE_REPORT)

  useEffect(() => {
    if (date && !appointment && !visiteReport) {
      setError(NO_ACTION_ERROR, { type: 'warning', message: 'mapDateWarning' })
    } else {
      clearErrors(NO_ACTION_ERROR)
    }
  }, [mapDate, setError, clearErrors, date, appointment, visiteReport])

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="mapFiltersForm withBackgroundColorDefault"
      id="mapFiltersForm"
    >
      <DynamicFormattedMessage
        tag="h4"
        id="form.section.mapFilters.label"
        className="textPrimary fontMedium px05 pt2 pb15"
      />
      <div className="dFlex flexWrap px0 mb05">
        <div className="mapFiltersFormDate dFlex alignItemsCenter px05 mb2">
          <FormGroupDate
            startDate={mapStartDate}
            startDateOnly={true}
            onDateChange={onDateChange}
          />
          <InfoIconWithTooltip tooltipLabel="info.tooltipMapFilters.label" />
        </div>
        <div className="mapFiltersFormOptions dFlex flexWrap px1">
          <Checkbox
            name="appointment"
            id="appointment"
            register={register}
            defaultValue={urlValueAppointment}
            onChange={triggerSubmit}
            error={errors.appointment}
            customClass="mb2 mr1"
          />
          <Checkbox
            name="visiteReport"
            id="visiteReport"
            register={register}
            defaultValue={urlValueVisiteReport}
            onChange={triggerSubmit}
            error={errors.visiteReport}
            customClass="mb2"
          />
        </div>
        <ErrorMessage
          error={errors[NO_ACTION_ERROR]}
          className="noActionError dFlex flexWrap px1"
        />
        <div className="mapFiltersDirections dFlex flexWrap px1">
          <div className="mb2 mr1 dInlineFlex">
            <MapDirectionsSection filters={filters} />
          </div>
        </div>
      </div>
    </form>
  )
}
