import { useEffect, useState } from 'react'
import { TClientDetailsItem } from '../../interfaces/IClientDetails'
import { IMarkerType } from '../../interfaces/IMap'
import { mapMarkerYellow } from '../../assets/images/images'
import { getMapMarker } from '../../utils/map'

/**
 * Hook used to set the map marker type for each client.
 */

export const useMapMarker: (client: TClientDetailsItem) => IMarkerType = (
  client
) => {
  const [markerType, setMarkerType] = useState(mapMarkerYellow)

  useEffect(() => {
    setMarkerType(getMapMarker(client))
  }, [client])

  return { markerType }
}
