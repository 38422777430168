import { useForm } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import set from 'lodash.set'
import { useIntl } from 'react-intl'
import {
  IDashboardCaFilters,
  IDashboardCaFiltersSection,
  ITurnoverData,
} from '../../interfaces/IDashboard'
import { currentYearValue } from '../../utils/helpers'
import { useLoadData } from '../UseLoadData'
import { getOptionsService } from '../../services/getClientsListService'
import {
  CLUSTER_ROUTE,
  MARQUE_ROUTE,
  STATUS,
  TYPE_CIBLE_2_ROUTE,
} from '../../enums/common'
import {
  dashboardCaFiltersInitialState,
  TDashboardCaFilterItem,
  CIBLE2_QUERY_PARAM,
  DASHBOARD_CA_FILTER_QUERY_MAPPING,
  DASHBOARD_CA_QUERY_FILTER_PARAMS_MAPPING,
  DASHBOARD_CA_QUERY_PARAMS_MAPPING,
  GROUPMENT_QUERY_PARAM,
  MARQUE_QUERY_PARAM,
  GROUPMENT_FILTER,
  MARQUE_FILTER,
  CIBLE2_FILTER,
  dashboardCaInitialState,
  SELECTED_USER_PARAM,
  dashboardCaUserDefault,
  FIELDS_WITH_LABEL,
} from '../../constants/dashboard'
import { ISelectOption } from '../../interfaces/IFilters'
import {
  isValidSelection,
  prepareFieldSelectOptions,
} from '../../utils/dashboard'
import { DASHBOARD_CA } from '../../constants/routes'
import { dashboardCaActions } from '../../store/reducers/dashboardCaReducer'
import { dashboardCaSelectors } from '../../store/selectors'
import { sharedDataActions } from '../../store/reducers/sharedDataReducer'
import { getDashboardService } from '../../services/dashboardServices'
import { API_PATHS } from '../../enums/apiPaths'
import { getJwtUserDetails, handleError } from '../../utils/api'

export const useDashboardFiltersHook = ({
  filters,
  selectedUser,
  callbackOnSubmit,
  formState,
  setFormState,
  usersStatusPending,
  shouldUseLoggedUserId,
}: IDashboardCaFiltersSection) => {
  const intl = useIntl()
  const { clearQuery } = useSelector(dashboardCaSelectors.getDashboardData)
  const {
    handleSubmit,
    errors,
    control,
    setValue,
    getValues,
  } = useForm<IDashboardCaFilters>({
    mode: 'onChange',
  })
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const currentYear = currentYearValue
  const user = useRef(selectedUser?.id)

  const marqueeData = useLoadData(() => getOptionsService(MARQUE_ROUTE))
  const groupements = useLoadData(() => getOptionsService(CLUSTER_ROUTE))
  const type2 = useLoadData(() => getOptionsService(TYPE_CIBLE_2_ROUTE))

  const [showFilterWarning, setShowFilterWarning] = useState(false)
  const [defaultSelection, setDefaultSelection] = useState<IDashboardCaFilters>(
    dashboardCaFiltersInitialState
  )
  const [isQueryParams, setIsQueryParams] = useState(false)
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const [isQueryReady, setIsQueryReady] = useState(false)

  const prepareSelectOptions = useCallback(
    (field: string, value: string) => {
      switch (field) {
        case GROUPMENT_QUERY_PARAM:
          return prepareFieldSelectOptions(groupements.data, value)
        case MARQUE_QUERY_PARAM:
          return prepareFieldSelectOptions(marqueeData.data, value, false)
        case CIBLE2_QUERY_PARAM:
          return prepareFieldSelectOptions(type2.data, value)
        default:
          return {}
      }
    },
    [groupements.data, marqueeData.data, type2.data]
  )

  const filtersLoadStatusPending = useMemo(
    () =>
      groupements.status === STATUS.PENDING ||
      marqueeData.status === STATUS.PENDING ||
      type2.status === STATUS.PENDING,
    [groupements, marqueeData, type2]
  )

  const formDataForSelectStatusPending = useMemo(
    () => filtersLoadStatusPending || usersStatusPending,
    [filtersLoadStatusPending, usersStatusPending]
  )

  const loadFiltersFromUrlQuery = useCallback(async () => {
    if (!isQueryParams) return
    const searchParams: URLSearchParams = new URLSearchParams(location.search)
    searchParams.forEach((value, key) => {
      if (key === SELECTED_USER_PARAM) {
        dispatch(sharedDataActions.setSelectedUser(value))
        user.current = value

        return
      }
      const filterKey =
        DASHBOARD_CA_QUERY_FILTER_PARAMS_MAPPING[
          key as keyof typeof DASHBOARD_CA_QUERY_FILTER_PARAMS_MAPPING
        ]
      if (filterKey) {
        setValue(
          filterKey,
          prepareSelectOptions(
            DASHBOARD_CA_QUERY_PARAMS_MAPPING[
              key as keyof typeof DASHBOARD_CA_QUERY_PARAMS_MAPPING
            ],
            value
          ),
          { shouldDirty: true }
        )
      }
    })
    dispatch(dashboardCaActions.setIsDefaultSelection(false))
    dispatch(dashboardCaActions.setCaFilters(getValues()))
  }, [
    location.search,
    getValues,
    setValue,
    isQueryParams,
    prepareSelectOptions,
    dispatch,
  ])

  const resetFormMultiselectInputs = useCallback(
    (inputs: string[]) => {
      inputs.forEach((input) => {
        setValue(input, [])
      })
    },
    [setValue]
  )

  const selectedUserParam = useMemo(() => {
    if (!selectedUser) return {}

    return set(
      {} as TDashboardCaFilterItem,
      'selectedUser' as keyof typeof DASHBOARD_CA_FILTER_QUERY_MAPPING,
      selectedUser ? selectedUser!.id : 0
    )
  }, [selectedUser])

  const prepareUrlQueryFromFilters = useCallback(
    (values: IDashboardCaFilters) => {
      if (!values) return
      const params: string[] = []
      params.push(new URLSearchParams(selectedUserParam).toString())
      Object.entries(values).forEach(([key, value]) => {
        if (!value.length) return

        const filter = value.map((item: ISelectOption) =>
          key === MARQUE_FILTER ? item.label : item.value
        )
        const obj = set(
          {} as TDashboardCaFilterItem,
          DASHBOARD_CA_FILTER_QUERY_MAPPING[
            key as keyof typeof DASHBOARD_CA_FILTER_QUERY_MAPPING
          ],
          filter.join(',')
        )
        params.push(new URLSearchParams(obj).toString())
      })

      return params.join('&')
    },
    [selectedUserParam]
  )

  const fetchChartData = useCallback(
    async (values: IDashboardCaFilters) => {
      setFormState({
        status: STATUS.PENDING,
        message: '',
        messageCode: '',
      })

      const submitValues = {
        [MARQUE_QUERY_PARAM]: values[MARQUE_FILTER],
        [CIBLE2_FILTER]: values[CIBLE2_FILTER],
        [GROUPMENT_FILTER]: values[GROUPMENT_FILTER],
      }

      try {
        const chartResponse = await getDashboardService<ITurnoverData>(
          { ...submitValues },
          user.current,
          API_PATHS.turnover,
          FIELDS_WITH_LABEL
        )
        dispatch(dashboardCaActions.setChartData(chartResponse))
        setFormState({
          status: STATUS.SUCCESS,
          message: '',
          messageCode: '',
        })
      } catch (error) {
        const handledError = handleError(error, intl)
        setFormState(handledError)
      }
    },
    [dispatch, intl, setFormState]
  )

  const onSubmit = async (values: IDashboardCaFilters) => {
    if (!isValidSelection(getValues())) {
      setShowFilterWarning(true)
      return
    }

    user.current = selectedUser?.id
    setShowFilterWarning(false)
    // filters changed on submit, reset default selection
    dispatch(dashboardCaActions.setIsDefaultSelection(false))
    dispatch(dashboardCaActions.setCaFilters(values))
    callbackOnSubmit()

    setIsFormSubmitted(true)
    await fetchChartData(values)
    history.push({
      pathname: `${DASHBOARD_CA}`,
      search: `?${prepareUrlQueryFromFilters(values)}`,
    })
  }

  const resetFilters = () => {
    setFormState({
      status: STATUS.IDLE,
      message: '',
      messageCode: '',
    })

    dispatch(dashboardCaActions.setCaFilters(dashboardCaFiltersInitialState))
    dispatch(dashboardCaActions.setCurrentChartUser(dashboardCaUserDefault))
    resetFormMultiselectInputs([MARQUE_FILTER, GROUPMENT_FILTER, CIBLE2_FILTER])
    setShowFilterWarning(false)
    if (isQueryParams) {
      dispatch(dashboardCaActions.setClearQuery(true))
      history.push({
        pathname: `${DASHBOARD_CA}`,
        search: '',
      })
    }
  }

  const isRegularPageLoad = useMemo(() => {
    return !isQueryParams && !clearQuery
  }, [isQueryParams, clearQuery])

  useEffect(() => {
    if (isValidSelection(getValues())) {
      setShowFilterWarning(false)
    }
  }, [filters, getValues])

  useEffect(() => {
    if (isRegularPageLoad) {
      setDefaultSelection((previousState) => ({
        ...previousState,
        ...{ marque: marqueeData.data },
      }))
    }
  }, [isRegularPageLoad, marqueeData])

  useEffect(() => {
    if (isRegularPageLoad) {
      setDefaultSelection((previousState) => ({
        ...previousState,
        ...{ groupIds: groupements.data },
      }))
    }
  }, [isRegularPageLoad, groupements])

  useEffect(() => {
    if (isRegularPageLoad) {
      setDefaultSelection((previousState) => ({
        ...previousState,
        ...{ targetType2Ids: type2.data },
      }))
    }
  }, [isRegularPageLoad, type2])

  useEffect(() => {
    if (isRegularPageLoad) {
      dispatch(dashboardCaActions.setCaFilters(defaultSelection))
    }
  }, [dispatch, isRegularPageLoad, defaultSelection])

  useEffect(() => {
    setIsQueryParams(!!location.search)
    loadFiltersFromUrlQuery().then(() => setIsQueryReady(true))
  }, [loadFiltersFromUrlQuery, location.search])

  useEffect(() => {
    if (!filters) return
    Object.entries(filters).forEach((filter) => {
      setValue(filter[0], filter[1])
    })
  }, [filters, setValue])

  useEffect(() => {
    return () => {
      dispatch(dashboardCaActions.setDashboardCaData(dashboardCaInitialState))
    }
  }, [dispatch])

  const shouldLoadFromQuery = useMemo(
    () =>
      !isRegularPageLoad &&
      !isFormSubmitted &&
      isQueryReady &&
      !formDataForSelectStatusPending,

    [
      isRegularPageLoad,
      isFormSubmitted,
      isQueryReady,
      formDataForSelectStatusPending,
    ]
  )

  const setCurrentUser = useCallback(async () => {
    if (shouldUseLoggedUserId) {
      user.current = getJwtUserDetails()?.id.toString()
    }
  }, [shouldUseLoggedUserId])

  useEffect(() => {
    const queryValues = {
      ...(getValues() as IDashboardCaFilters),
    }

    if (!shouldLoadFromQuery || !isValidSelection(queryValues)) return

    setFormState({
      status: STATUS.PENDING,
      message: '',
      messageCode: '',
    })

    setCurrentUser()
      .then(() => dispatch(sharedDataActions.setSelectedUser(user.current)))
      .then(() => {
        fetchChartData(queryValues).then(() => setIsQueryReady(false))
      })
    // eslint-disable-next-line
  }, [getValues, fetchChartData, dispatch, shouldLoadFromQuery, setCurrentUser])

  const disabledActionButtons = useMemo(
    () => formState.status === STATUS.PENDING || formDataForSelectStatusPending,
    [formState.status, formDataForSelectStatusPending]
  )

  return {
    handleSubmit,
    errors,
    control,
    setValue,
    getValues,
    onSubmit,
    resetFilters,
    currentYear,
    showFilterWarning,
    groupements,
    type2,
    marqueeData,
    filtersLoadStatusPending,
    disabledActionButtons,
  }
}
