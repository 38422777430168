import React, { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'
import ErrorMessage from './ErrorMessage'
import { DYNAMIC_BUTTONS_INPUT_TYPE } from '../../enums/common'
import { useDynamicButtons } from '../../hooks/form/UseDynamicButtons'
import { IDynamicButtons } from '../../interfaces/IForms'

const DynamicButtons: FC<IDynamicButtons> = ({
  defaultValue,
  optionsArray,
  labelName,
  labelValues,
  register,
  error,
  name,
  customClass = '',
  onChange = () => {},
  inputType = DYNAMIC_BUTTONS_INPUT_TYPE.radio,
  multipleCheck = false,
  setValue,
}) => {
  const formContext = useFormContext()
  const { handleOnChange } = useDynamicButtons(
    name,
    inputType,
    multipleCheck,
    onChange,
    formContext?.setValue || setValue
  )

  return (
    <div className={`inputGroup ${customClass && customClass}`}>
      <DynamicFormattedMessage
        id={`form.field.${labelName || name}.label`}
        className="inputLabel"
        values={labelValues}
      />
      <div className="radioButtonsWrapper dFlex flexWrap">
        {!!optionsArray?.length &&
          optionsArray.map(({ value, label }, index) => (
            <label
              htmlFor={`${name}${value.replace(/ /g, '')}`}
              key={index.toString()}
              className="inputRadioWrapper relative hasBorderRadius hasBoxShadow px1 py1"
            >
              <input
                type={inputType}
                name={name}
                id={`${name}${value.replace(/ /g, '')}`}
                value={value}
                ref={register}
                className="inputRadio"
                onChange={handleOnChange}
                defaultChecked={defaultValue === value}
              />
              <span className="inputRadioMask hasBorderRadius" />
              <span className="inputRadioLabel">{label}</span>
            </label>
          ))}
      </div>
      <ErrorMessage error={error} />
    </div>
  )
}

export default DynamicButtons
