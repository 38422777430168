import React, { FC } from 'react'
import { TYPE_SUMMARY } from '../../../../constants/tables'
import {
  IHistoryTableData,
  IHistoryTableRow,
} from '../../../../interfaces/IHistory'

export const SemesterTableContent: FC<IHistoryTableData> = ({ data }) => {
  return (
    <>
      {data &&
        data.length !== 0 &&
        data.map(
          (
            { type, name, currentYear, previousYear }: IHistoryTableRow,
            index: number
          ) => (
            <tr
              key={index.toString()}
              className={`textPrimary textCustomXs ${
                type === TYPE_SUMMARY && 'fontBold backgroundNeutralLight'
              }`}
            >
              <td>{name}</td>
              <td className="textCenter">{currentYear?.S1?.quantity || '-'}</td>
              <td className="textCenter">{currentYear?.S2?.quantity || '-'}</td>
              <td className="textCenter">{currentYear?.S1?.value || '-'}</td>
              <td className="textCenter">{currentYear?.S2?.value || '-'}</td>
              <td className="textCenter">
                {previousYear?.S1?.quantity || '-'}
              </td>
              <td className="textCenter">
                {previousYear?.S2?.quantity || '-'}
              </td>
              <td className="textCenter">{previousYear?.S1?.value || '-'}</td>
              <td className="textCenter">{previousYear?.S2?.value || '-'}</td>
            </tr>
          )
        )}
    </>
  )
}
