import React, { FC } from 'react'
import { format } from 'date-fns'
import { InformationDataRow } from './InformationDataRow'
import DynamicFormattedMessage from '../../../common/ui/DynamicFormattedMessage'
import { REPORT_DATETIME_FORMAT } from '../../../../enums/common'
import { handleHtmlContent } from '../../../../utils/helpers'
import { IInformationLastVisitReport } from '../../../../interfaces/IVisitReports'

export const InformationLastVisitReport: FC<IInformationLastVisitReport> = ({
  lastVisiteDate,
  lastVisiteType,
  lastVisiteComment,
}) => {
  return (
    <InformationDataRow label="lastVisit">
      {lastVisiteDate ? (
        <div className="colMd9 px0 py05 dFlex flexWrap alignItemsCenter">
          <div className="px15">
            <DynamicFormattedMessage
              id="form.visitReport.information.lastVisit.date.label"
              tag="p"
              className="dInlineBlock fontMedium mb15 pr15"
            />
            <p className="dInlineBlock">
              {(lastVisiteDate &&
                format(new Date(lastVisiteDate), REPORT_DATETIME_FORMAT)) ||
                '-'}
            </p>
          </div>
          <div className="px15">
            <DynamicFormattedMessage
              id="form.visitReport.information.lastVisit.action.label"
              tag="p"
              className="dInlineBlock fontMedium mb15 pr15"
            />
            <p className="dInlineBlock">{lastVisiteType || '-'}</p>
          </div>
          <div className="col12">
            <DynamicFormattedMessage
              id="form.visitReport.information.lastVisit.comment.label"
              tag="p"
              className="dInlineBlock fontMedium mb15 pr15"
            />
            {(lastVisiteComment && (
              <div
                className="dInlineBlock wordBreakAll"
                dangerouslySetInnerHTML={handleHtmlContent(lastVisiteComment)}
              />
            )) ||
              '-'}
          </div>
        </div>
      ) : (
        <DynamicFormattedMessage
          id="form.visitReport.information.noLastVisitReport.label"
          tag="p"
          className="textDanger px15"
        />
      )}
    </InformationDataRow>
  )
}
