import React, {
  FC,
  useRef,
  KeyboardEvent,
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { FIELD_SEARCH } from '../../constants/form'
import { ROUTES } from '../../enums/routes'
import { iconList } from '../../assets/images/images'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'
import { ISearchSection } from '../../interfaces/IMap'
import { Tooltip } from '../common/Tooltip/Tooltip'
import { TooltipContent } from '../common/Tooltip/TooltipContent'
import { MAP } from '../../constants/routes'
import { INITIAL_ACTIVE_PAGE, MAP_LEGEND } from '../../enums/common'
import Button from '../common/ui/Button'
import {
  ADVANCED_SEARCH_SELECT_INPUT_FIELDS,
  advancedFiltersInitialState,
} from '../../constants/filters'
import { clientsFiltersActions } from '../../store/reducers/filtersReducer'
import { convertFiltersDatetime } from '../../utils/data'
import { useUrlParamsHook } from '../../hooks/UseUrlParamsHook'
import { useMapFiltersForm } from '../../hooks/map/UseMapFiltersForm'
import { isFiltersSet } from '../../utils/filters'
import { ActiveFiltersSection } from '../pageFilters/ActiveFiltersSection'
import { clientsFiltersSelectors } from '../../store/selectors'

export const MapSearchSection: FC<
  ISearchSection<typeof ADVANCED_SEARCH_SELECT_INPUT_FIELDS>
> = ({
  setFilters,
  filters,
  changePage,
  fieldsData,
  routes,
  resetRoute,
  callback,
}) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const searchRef = useRef<HTMLInputElement>(null)
  const [accountsPortfolioRoute, setAccountsPortfolioRoute] = useState(
    routes?.accountsPortfolio ?? ROUTES.accountsPortfolio
  )
  const { selectedPage: storeSelectedPage } = useSelector(
    clientsFiltersSelectors.clientsFiltersData
  )
  const { reset, setMapDate } = useMapFiltersForm(filters, setFilters)

  const resetMapFormFilters = useCallback(() => {
    reset({
      ...advancedFiltersInitialState,
      [FIELD_SEARCH]: searchRef?.current?.value,
      appointment: 0,
      visiteReport: 0,
      date: '',
    })
    setMapDate({
      startDate: '',
      endDate: '',
    })

    if (callback) {
      callback()
    }
  }, [callback, reset, setMapDate])

  const { setUrlQueryParams, prepareUrl } = useUrlParamsHook(storeSelectedPage)

  const resetAccountsPortfolioUrl = useCallback(() => {
    resetMapFormFilters()
    setAccountsPortfolioRoute(
      prepareUrl(
        {
          ...advancedFiltersInitialState,
          search: '',
          lastVisitStart: '',
          lastVisitEnd: '',
          appointment: 0,
          visiteReport: 0,
          date: '',
        },
        ROUTES.accountsPortfolio
      )
    )
  }, [prepareUrl, resetMapFormFilters])

  const handleSimpleSearch = () => {
    if (!searchRef?.current?.value) return

    resetMapFormFilters()

    if (changePage) {
      changePage(INITIAL_ACTIVE_PAGE)
    }

    dispatch(
      clientsFiltersActions.setFilters(
        convertFiltersDatetime({
          ...advancedFiltersInitialState,
          [FIELD_SEARCH]: searchRef?.current?.value ?? '',
          appointment: 0,
          visiteReport: 0,
          date: '',
        })
      )
    )

    dispatch(clientsFiltersActions.setSelectedPage(INITIAL_ACTIVE_PAGE))

    setFilters({
      ...advancedFiltersInitialState,
      [FIELD_SEARCH]: searchRef?.current?.value ?? '',
      appointment: 0,
      visiteReport: 0,
      date: '',
    })

    setUrlQueryParams(
      {
        ...advancedFiltersInitialState,
        [FIELD_SEARCH]: searchRef?.current?.value ?? '',
        appointment: 0,
        visiteReport: 0,
        date: '',
      },
      false
    )

    setAccountsPortfolioRoute(
      prepareUrl(
        {
          ...advancedFiltersInitialState,
          [FIELD_SEARCH]: searchRef?.current?.value ?? '',
          appointment: 0,
          visiteReport: 0,
          date: '',
        },
        ROUTES.accountsPortfolio
      )
    )
  }

  // update account portofolio route on filters update
  useEffect(() => {
    setAccountsPortfolioRoute(prepareUrl(filters, ROUTES.accountsPortfolio))
  }, [filters, prepareUrl])

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      handleSimpleSearch()
    }
  }

  // @todo move outside to a common utilities for this and PageFiltersSection
  const showFilters = useMemo(() => (filters ? isFiltersSet(filters) : false), [
    filters,
  ])

  return (
    <section className="dFlex pb15 searchSection">
      <div className="relative inputFieldWrapper pr0 inputSection">
        <form>
          <input
            name={FIELD_SEARCH}
            id={FIELD_SEARCH}
            ref={searchRef}
            placeholder={intl.formatMessage({
              id: 'placeholder.searchOnMap',
            })}
            data-qa="inputSearch"
            className="inputField simpleSearchText"
            onKeyPress={handleKeyPress}
          />
        </form>
      </div>
      <div className="dFlex alignItemsCenter justifyContentBetween btnSection">
        <div className="pageFiltersAdvancedCtaWrapper dFlex">
          <DynamicFormattedMessage
            id="cta.search.button.label"
            tag={Button}
            className="btn btnPrimary"
            data-qa="searchButton"
            onClick={handleSimpleSearch}
          />
        </div>
        <div className="pl0md dFlex textRight alignItemsCenter justifyContentEnd">
          <Tooltip title="tooltip.map.title.label">
            <TooltipContent route={MAP} data={MAP_LEGEND} />
          </Tooltip>
          <Link
            to={accountsPortfolioRoute}
            className="link linkBasic dInlineFlex fontMedium"
            data-qa="ctaSeeAccountPortfolio"
          >
            <img src={iconList} alt="list icon" className="mr05" />
            <DynamicFormattedMessage id="cta.seeList.label" />
          </Link>
        </div>
      </div>
      {filters && showFilters && (
        <ActiveFiltersSection
          setFilters={setFilters}
          changePage={changePage}
          filters={filters}
          fieldsData={fieldsData}
          resetRoute={resetRoute}
          containerClassName="pl0"
          callback={resetAccountsPortfolioUrl}
        />
      )}
    </section>
  )
}
