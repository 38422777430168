import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import { OptionsType } from 'react-select'
import debounce from 'lodash.debounce'
import { Location } from 'history'
import { generatePath, useHistory, useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'
import DynamicButtons from '../form/DynamicButtons'
import FormGroupDate from '../form/FormGroupDate'
import SelectInput from '../form/SelectInput'
import Button from '../common/ui/Button'
import Alerts from '../alerts/Alerts'
import { useNewAppointmentFormHook } from '../../hooks/UseAppointmentForm'
import {
  getAppointmentDetails,
  getAppointmentTypes,
  getContactPersons,
  searchApi,
} from '../../services/appointmentsService'
import {
  IAppointmentDate,
  IAppointmentDefault,
} from '../../interfaces/IAppointments'
import { advancedFiltersInitialState } from '../../constants/filters'
import {
  prepareOptionsForSelect,
  isGreaterThan,
  isSmallerThanClosingHours,
  isGreaterThanOpeningHours,
  getMinHour,
} from '../../utils/appointments'
import { dateInterval, objEqual, getClosingHours } from '../../utils/helpers'
import {
  APPOINTMENT_ACTION,
  DATEPICKER_TIME_FORMAT,
  RANGE_INPUT_DEFAULT,
  RANGE_INPUT_MAX,
  RANGE_INPUT_MIN,
  RANGE_INPUT_STEP,
  STATUS,
} from '../../enums/common'
import { useLoadData } from '../../hooks/UseLoadData'
import ClientsSelectSearch from '../form/ClientsSelectSearch'
import { ISelectOption } from '../../interfaces/IFilters'
import WysiwygSection from '../form/WysiwygSection'
import RangeStepSection from '../form/RangeStepSection'
import { clientContactPersonsActions } from '../../store/reducers/clientContactPersonsReducer'
import { getClientContactPersons } from '../../store/selectors/clientContactPersonsSelectors'
import { getClientsListServiceData } from '../../services/getClientsListService'
import { getAllClientsValue } from '../../store/selectors/allClientsSelectors'
import { getAppointmentTypesValue } from '../../store/selectors/sharedDataSelectors'
import { CALENDAR_VIEW_MONTH } from '../../constants/calendar'
import { ROUTES } from '../../enums/routes'
import {
  SELECT_NO_TIER_OPTIONS_PLACEHOLDER,
  SELECT_OPTIONS_PLACEHOLDER,
} from '../../constants/form'
import { StickyWrapper } from '../common/ui/StickyWrapper'

function AppointmentForm({
  appointmentId,
  setIsModalOpen,
}: {
  appointmentId: number
  setIsModalOpen: Dispatch<SetStateAction<boolean>>
}): ReactElement {
  const intl = useIntl()
  const { state }: Location<IAppointmentDefault> = useLocation()
  const appointmentDetails = useLoadData(() =>
    getAppointmentDetails(appointmentId, APPOINTMENT_ACTION.EDIT)
  )

  const defaultValues = appointmentId ? appointmentDetails.data : state
  const history = useHistory()
  const prevLocation = history?.location?.state?.prevLocation
  const prevState = history?.location?.state?.prevState

  const {
    client,
    startDate,
    endDate,
    comment,
    contactPerson,
    appointmentTypeId,
    offset,
    currentPage,
    viewType,
  } = defaultValues || {}

  const {
    form: { register, handleSubmit, errors, control, getValues, setValue },
    formState: { status, message },
    onSubmit,
  } = useNewAppointmentFormHook(appointmentId, setIsModalOpen)

  const appointmentTypes = useLoadData(() => getAppointmentTypes(), {
    fetchFromRedux: true,
    reduxSelector: getAppointmentTypesValue,
  })
  const { data: clients, status: tiersOptionsLoadingStatus } = useLoadData(
    () => getClientsListServiceData(advancedFiltersInitialState, 0, 20),
    {
      fetchFromRedux: true,
      reduxSelector: getAllClientsValue,
    }
  )

  const options = clients?.length
    ? prepareOptionsForSelect(clients, client)
    : client
    ? [
        {
          label: `${client?.name}${
            client?.cipCode ? ` (${client?.cipCode})` : ''
          }${client?.city ? ` - ${client?.city}` : ''}`,
          value: client?.id.toString(),
        },
      ]
    : []

  const [selectedClient, setSelectedClient] = useState(client?.id)
  const contactPersons = useLoadData(
    () => getContactPersons(selectedClient || client?.id),
    {
      dependencies: [selectedClient, client?.id],
      fetchFromRedux: true,
      storeInRedux: true,
      reduxAction: clientContactPersonsActions.setClientContactPersons,
      reduxSelector: getClientContactPersons,
      reduxStorePath: selectedClient
        ? selectedClient.toString()
        : client?.id
        ? client?.id.toString()
        : '',
    }
  )

  const selectedEventInterval = dateInterval(startDate, endDate)
  const minDuration = appointmentId ? RANGE_INPUT_MIN : RANGE_INPUT_DEFAULT
  const [maxDuration, setMaxDuration] = useState<number>(RANGE_INPUT_MAX)
  const [hoursBeforeClose, setHoursBeforeClose] = useState<number>(
    RANGE_INPUT_MAX
  )

  let rangeDefaultDuration =
    selectedEventInterval > minDuration && viewType !== CALENDAR_VIEW_MONTH
      ? selectedEventInterval
      : minDuration
  if (selectedEventInterval > maxDuration && viewType !== CALENDAR_VIEW_MONTH) {
    rangeDefaultDuration = maxDuration
  }

  const [appointmentDuration, setAppointmentDuration] = useState<number>(
    rangeDefaultDuration
  )

  const [appointmentDate, setAppointmentDate] = useState<IAppointmentDate>({
    startDate: startDate || '',
    endDate: endDate || '',
  })
  const [
    noTiersOptionsPlaceholder,
    setNoTiersOptionsPlaceholder,
  ] = useState<string>(SELECT_OPTIONS_PLACEHOLDER)

  const appointmentStartDate = {
    id: 'startDate',
    value:
      appointmentDate.startDate !== ''
        ? appointmentDate.startDate
        : startDate || getMinHour(new Date()),
    label: 'form.field.startDate',
    setValue: setAppointmentDate,
    classes: 'inputField inputDate datepickerLeft',
    error: errors.startDate,
    displayTime: true,
    format: DATEPICKER_TIME_FORMAT,
    control,
    minDate: new Date(),
    maxDate: new Date(),
    filterTime: true,
    rules: {
      required: true,
      validate: {
        greaterThanNow: !defaultValues?.id ? isGreaterThan : () => {},
        smallerThanClosingHours: isSmallerThanClosingHours,
        greaterThanOpeningHours: isGreaterThanOpeningHours,
      },
    },
  }

  const appointmentFinalDuration = {
    id: 'duration',
    name: 'appointment.duration',
    value: appointmentDuration,
    setValue: setAppointmentDuration,
    defValue: rangeDefaultDuration,
    step: RANGE_INPUT_STEP,
    minValue: RANGE_INPUT_MIN,
    maxValue: maxDuration,
    hasLabel: true,
    control,
    error: errors.duration,
    rules: {
      required: false,
    },
  }

  const debouncedSearch = debounce(searchApi, 500)
  const getOptions = (
    query: string,
    callBack: (parameter: OptionsType<ISelectOption>) => void
  ) => {
    return debouncedSearch(query, callBack)
  }

  const handleModal = () => {
    const values = getValues()
    Object.assign(values, {
      appointmentTypeId: values.appointmentTypeId
        ? `${values.appointmentTypeId || appointmentTypeId}`
        : '',
    })

    const defVal = {
      clientId: client?.id && `${client?.id}`,
      appointmentTypeId: appointmentTypeId ? `${appointmentTypeId}` : '',
      comment: comment || undefined,
      contactPersonId: contactPerson ? `${contactPerson?.id}` : '',
      duration: rangeDefaultDuration || 1,
      startDate: appointmentId ? undefined : startDate,
    }

    setIsModalOpen(!objEqual(values, defVal))
  }

  useEffect(() => {
    const closingHours =
      appointmentStartDate.value &&
      getClosingHours(appointmentStartDate.value as Date)

    setHoursBeforeClose(dateInterval(appointmentStartDate.value, closingHours))
  }, [appointmentStartDate.value])

  useEffect(() => {
    if (hoursBeforeClose > RANGE_INPUT_MAX) {
      return setMaxDuration(RANGE_INPUT_MAX)
    }

    setMaxDuration(hoursBeforeClose)
  }, [hoursBeforeClose])

  useEffect(() => {
    if (getValues(appointmentFinalDuration.id) > maxDuration) {
      setValue(appointmentFinalDuration.id, maxDuration)
      setAppointmentDuration(maxDuration)
    }
  }, [maxDuration, setValue, appointmentFinalDuration.id, getValues])

  useEffect(() => {
    handleModal()
  }, [appointmentDate, selectedClient, appointmentDetails.data]) // eslint-disable-line

  useEffect(() => {
    if (tiersOptionsLoadingStatus === STATUS.SUCCESS && !clients?.length) {
      setNoTiersOptionsPlaceholder(SELECT_NO_TIER_OPTIONS_PLACEHOLDER)
    }
  }, [tiersOptionsLoadingStatus, clients])

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      key={defaultValues?.id}
      onChange={handleModal}
    >
      <StickyWrapper customClass="px0">
        <Alerts status={status} message={message} />
        <Alerts
          status={appointmentDetails.status}
          message={appointmentDetails.message}
        />
      </StickyWrapper>
      <div className="mb15">
        <DynamicButtons
          optionsArray={appointmentTypes.data}
          register={register({ required: true })}
          error={errors.appointmentTypeId}
          name="appointmentTypeId"
          defaultValue={appointmentTypeId?.toString()}
        />
      </div>
      <div className="row">
        <div className="colMd5 mb15">
          <ClientsSelectSearch
            name="clientId"
            options={options}
            control={control}
            clientId={selectedClient || client?.id}
            handleSelectChange={(selectedValue) => {
              setSelectedClient(selectedValue as any)
            }}
            getOptions={getOptions}
            error={errors.clientId}
            disabled={!clients.length && !client?.id}
            noOptionsPlaceholder={intl.formatMessage({
              id: noTiersOptionsPlaceholder,
            })}
          />
        </div>
        <div className="colMd5 mb15">
          <SelectInput
            name="contactPersonId"
            id="contactPersonId"
            register={register}
            options={contactPersons.data}
            defaultValue={contactPerson?.id?.toString()}
          />
        </div>
        <div className="colMd5 mb15 appointmentPageDateGroup">
          <FormGroupDate
            startDate={appointmentStartDate}
            startDateOnly={true}
          />
        </div>
      </div>
      <div className="row">
        <div className="colMd5 mb15">
          <RangeStepSection range={appointmentFinalDuration} />
        </div>
        <div className="colMd10 mb2">
          <WysiwygSection
            control={control}
            errors={errors}
            defaultValue={comment}
          />
        </div>
      </div>
      <div className="clientFileButtonsWrapper dFlex">
        <DynamicFormattedMessage
          id="form.button.cancel"
          tag={Button}
          className="btn btnOutline linkUnstyled withBackgroundColorDefault px15 py15 cancelAppointmentForm"
          data-qa="cancelNewAppointment"
          onClick={() => {
            handleModal()
            if (prevLocation)
              return history.push(
                prevLocation,
                prevState
                  ? {
                      ...prevState,
                      date: startDate,
                      offset,
                      currentPage,
                    }
                  : {
                      date: startDate,
                      offset,
                      currentPage,
                    }
              )
            if (appointmentId)
              return history.replace(
                generatePath(ROUTES.viewAppointment, {
                  appointmentId,
                })
              )

            history.replace(ROUTES.agenda, { date: appointmentStartDate.value })
          }}
        />
        <DynamicFormattedMessage
          id="form.button.submit"
          tag={Button}
          type="submit"
          className={`btn btnPrimary px15 py15 submitAppointment ${
            status === STATUS.PENDING ? 'btnDisabled' : ''
          }`}
          data-qa="submitNewAppointment"
          disabled={status === STATUS.PENDING}
        />
      </div>
    </form>
  )
}

export default AppointmentForm
