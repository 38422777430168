import {
  ISectionRowData,
  ISectionRowInput,
  IVisitReport,
  IVisitReportForm,
  TMarket,
  TViewVisitReportRow,
  TVisitReportCurrentVersion,
} from '../interfaces/IVisitReports'
import { INPUT_TYPE, MARKET, V3 } from '../enums/common'

// This should be changed and increased at each new visit report form fields update
export const VISIT_REPORT_CURRENT_VERSION: TVisitReportCurrentVersion = V3

export const VISIT_ACTION_TYPE_3 = '3'
export const VISIT_REPORT_DEFAULT = 'default'
export const VISIT_REPORT_PHOTO = 'Photo'
export const VISIT_REPORT_COMMENT = 'Comment'
export const VISIT_REPORT_SUBSCRIBED = 'subscribe'

export const IMAGE_UPLOAD_DEFAULT_ERROR_MESSAGE = 'images'

const visitReportImageInitialState = {
  key: null,
  content: null,
}

export const visitReportInitialState: IVisitReport = {
  id: 0,
  comment: '',
  contactPerson: '',
  contactType: '',
  marche: '',
  date: '',
  duration: null,
  suiviLitige: false,
  suiviInformation: false,
  suiviReassort: false,
  suiviModification: false,
  suiviFacture: false,
  suiviLivraison: false,
  suiviProblemeService: false,
  suiviProblemeCommande: false,
  productNeo: false,
  productNeoPhoto: visitReportImageInitialState,
  productNeoComment: '',
  productVision: false,
  productVisionPhoto: visitReportImageInitialState,
  productVisionComment: '',
  productLibre: false,
  productLibrePhoto: visitReportImageInitialState,
  productLibreComment: '',
  servicesQuizFormation: false,
  servicesQuizNoPersons: 0,
  servicesQuizPhoto: visitReportImageInitialState,
  servicesQuizComment: '',
  servicesCoachFormation: false,
  servicesCoachNoPersons: 0,
  servicesCoachPhoto: visitReportImageInitialState,
  servicesCoachComment: '',
  eventsEchSensor: false,
  eventsGroupPatient: false,
  eventsGroupPatientNoPersons: 0,
  eventsGroupPatientNoDownloads: 0,
  eventsGroupPatientNoSubscribed: 0,
  eventsGroupPatientSigned: false,
  eventsGroupPatientPhoto: visitReportImageInitialState,
  eventsGroupPatientComment: '',
  eventsEasyCollect: false,
  eventsEasyCollectPhoto: visitReportImageInitialState,
  eventsEasyCollectComment: '',
  eventsSsm: false,
  eventsSsmPhoto: visitReportImageInitialState,
  eventsSsmComment: '',
  eventsGroupConvergence: false,
  eventsGroupConvergencePhoto: visitReportImageInitialState,
  eventsGroupConvergenceComment: '',
  selloutReassort: false,
  selloutMerch: false,
  selloutMerchPhoto: visitReportImageInitialState,
  selloutEngagement: false,
  selloutEngagementVisionNo: 0,
  selloutEngagementNeoNo: 0,
  selloutEngagementFslNo: 0,
  selloutEngagementQacNoPersons: 0,
  selloutIdentificationQac: false,
  selloutIdentificationQacNoPersons: 0,
  selloutIdentificationFsl: false,
  selloutIdentificationFslNoPersons: 0,
  selloutFsl: false,
  selloutFslNo: 0,
  selloutFslIdentificationNoPersons: 0,
  docsAvenant: false,
  docsRpConv: false,
  docsRpConvPerson1: '',
  docsRpConvPerson1Func: '',
  docsRpConvPerson2: '',
  docsRpConvPerson2Func: '',
  docsGroupePatient: false,
  docsEasyCollect: false,
  docsEasyCollectPhoto: visitReportImageInitialState,
  docsSsm: false,
  docsBgm: false,
  docsFsl: false,
  docsOther: false,
  docsOtherComment: '',
  docsOtherPhoto: visitReportImageInitialState,
  version: VISIT_REPORT_CURRENT_VERSION,
}

// Visit report fields

// Visit report tab1 fields
export const VISIT_REPORT_RANGE_INPUT_MAX = 3
export const VISIT_REPORT_SELLOUT_INPUT_MAX = 30
export const VISIT_REPORT_INPUT_MIN = 1
export const VISIT_REPORT_INPUT_MAX = 99
export const VISIT_DATE = 'date'
export const VISIT_VISIT_DATE = 'visitDate'
export const VISIT_DURATION = 'duration'
export const VISIT_CONTACT = 'contactPerson'
export const VISIT_ACTION_TYPE = 'contactType'
export const VISIT_ACTION_TYPE_ID = 'contactTypeId'
export const VISIT_MARKET = 'marche'
export const VISIT_CUSTOMER_CARE = 'suivi'
export const VISIT_CUSTOMER_CARE_TYPE_1 = 'suiviLitige'
export const VISIT_CUSTOMER_CARE_TYPE_2 = 'suiviInformation'
export const VISIT_CUSTOMER_CARE_TYPE_3 = 'suiviReassort'
export const VISIT_CUSTOMER_CARE_TYPE_4 = 'suiviModification'
export const VISIT_CUSTOMER_CARE_TYPE_5 = 'suiviFacture'
export const VISIT_CUSTOMER_CARE_TYPE_6 = 'suiviLivraison'
export const VISIT_CUSTOMER_CARE_TYPE_7 = 'suiviProblemeService'
export const VISIT_CUSTOMER_CARE_TYPE_8 = 'suiviProblemeCommande'
export const VISIT_PERSON_ID = 'contactPersonId'
export const VISIT_COMMENT = 'comment'

// Visit report tab2 fields
export const VISIT_PRODUCT = 'product'
export const VISIT_PRODUCT_TYPE_1 = 'productVision'
export const VISIT_PRODUCT_TYPE_1_PHOTO = 'productVisionPhoto'
export const VISIT_PRODUCT_TYPE_1_COMMENT = 'productVisionComment'
export const VISIT_PRODUCT_TYPE_2 = 'productNeo'
export const VISIT_PRODUCT_TYPE_2_PHOTO = 'productNeoPhoto'
export const VISIT_PRODUCT_TYPE_2_COMMENT = 'productNeoComment'
export const VISIT_PRODUCT_TYPE_3 = 'productLibre'
export const VISIT_PRODUCT_TYPE_3_PHOTO = 'productLibrePhoto'
export const VISIT_PRODUCT_TYPE_3_COMMENT = 'productLibreComment'
export const VISIT_SERVICE = 'services'
export const VISIT_SERVICE_TYPE_1 = 'servicesQuizFormation'
export const VISIT_SERVICE_TYPE_1_INPUT = 'servicesQuizNoPersons'
export const VISIT_SERVICE_TYPE_1_PHOTO = 'servicesQuizPhoto'
export const VISIT_SERVICE_TYPE_1_COMMENT = 'servicesQuizComment'
export const VISIT_SERVICE_TYPE_1_SUBSCRIBED = 'subscribeComptoir'
export const VISIT_SERVICE_TYPE_2 = 'servicesCoachFormation'
export const VISIT_SERVICE_TYPE_2_INPUT = 'servicesCoachNoPersons'
export const VISIT_SERVICE_TYPE_2_PHOTO = 'servicesCoachPhoto'
export const VISIT_SERVICE_TYPE_2_COMMENT = 'servicesCoachComment'
export const VISIT_SERVICE_TYPE_2_SUBSCRIBED = 'subscribeCoach'
export const VISIT_EVENTS = 'events'
export const VISIT_EVENTS_TYPE_1 = 'eventsGroupPatient'
export const VISIT_EVENTS_TYPE_1_INPUT = 'eventsGroupPatientNoPersons'
export const VISIT_EVENTS_TYPE_1_PHOTO = 'eventsGroupPatientPhoto'
export const VISIT_EVENTS_TYPE_1_DOWNLOADS = 'eventsGroupPatientNoDownloads'
export const VISIT_EVENTS_TYPE_1_SUBSCRIPTION = 'eventsGroupPatientNoSubscribed'
export const VISIT_EVENTS_TYPE_1_SIGNED = 'eventsGroupPatientSigned'
export const VISIT_EVENTS_TYPE_1_COMMENT = 'eventsGroupPatientComment'
export const VISIT_EVENTS_TYPE_2 = 'eventsEasyCollect'
export const VISIT_EVENTS_TYPE_2_PHOTO = 'eventsEasyCollectPhoto'
export const VISIT_EVENTS_TYPE_2_COMMENT = 'eventsEasyCollectComment'
export const VISIT_EVENTS_TYPE_3 = 'eventsSsm'
export const VISIT_EVENTS_TYPE_3_PHOTO = 'eventsSsmPhoto'
export const VISIT_EVENTS_TYPE_3_COMMENT = 'eventsSsmComment'
export const VISIT_EVENTS_TYPE_4 = 'eventsGroupConvergence'
export const VISIT_EVENTS_TYPE_4_PHOTO = 'eventsGroupConvergencePhoto'
export const VISIT_EVENTS_TYPE_4_COMMENT = 'eventsGroupConvergenceComment'
export const VISIT_EVENTS_TYPE_5 = 'eventsEchSensor'
export const VISIT_FORMATION = 'formation'

// Visit report tab3 fields
export const VISIT_SELLOUT = 'sellout'
export const VISIT_SELLOUT_TYPE_1 = 'selloutReassort'
export const VISIT_SELLOUT_TYPE_2 = 'selloutMerch'
export const VISIT_SELLOUT_TYPE_2_PHOTO = 'selloutMerchPhoto'
export const VISIT_SELLOUT_TYPE_3 = 'selloutEngagement'
export const VISIT_SELLOUT_TYPE_3_INPUT_VISION = 'selloutEngagementVisionNo'
export const VISIT_SELLOUT_TYPE_3_INPUT_NEO = 'selloutEngagementNeoNo'
export const VISIT_SELLOUT_TYPE_3_INPUT_FSL = 'selloutEngagementFslNo'
export const VISIT_SELLOUT_TYPE_3_INPUT_QAC = 'selloutEngagementQacNoPersons'
export const VISIT_SELLOUT_TYPE_4 = 'selloutIdentificationQac'
export const VISIT_SELLOUT_TYPE_4_INPUT = 'selloutIdentificationQacNoPersons'
export const VISIT_SELLOUT_TYPE_5 = 'selloutIdentificationFsl'
export const VISIT_SELLOUT_TYPE_5_INPUT = 'selloutIdentificationFslNoPersons'
export const VISIT_SELLOUT_TYPE_6 = 'selloutFsl'
export const VISIT_SELLOUT_TYPE_6_INPUT_FSL = 'selloutFslNo'
export const VISIT_SELLOUT_TYPE_6_INPUT_FSL_IDENTIFICATION =
  'selloutFslIdentificationNoPersons'

export const VISIT_SELLOUT_TYPE_3_LABEL = 'selloutEngagementNo'

// Visit report tab4 fields
export const VISIT_DOCUMENTS = 'docs'
export const VISIT_DOCUMENTS_TYPE_1 = 'docsAvenant'
export const VISIT_DOCUMENTS_TYPE_2 = 'docsRpConv'
export const VISIT_DOCUMENTS_TYPE_2_PERSON_1 = 'docsRpConvPerson1'
export const VISIT_DOCUMENTS_TYPE_2_PERSON_1_POSITION = 'docsRpConvPerson1Func'
export const VISIT_DOCUMENTS_TYPE_2_PERSON_2 = 'docsRpConvPerson2'
export const VISIT_DOCUMENTS_TYPE_2_PERSON_2_POSITION = 'docsRpConvPerson2Func'
export const VISIT_DOCUMENTS_TYPE_3 = 'docsGroupePatient'
export const VISIT_DOCUMENTS_TYPE_4 = 'docsEasyCollect'
export const VISIT_DOCUMENTS_TYPE_4_PHOTO = 'docsEasyCollectPhoto'
export const VISIT_DOCUMENTS_TYPE_5 = 'docsSsm'
export const VISIT_DOCUMENTS_TYPE_6 = 'docsBgm'
export const VISIT_DOCUMENTS_TYPE_7 = 'docsFsl'
export const VISIT_DOCUMENTS_TYPE_8 = 'docsOther'
export const VISIT_DOCUMENTS_TYPE_8_PHOTO = 'docsOtherPhoto'
export const VISIT_DOCUMENTS_TYPE_8_INPUT = 'docsOtherComment'

export const PHOTO_1 = 'photo1'
export const PHOTO_2 = 'photo2'
export const PHOTO_3 = 'photo3'

// Visit report tab1 data
export const VISIT_REPORT_CUSTOMER_CARE_TYPES: (keyof IVisitReport)[] = [
  VISIT_CUSTOMER_CARE_TYPE_1,
  VISIT_CUSTOMER_CARE_TYPE_2,
  VISIT_CUSTOMER_CARE_TYPE_3,
  VISIT_CUSTOMER_CARE_TYPE_4,
  VISIT_CUSTOMER_CARE_TYPE_5,
  VISIT_CUSTOMER_CARE_TYPE_6,
  VISIT_CUSTOMER_CARE_TYPE_7,
  VISIT_CUSTOMER_CARE_TYPE_8,
]

export const VISIT_MARKET_OPTIONS: TMarket[] = [
  MARKET.TYPE1,
  MARKET.TYPE2,
  MARKET.TYPE3,
]

// Visit report tab2 data
export const VISIT_PRODUCTS_ROWS: ISectionRowData[] = [
  {
    check: VISIT_PRODUCT_TYPE_1,
    imgKey: VISIT_PRODUCT_TYPE_1_PHOTO,
    wysiwygKey: VISIT_PRODUCT_TYPE_1_COMMENT,
  },
  {
    check: VISIT_PRODUCT_TYPE_2,
    imgKey: VISIT_PRODUCT_TYPE_2_PHOTO,
    wysiwygKey: VISIT_PRODUCT_TYPE_2_COMMENT,
  },
  {
    check: VISIT_PRODUCT_TYPE_3,
    imgKey: VISIT_PRODUCT_TYPE_3_PHOTO,
    wysiwygKey: VISIT_PRODUCT_TYPE_3_COMMENT,
  },
]

export const VISIT_SERVICES_ROWS: ISectionRowData[] = [
  {
    check: VISIT_SERVICE_TYPE_1,
    checkLabel: VISIT_FORMATION,
    imgKey: VISIT_SERVICE_TYPE_1_PHOTO,
    input: {
      key: VISIT_SERVICE_TYPE_1_INPUT,
    },
    subscribed: VISIT_SERVICE_TYPE_1_SUBSCRIBED,
    wysiwygKey: VISIT_SERVICE_TYPE_1_COMMENT,
  },
  {
    check: VISIT_SERVICE_TYPE_2,
    checkLabel: VISIT_FORMATION,
    imgKey: VISIT_SERVICE_TYPE_2_PHOTO,
    input: {
      key: VISIT_SERVICE_TYPE_2_INPUT,
    },
    subscribed: VISIT_SERVICE_TYPE_2_SUBSCRIBED,
    wysiwygKey: VISIT_SERVICE_TYPE_2_COMMENT,
  },
]

export const VISIT_EVENTS_ROWS: ISectionRowData[] = [
  {
    check: VISIT_EVENTS_TYPE_5,
  },
  {
    check: VISIT_EVENTS_TYPE_2,
    imgKey: VISIT_EVENTS_TYPE_2_PHOTO,
    wysiwygKey: VISIT_EVENTS_TYPE_2_COMMENT,
  },
  {
    check: VISIT_EVENTS_TYPE_3,
    imgKey: VISIT_EVENTS_TYPE_3_PHOTO,
    wysiwygKey: VISIT_EVENTS_TYPE_3_COMMENT,
  },
  {
    check: VISIT_EVENTS_TYPE_4,
    imgKey: VISIT_EVENTS_TYPE_4_PHOTO,
    wysiwygKey: VISIT_EVENTS_TYPE_4_COMMENT,
  },
]

export const VISIT_EVENTS_TYPE_1_ROW: ISectionRowData = {
  check: VISIT_EVENTS_TYPE_1,
  imgKey: VISIT_EVENTS_TYPE_1_PHOTO,
  wysiwygKey: VISIT_EVENTS_TYPE_1_COMMENT,
}

export const VISIT_EVENTS_TYPE_1_INPUTS: (keyof IVisitReport)[] = [
  VISIT_EVENTS_TYPE_1_INPUT,
  VISIT_EVENTS_TYPE_1_DOWNLOADS,
]

export const VISIT_SECOND_PANEL_FIRST_SECTIONS = [
  { section: VISIT_PRODUCT, rows: VISIT_PRODUCTS_ROWS },
  { section: VISIT_SERVICE, rows: VISIT_SERVICES_ROWS },
]

// Visit report tab3 data
export const VISIT_SELLOUT_ROWS: ISectionRowData[] = [
  {
    check: VISIT_SELLOUT_TYPE_1,
  },
  {
    check: VISIT_SELLOUT_TYPE_2,
    imgKey: VISIT_SELLOUT_TYPE_2_PHOTO,
  },
]

export const VISIT_SELLOUT_TYPE_3_ROW: ISectionRowData = {
  check: VISIT_SELLOUT_TYPE_3,
}

export const VISIT_SELLOUT_TYPE_3_INPUTS: (keyof IVisitReport)[] = [
  VISIT_SELLOUT_TYPE_3_INPUT_VISION,
  VISIT_SELLOUT_TYPE_3_INPUT_NEO,
  VISIT_SELLOUT_TYPE_3_INPUT_QAC,
]

export const VISIT_SELLOUT_TYPE_3_INPUTS_DETAILS: ISectionRowInput[] = [
  {
    input: {
      key: VISIT_SELLOUT_TYPE_3_INPUT_VISION,
      label: VISIT_SELLOUT_TYPE_3_LABEL,
    },
  },
  {
    input: {
      key: VISIT_SELLOUT_TYPE_3_INPUT_NEO,
      label: VISIT_SELLOUT_TYPE_3_LABEL,
    },
  },
  {
    input: {
      key: VISIT_SELLOUT_TYPE_3_INPUT_QAC,
      label: VISIT_SELLOUT_TYPE_3_INPUT_QAC,
      required: true,
    },
  },
]

export const VISIT_SELLOUT_TYPE_6_ROW: ISectionRowData = {
  check: VISIT_SELLOUT_TYPE_6,
}

export const VISIT_SELLOUT_TYPE_6_INPUTS: (keyof IVisitReport)[] = [
  VISIT_SELLOUT_TYPE_6_INPUT_FSL,
  VISIT_SELLOUT_TYPE_6_INPUT_FSL_IDENTIFICATION,
]

export const VISIT_SELLOUT_TYPE_6_INPUTS_DETAILS: ISectionRowInput[] = [
  {
    input: {
      key: VISIT_SELLOUT_TYPE_6_INPUT_FSL,
      label: VISIT_SELLOUT_TYPE_3_INPUT_FSL,
      required: true,
    },
  },
  {
    input: {
      key: VISIT_SELLOUT_TYPE_6_INPUT_FSL_IDENTIFICATION,
      label: VISIT_SELLOUT_TYPE_3_INPUT_QAC,
      required: true,
    },
  },
]

// Visit report tab4 data
export const VISIT_DOCUMENTS_ROWS: ISectionRowData[] = [
  {
    check: VISIT_DOCUMENTS_TYPE_1,
    checkDisabled: true,
  },
  {
    check: VISIT_DOCUMENTS_TYPE_3,
  },
  {
    check: VISIT_DOCUMENTS_TYPE_4,
    imgKey: VISIT_DOCUMENTS_TYPE_4_PHOTO,
  },
  {
    check: VISIT_DOCUMENTS_TYPE_5,
  },
  {
    check: VISIT_DOCUMENTS_TYPE_6,
  },
  {
    check: VISIT_DOCUMENTS_TYPE_7,
  },
  {
    check: VISIT_DOCUMENTS_TYPE_8,
    imgKey: VISIT_DOCUMENTS_TYPE_8_PHOTO,
    input: {
      key: VISIT_DOCUMENTS_TYPE_8_INPUT,
      type: INPUT_TYPE.text,
    },
  },
]

export const VISIT_DOCUMENTS_TYPE_2_ROW: ISectionRowData = {
  check: VISIT_DOCUMENTS_TYPE_2,
}

export const VISIT_DOCUMENTS_TYPE_2_INPUTS_1: (keyof IVisitReport)[] = [
  VISIT_DOCUMENTS_TYPE_2_PERSON_1,
  VISIT_DOCUMENTS_TYPE_2_PERSON_1_POSITION,
]

export const VISIT_DOCUMENTS_TYPE_2_INPUTS_2: (keyof IVisitReport)[] = [
  VISIT_DOCUMENTS_TYPE_2_PERSON_2,
  VISIT_DOCUMENTS_TYPE_2_PERSON_2_POSITION,
]

// Visit report required fields
export const VISIT_REPORT_SECOND_PANEL_VALIDATION = [
  VISIT_PRODUCT_TYPE_1_COMMENT,
  VISIT_PRODUCT_TYPE_2_COMMENT,
  VISIT_PRODUCT_TYPE_3_COMMENT,
  VISIT_SERVICE_TYPE_1_INPUT,
  VISIT_SERVICE_TYPE_1_COMMENT,
  VISIT_SERVICE_TYPE_2_INPUT,
  VISIT_SERVICE_TYPE_2_COMMENT,
  VISIT_EVENTS_TYPE_1_INPUT,
  VISIT_EVENTS_TYPE_1_DOWNLOADS,
  VISIT_EVENTS_TYPE_1_SUBSCRIPTION,
  VISIT_EVENTS_TYPE_1_SIGNED,
  VISIT_EVENTS_TYPE_1_COMMENT,
  VISIT_EVENTS_TYPE_2_COMMENT,
  VISIT_EVENTS_TYPE_3_COMMENT,
  VISIT_EVENTS_TYPE_4_COMMENT,
]

export const VISIT_REPORT_THIRD_PANEL_VALIDATION = [
  VISIT_SELLOUT_TYPE_3_INPUT_NEO,
  VISIT_SELLOUT_TYPE_3_INPUT_VISION,
  VISIT_SELLOUT_TYPE_3_INPUT_QAC,
  VISIT_SELLOUT_TYPE_6_INPUT_FSL,
  VISIT_SELLOUT_TYPE_6_INPUT_FSL_IDENTIFICATION,
]
export const VISIT_REPORT_FORTH_PANEL_VALIDATION = [
  VISIT_DOCUMENTS_TYPE_2_PERSON_1,
  VISIT_DOCUMENTS_TYPE_2_PERSON_1_POSITION,
  VISIT_DOCUMENTS_TYPE_2_PERSON_2,
  VISIT_DOCUMENTS_TYPE_2_PERSON_2_POSITION,
  VISIT_DOCUMENTS_TYPE_8_INPUT,
]

// VisitReport submit fields
export const VISIT_REPORT_IMAGES: (keyof IVisitReport)[] = [
  VISIT_PRODUCT_TYPE_1_PHOTO, // tab2
  VISIT_PRODUCT_TYPE_2_PHOTO,
  VISIT_PRODUCT_TYPE_3_PHOTO,
  VISIT_SERVICE_TYPE_1_PHOTO,
  VISIT_SERVICE_TYPE_2_PHOTO,
  VISIT_EVENTS_TYPE_1_PHOTO,
  VISIT_EVENTS_TYPE_2_PHOTO,
  VISIT_EVENTS_TYPE_3_PHOTO,
  VISIT_EVENTS_TYPE_4_PHOTO,
  VISIT_SELLOUT_TYPE_2_PHOTO, // tab3
  VISIT_DOCUMENTS_TYPE_4_PHOTO, // tab4
  VISIT_DOCUMENTS_TYPE_8_PHOTO,
]
export const VISIT_REPORT_WYSIWYG_INPUTS: (keyof IVisitReport)[] = [
  VISIT_PRODUCT_TYPE_1_COMMENT, // tab2
  VISIT_PRODUCT_TYPE_2_COMMENT,
  VISIT_PRODUCT_TYPE_3_COMMENT,
  VISIT_SERVICE_TYPE_1_COMMENT,
  VISIT_SERVICE_TYPE_2_COMMENT,
  VISIT_EVENTS_TYPE_1_COMMENT,
  VISIT_EVENTS_TYPE_2_COMMENT,
  VISIT_EVENTS_TYPE_3_COMMENT,
  VISIT_EVENTS_TYPE_4_COMMENT,
]

export const VISIT_REPORT_BASIC_INPUTS: (keyof IVisitReport)[] = [
  VISIT_SERVICE_TYPE_1_INPUT, // tab2
  VISIT_SERVICE_TYPE_2_INPUT,
  VISIT_EVENTS_TYPE_1_INPUT,
  VISIT_EVENTS_TYPE_1_DOWNLOADS,
  VISIT_EVENTS_TYPE_1_SUBSCRIPTION,
  ...VISIT_SELLOUT_TYPE_3_INPUTS, // tab3
  ...VISIT_SELLOUT_TYPE_6_INPUTS,
  VISIT_DOCUMENTS_TYPE_2_PERSON_1, // tab4
  VISIT_DOCUMENTS_TYPE_2_PERSON_1_POSITION,
  VISIT_DOCUMENTS_TYPE_2_PERSON_2,
  VISIT_DOCUMENTS_TYPE_2_PERSON_2_POSITION,
  VISIT_DOCUMENTS_TYPE_8_INPUT,
]

export const VISIT_REPORT_INPUTS: (keyof IVisitReport)[] = [
  ...VISIT_REPORT_BASIC_INPUTS,
  ...VISIT_REPORT_WYSIWYG_INPUTS,
]

export const VISIT_REPORT_INT_INPUTS: (keyof IVisitReport)[] = [
  VISIT_SERVICE_TYPE_1_INPUT, // tab2
  VISIT_SERVICE_TYPE_2_INPUT,
  VISIT_EVENTS_TYPE_1_INPUT,
  VISIT_EVENTS_TYPE_1_DOWNLOADS,
  VISIT_EVENTS_TYPE_1_SUBSCRIPTION,
  ...VISIT_SELLOUT_TYPE_3_INPUTS, // tab3
  ...VISIT_SELLOUT_TYPE_6_INPUTS,
]

export const VISIT_REPORT_TEXT_INPUTS: (keyof IVisitReport)[] = [
  VISIT_PRODUCT_TYPE_1_COMMENT, // tab2
  VISIT_PRODUCT_TYPE_2_COMMENT,
  VISIT_PRODUCT_TYPE_3_COMMENT,
  VISIT_SERVICE_TYPE_1_COMMENT,
  VISIT_SERVICE_TYPE_2_COMMENT,
  VISIT_EVENTS_TYPE_1_COMMENT,
  VISIT_EVENTS_TYPE_2_COMMENT,
  VISIT_EVENTS_TYPE_3_COMMENT,
  VISIT_EVENTS_TYPE_4_COMMENT,
  VISIT_DOCUMENTS_TYPE_2_PERSON_1, // tab4
  VISIT_DOCUMENTS_TYPE_2_PERSON_1_POSITION,
  VISIT_DOCUMENTS_TYPE_2_PERSON_2,
  VISIT_DOCUMENTS_TYPE_2_PERSON_2_POSITION,
  VISIT_DOCUMENTS_TYPE_8_INPUT,
]

export const VISIT_REPORT_MAIN_CHECKBOXES: (keyof IVisitReport)[] = [
  VISIT_PRODUCT_TYPE_1, // tab2
  VISIT_PRODUCT_TYPE_2,
  VISIT_PRODUCT_TYPE_3,
  VISIT_SERVICE_TYPE_1,
  VISIT_SERVICE_TYPE_2,
  VISIT_EVENTS_TYPE_1,
  VISIT_EVENTS_TYPE_2,
  VISIT_EVENTS_TYPE_3,
  VISIT_EVENTS_TYPE_4,
  VISIT_EVENTS_TYPE_5,
  VISIT_SELLOUT_TYPE_1, // tab3
  VISIT_SELLOUT_TYPE_2,
  VISIT_SELLOUT_TYPE_3,
  VISIT_SELLOUT_TYPE_6,
  VISIT_DOCUMENTS_TYPE_1, // tab4
  VISIT_DOCUMENTS_TYPE_2,
  VISIT_DOCUMENTS_TYPE_3,
  VISIT_DOCUMENTS_TYPE_4,
  VISIT_DOCUMENTS_TYPE_5,
  VISIT_DOCUMENTS_TYPE_6,
  VISIT_DOCUMENTS_TYPE_7,
  VISIT_DOCUMENTS_TYPE_8,
]

export const VISIT_REPORT_CHECKBOXES: (keyof IVisitReport)[] = [
  ...VISIT_REPORT_MAIN_CHECKBOXES,
  VISIT_EVENTS_TYPE_1_SIGNED,
]

// VisitReport form reset fields
export const VISIT_REPORT_RESET_FALSE: (keyof IVisitReportForm)[] = [
  ...(VISIT_REPORT_CUSTOMER_CARE_TYPES as (keyof IVisitReportForm)[]),
  ...(VISIT_REPORT_CHECKBOXES as (keyof IVisitReportForm)[]),
]

export const VISIT_REPORT_RESET_NULL: (keyof IVisitReportForm)[] = [
  VISIT_DURATION,
  VISIT_ACTION_TYPE_ID,
]

export const VISIT_REPORT_RESET_EMPTY: (keyof IVisitReportForm)[] = [
  VISIT_VISIT_DATE,
  VISIT_COMMENT,
  ...(VISIT_REPORT_IMAGES as (keyof IVisitReportForm)[]),
  ...(VISIT_REPORT_INPUTS as (keyof IVisitReportForm)[]),
]

export const VISIT_REPORT_RESET_EMPTY_ARRAY: (keyof IVisitReportForm)[] = [
  VISIT_MARKET,
]

// View visit report data Version 1
// View tab1
export const VIEW_VISIT_REPORT_FIRST_PANEL_LIST_V1: (keyof IVisitReport)[] = [
  VISIT_DATE,
  VISIT_DURATION,
  VISIT_CONTACT,
  VISIT_ACTION_TYPE,
  VISIT_MARKET,
]

// View tab2
export const VIEW_VISIT_REPORT_PRODUCTS_ROWS_V1: (keyof IVisitReport)[][] = [
  [
    VISIT_PRODUCT_TYPE_1,
    VISIT_PRODUCT_TYPE_1_PHOTO,
    VISIT_PRODUCT_TYPE_1_COMMENT,
  ],
  [
    VISIT_PRODUCT_TYPE_2,
    VISIT_PRODUCT_TYPE_2_PHOTO,
    VISIT_PRODUCT_TYPE_2_COMMENT,
  ],
  [
    VISIT_PRODUCT_TYPE_3,
    VISIT_PRODUCT_TYPE_3_PHOTO,
    VISIT_PRODUCT_TYPE_3_COMMENT,
  ],
]

export const VIEW_VISIT_REPORT_SERVICES_ROWS_V1: (keyof TViewVisitReportRow)[][] = [
  [
    VISIT_SERVICE_TYPE_1,
    VISIT_SERVICE_TYPE_1_SUBSCRIBED,
    VISIT_SERVICE_TYPE_1_INPUT,
    VISIT_SERVICE_TYPE_1_PHOTO,
    VISIT_SERVICE_TYPE_1_COMMENT,
  ],
  [
    VISIT_SERVICE_TYPE_2,
    VISIT_SERVICE_TYPE_2_SUBSCRIBED,
    VISIT_SERVICE_TYPE_2_INPUT,
    VISIT_SERVICE_TYPE_2_PHOTO,
    VISIT_SERVICE_TYPE_2_COMMENT,
  ],
]

export const VIEW_VISIT_REPORT_EVENTS_ROWS_V1: (keyof IVisitReport)[][] = [
  [VISIT_EVENTS_TYPE_5],
  [
    VISIT_EVENTS_TYPE_1,
    VISIT_EVENTS_TYPE_1_INPUT,
    VISIT_EVENTS_TYPE_1_DOWNLOADS,
    VISIT_EVENTS_TYPE_1_SUBSCRIPTION,
    VISIT_EVENTS_TYPE_1_SIGNED,
    VISIT_EVENTS_TYPE_1_PHOTO,
    VISIT_EVENTS_TYPE_1_COMMENT,
  ],
  [VISIT_EVENTS_TYPE_2, VISIT_EVENTS_TYPE_2_PHOTO, VISIT_EVENTS_TYPE_2_COMMENT],
  [VISIT_EVENTS_TYPE_3, VISIT_EVENTS_TYPE_3_PHOTO, VISIT_EVENTS_TYPE_3_COMMENT],
  [VISIT_EVENTS_TYPE_4, VISIT_EVENTS_TYPE_4_PHOTO, VISIT_EVENTS_TYPE_4_COMMENT],
]

// View tab3
export const VIEW_VISIT_REPORT_SELLOUT_ROWS_V1: (keyof IVisitReport)[][] = [
  [VISIT_SELLOUT_TYPE_1],
  [VISIT_SELLOUT_TYPE_2, VISIT_SELLOUT_TYPE_2_PHOTO],
  [
    VISIT_SELLOUT_TYPE_3,
    VISIT_SELLOUT_TYPE_3_INPUT_VISION,
    VISIT_SELLOUT_TYPE_3_INPUT_NEO,
    VISIT_SELLOUT_TYPE_3_INPUT_FSL,
  ],
  [VISIT_SELLOUT_TYPE_4, VISIT_SELLOUT_TYPE_4_INPUT],
  [VISIT_SELLOUT_TYPE_5, VISIT_SELLOUT_TYPE_5_INPUT],
]

// View tab4
export const VIEW_VISIT_REPORT_DOCUMENTS_ROWS_V1: (keyof IVisitReport)[][] = [
  [VISIT_DOCUMENTS_TYPE_1],
  [
    VISIT_DOCUMENTS_TYPE_2,
    VISIT_DOCUMENTS_TYPE_2_PERSON_1,
    VISIT_DOCUMENTS_TYPE_2_PERSON_1_POSITION,
    VISIT_DOCUMENTS_TYPE_2_PERSON_2,
    VISIT_DOCUMENTS_TYPE_2_PERSON_2_POSITION,
  ],
  [VISIT_DOCUMENTS_TYPE_3],
  [VISIT_DOCUMENTS_TYPE_4, VISIT_DOCUMENTS_TYPE_4_PHOTO],
  [VISIT_DOCUMENTS_TYPE_5],
  [VISIT_DOCUMENTS_TYPE_6],
  [VISIT_DOCUMENTS_TYPE_7],
  [
    VISIT_DOCUMENTS_TYPE_8,
    VISIT_DOCUMENTS_TYPE_8_INPUT,
    VISIT_DOCUMENTS_TYPE_8_PHOTO,
  ],
]

// View visit report data Version 2
// View tab1
export const VIEW_VISIT_REPORT_FIRST_PANEL_LIST_V2: (keyof IVisitReport)[] = [
  VISIT_DATE,
  VISIT_DURATION,
  VISIT_CONTACT,
  VISIT_ACTION_TYPE,
  VISIT_MARKET,
]

// View tab2
export const VIEW_VISIT_REPORT_PRODUCTS_ROWS_V2: (keyof IVisitReport)[][] = [
  [
    VISIT_PRODUCT_TYPE_1,
    VISIT_PRODUCT_TYPE_1_PHOTO,
    VISIT_PRODUCT_TYPE_1_COMMENT,
  ],
  [
    VISIT_PRODUCT_TYPE_2,
    VISIT_PRODUCT_TYPE_2_PHOTO,
    VISIT_PRODUCT_TYPE_2_COMMENT,
  ],
  [
    VISIT_PRODUCT_TYPE_3,
    VISIT_PRODUCT_TYPE_3_PHOTO,
    VISIT_PRODUCT_TYPE_3_COMMENT,
  ],
]

export const VIEW_VISIT_REPORT_SERVICES_ROWS_V2: (keyof TViewVisitReportRow)[][] = [
  [
    VISIT_SERVICE_TYPE_1,
    VISIT_SERVICE_TYPE_1_SUBSCRIBED,
    VISIT_SERVICE_TYPE_1_INPUT,
    VISIT_SERVICE_TYPE_1_PHOTO,
    VISIT_SERVICE_TYPE_1_COMMENT,
  ],
  [
    VISIT_SERVICE_TYPE_2,
    VISIT_SERVICE_TYPE_2_SUBSCRIBED,
    VISIT_SERVICE_TYPE_2_INPUT,
    VISIT_SERVICE_TYPE_2_PHOTO,
    VISIT_SERVICE_TYPE_2_COMMENT,
  ],
]

export const VIEW_VISIT_REPORT_EVENTS_ROWS_V2: (keyof IVisitReport)[][] = [
  [VISIT_EVENTS_TYPE_5],
  [
    VISIT_EVENTS_TYPE_1,
    VISIT_EVENTS_TYPE_1_INPUT,
    VISIT_EVENTS_TYPE_1_DOWNLOADS,
    VISIT_EVENTS_TYPE_1_SUBSCRIPTION,
    VISIT_EVENTS_TYPE_1_SIGNED,
    VISIT_EVENTS_TYPE_1_PHOTO,
    VISIT_EVENTS_TYPE_1_COMMENT,
  ],
  [VISIT_EVENTS_TYPE_2, VISIT_EVENTS_TYPE_2_PHOTO, VISIT_EVENTS_TYPE_2_COMMENT],
  [VISIT_EVENTS_TYPE_3, VISIT_EVENTS_TYPE_3_PHOTO, VISIT_EVENTS_TYPE_3_COMMENT],
  [VISIT_EVENTS_TYPE_4, VISIT_EVENTS_TYPE_4_PHOTO, VISIT_EVENTS_TYPE_4_COMMENT],
]

// View tab3
export const VIEW_VISIT_REPORT_SELLOUT_ROWS_V2: (keyof IVisitReport)[][] = [
  [VISIT_SELLOUT_TYPE_1],
  [VISIT_SELLOUT_TYPE_2, VISIT_SELLOUT_TYPE_2_PHOTO],
  [
    VISIT_SELLOUT_TYPE_3,
    VISIT_SELLOUT_TYPE_3_INPUT_VISION,
    VISIT_SELLOUT_TYPE_3_INPUT_NEO,
    VISIT_SELLOUT_TYPE_3_INPUT_QAC,
  ],
  [
    VISIT_SELLOUT_TYPE_6,
    VISIT_SELLOUT_TYPE_6_INPUT_FSL,
    VISIT_SELLOUT_TYPE_6_INPUT_FSL_IDENTIFICATION,
  ],
]

// View tab4
export const VIEW_VISIT_REPORT_DOCUMENTS_ROWS_V2: (keyof IVisitReport)[][] = [
  [VISIT_DOCUMENTS_TYPE_1],
  [
    VISIT_DOCUMENTS_TYPE_2,
    VISIT_DOCUMENTS_TYPE_2_PERSON_1,
    VISIT_DOCUMENTS_TYPE_2_PERSON_1_POSITION,
    VISIT_DOCUMENTS_TYPE_2_PERSON_2,
    VISIT_DOCUMENTS_TYPE_2_PERSON_2_POSITION,
  ],
  [VISIT_DOCUMENTS_TYPE_3],
  [VISIT_DOCUMENTS_TYPE_4, VISIT_DOCUMENTS_TYPE_4_PHOTO],
  [VISIT_DOCUMENTS_TYPE_5],
  [VISIT_DOCUMENTS_TYPE_6],
  [VISIT_DOCUMENTS_TYPE_7],
  [
    VISIT_DOCUMENTS_TYPE_8,
    VISIT_DOCUMENTS_TYPE_8_INPUT,
    VISIT_DOCUMENTS_TYPE_8_PHOTO,
  ],
]

export const EVENT_SECTION_APPOINTMENTS_COUNT = 1
