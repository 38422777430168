import { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { getUserActivity } from '../../services/usersService'
import { useLoadData } from '../UseLoadData'
import { getUserAppoinments } from '../../services/appointmentsService'
import { STATUS } from '../../enums/common'
import { useUsersDashboardHook } from './UseUsersDashboardHook'
import { getContactTypes } from '../../services/visitReportsService'
import { getContactTypesFieldName } from '../../utils/dashboard'

export const useDashboardHook = () => {
  const [shouldRefetchUserActivity, setShouldRefetchUserActivity] = useState(
    true
  )

  const {
    userId,
    users,
    statusPending,
    onUserChange,
    isUsersLoadingError,
    isUserNotAvailableError,
  } = useUsersDashboardHook()

  const form = useForm({ mode: 'onChange' })

  const appointments = useLoadData(
    () => {
      if (!statusPending) return getUserAppoinments(+userId)
    },
    {
      dependencies: [userId, statusPending],
    }
  )

  const contactTypes = useLoadData(() => getContactTypes(), {
    dependencies: [],
  })

  const contactTypesPending = useMemo(
    () => contactTypes.status === STATUS.PENDING,
    [contactTypes.status]
  )

  const queryParams = useMemo(() => {
    const params = form.getValues()
    const query: string[] = []
    contactTypes?.data.map(({ value, label }) => {
      if (params[getContactTypesFieldName(label)]) {
        query.push(`contactTypeIds[]=${value}`)
      }
    })

    return query.join('&')
  }, [form, contactTypes])

  const userActivity = useLoadData(
    () => {
      if (shouldRefetchUserActivity && !statusPending && !contactTypesPending)
        return getUserActivity(+userId, queryParams)
    },
    {
      dependencies: [
        userId,
        statusPending,
        contactTypesPending,
        shouldRefetchUserActivity.toString(),
        queryParams,
      ],
    }
  )

  const dataStatusPending = useMemo(
    () =>
      (appointments.status || userActivity.status || users.status) ===
      STATUS.PENDING,
    [appointments, userActivity, users]
  )

  return {
    users,
    appointments,
    contactTypes,
    userActivity,
    onUserChange,
    dataStatusPending,
    isUsersLoadingError,
    isUserNotAvailableError,
    form,
    setShouldRefetchUserActivity,
  }
}
