import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import set from 'lodash.set'
import { useLoadData } from '../UseLoadData'
import { clientContractsActions } from '../../store/reducers/clientContractsReducer'
import {
  getContractForm,
  getContracts,
  getContractHistoryData,
  getDestructionCertificates,
} from '../../services/contractsServices'
import { IContract } from '../../interfaces/IContracts'
import { statusNetworkSelector } from '../../store/selectors'
import { getClientContractsData } from '../../store/selectors/clientContractsSelectors'
import {
  CONTRACTS_MONTH_INDEX,
  MAX_ENGAGEMENTS,
  STATUS,
} from '../../enums/common'
import { getCurrentYear } from '../../utils/helpers'
import { DATA_INITIAL_STATE } from '../../constants/api'
import { MIXED_FORM_TYPE_DESTRUCTION_CERTIFICATE_PATH } from '../../constants/mixedForms'

export const useInitiateContracts = (clientId: string) => {
  const dispatch = useDispatch()
  const dependencies = useMemo(() => [clientId], [clientId])
  const { hasNetwork } = useSelector(
    statusNetworkSelector.getStatusNetworkValue
  )

  const [allContractsDataResponse, setAllContractsDataResponse] = useState(
    DATA_INITIAL_STATE
  )

  const { status: destructionCertificatesStatus } = useLoadData(
    () => getDestructionCertificates(parseInt(clientId, 10)),
    {
      fetchFromRedux: !hasNetwork,
      reduxSelector: getClientContractsData,
      storeInRedux: hasNetwork,
      reduxAction: clientContractsActions.setClientDestructionCertificates,
      reduxStorePath: `${clientId}.${MIXED_FORM_TYPE_DESTRUCTION_CERTIFICATE_PATH}`,
    }
  )

  const { data: historyData, status: historyDataStatus } = useLoadData(
    () => getContractHistoryData(parseInt(clientId, 10)),
    {
      dependencies,
      fetchFromRedux: !hasNetwork,
      reduxSelector: getClientContractsData,
      storeInRedux: hasNetwork,
      reduxAction: clientContractsActions.setContractHistoryData,
      reduxStorePath: `${clientId}.contractHistoryData`,
    }
  )

  const { currentYear } = historyData

  useEffect(() => {
    if (!currentYear || !hasNetwork) return

    const { listContractTypes: contractTypes } = currentYear

    if (!contractTypes?.length) return

    const fetchContracts = async (contractTypeId: number) => {
      await getContracts(
        contractTypeId,
        getCurrentYear(CONTRACTS_MONTH_INDEX),
        MAX_ENGAGEMENTS,
        parseInt(clientId, 10)
      ).then((data) => {
        const payload = set({}, `${clientId}.contracts.${contractTypeId}`, data)
        dispatch(clientContractsActions.setClientContracts(payload))
      })
    }
    const fetchContractsForms = async (contractTypeId: number) => {
      await getContractForm(contractTypeId, parseInt(clientId, 10)).then(
        (data) => {
          const payload = set({}, `${clientId}.forms.${contractTypeId}`, data)
          dispatch(clientContractsActions.setClientContractsForms(payload))
        }
      )
    }
    const fetchAllContractsData = async () => {
      setAllContractsDataResponse({
        ...allContractsDataResponse,
        status: STATUS.PENDING,
      })
      await Promise.all(
        contractTypes.map(({ id }: IContract) =>
          Promise.all([fetchContracts(id), fetchContractsForms(id)])
        )
      )
    }

    fetchAllContractsData()
      .then(() => {
        setAllContractsDataResponse({
          ...allContractsDataResponse,
          status: STATUS.SUCCESS,
        })
      })
      .catch(() => {
        setAllContractsDataResponse({
          ...allContractsDataResponse,
          status: STATUS.DANGER,
        })
      })

    // eslint-disable-next-line
  }, [currentYear, clientId, dispatch])

  const isContractsDataStatusPending = useMemo(
    () =>
      historyDataStatus === STATUS.PENDING ||
      allContractsDataResponse.status === STATUS.PENDING ||
      destructionCertificatesStatus === STATUS.PENDING,
    [
      historyDataStatus,
      allContractsDataResponse.status,
      destructionCertificatesStatus,
    ]
  )

  return {
    isContractsDataStatusPending,
  }
}
