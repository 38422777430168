import {
  differenceInMinutes,
  differenceInBusinessDays,
  startOfMonth,
  startOfDay,
  endOfDay,
} from 'date-fns'
import { format, utcToZonedTime } from 'date-fns-tz'
import DOMPurify from 'dompurify'
import { Dispatch, SetStateAction } from 'react'
import {
  CODE_1011,
  CONTRACT_FILE_DOWNLOAD_TIME_FORMAT,
  CURRENT_DAY_DATETIME_FORMAT,
  DANGER,
  DATE_REQUEST_FORMAT,
  EXTERNAL_STORAGE_UPLOAD_FAILED_MESSAGE,
  FILE_EXTENSION_PDF,
  NO_DATE,
  RANGE_INPUT_MINUTES,
  REPORT_DATETIME_FORMAT,
  STATUS_BAD_GATEWAY,
  THIRTY_MIN,
  TODAY_APPOINTMENT_TIME_FORMAT,
  WARNING,
} from '../enums/common'
import {
  IControlDownload,
  IFileDetails,
  TDynamicButton,
} from '../interfaces/ICommonComponents'
import { IArrayOptions } from '../interfaces/IAppointments'
import { CONTENT_TYPE_APP_PDF } from '../constants/api'
import { IUser } from '../interfaces/IUsers'
import { APP_MONTH_INDEX } from '../constants/generalData'

export const formatDate = (
  date: string | Date | null,
  dateFormat: string = CURRENT_DAY_DATETIME_FORMAT,
  replacement: string = NO_DATE,
  isHistoryPage?: boolean
) => {
  if (typeof date === 'string') {
    return date ? format(utcToZonedTime(date, 'UTC'), dateFormat) : replacement
  }

  if (isHistoryPage) {
    return date
      ? `<span class="fontBold">${format(
          date,
          REPORT_DATETIME_FORMAT
        )}</span> | ${format(date, TODAY_APPOINTMENT_TIME_FORMAT)}`
      : replacement
  }

  return date ? format(date, dateFormat) : replacement
}

export const toNumber = (value: string) => parseInt(value, 10)

export const roundTwoDecimals = (number: number) =>
  Math.round(number * 100) / 100

export const objEqual = (x: any, y: any): boolean => {
  const ok = Object.keys
  const tx = typeof x
  const ty = typeof y
  return x && y && tx === 'object' && tx === ty
    ? ok(x).length === ok(y).length &&
        ok(x).every((key) => objEqual(x[key], y[key]))
    : x === y
}

export const getFormattedString = (
  param: string | undefined,
  joinSeparator: string = '',
  splitSeparator: string = ' '
) => {
  if (!param) return ''

  return param
    ?.normalize('NFD')
    .replace(/[\u0300-\u036f|&;$%@"<>()+,.]/g, '')
    .toLowerCase()
    .split(splitSeparator)
    .reduce((acc, el) => {
      acc.push(capitaliseString(el))
      return acc
    }, [] as string[])
    .join(joinSeparator)
}

export const filterPassedTime = (createdDate: Date) => {
  return new Date().getTime() - createdDate.getTime() > THIRTY_MIN
}

export const handleFileDownload = (url: string, fileName: string) => {
  const element = document.createElement('a')
  element.setAttribute('href', url)
  element.setAttribute('download', fileName)
  element.setAttribute('target', '_blank')

  element.style.display = 'none'
  document.body.appendChild(element)

  element.click()
}

export const isEmptyOrSpaces = (str: string | null) => {
  return str === null || str.match(/^ *$/) !== null
}

// use to handle html content with react dangerouslySetInnerHTML
export const handleHtmlContent = (content: string) => {
  return { __html: DOMPurify.sanitize(content) }
}

export const defaultDate = (date: string) =>
  date ? new Date(date) : new Date()

export const dateInterval = (
  startDate: string | Date | null,
  endDate: string | Date | null
): number =>
  (startDate as Date) &&
  (endDate as Date) &&
  differenceInMinutes(endDate as Date, startDate as Date) / RANGE_INPUT_MINUTES

export const getEndDate = (startDate: Date, interval: number) =>
  startDate && new Date(startDate.getTime() + interval * (1000 * 60 * 60))

export const getClosingHours = (startDate: Date) => {
  const closingHours = (startDate as Date) && new Date(startDate)
  closingHours.setHours(21)
  closingHours.setMinutes(0)

  return closingHours
}

export const timeConvert = (number: number) => {
  const hours: number = Math.floor(number)
  const minutes: number = Math.round(number * 60) % 60
  const displayedHours = hours ? `${hours}h` : ''
  const displayedMinutes = minutes ? `${minutes}min` : ''

  return `${displayedHours}${displayedMinutes}`
}

export const joinData = (
  data: (string | undefined | null)[],
  separator?: string
) => {
  return data.filter((el) => !!el).join(`${separator || ','} `)
}

export const capitaliseString = (string: string) =>
  string[0].toUpperCase() + string.slice(1)

export const getAmountFromTotal = (total: number, value: number) =>
  Math.round((value * 100) / total)

export const isEmptyObj = (object: object) =>
  Object.keys(object).length === 0 && object.constructor === Object

export const isObjectEmpty = (obj: object) =>
  Object.getPrototypeOf(obj) === Object.prototype &&
  Object.keys(obj).length === 0

export const getNWord = (string: string, index?: number) => {
  const getCamelCaseWords = string
    .replace(/([a-z](?=[A-Z]))/g, '$1 ')
    .split(' ')
  return getCamelCaseWords[index || getCamelCaseWords.length - 1]
}

export const range = (start: number, end: number): Array<number> => {
  return Array(end - start + 1)
    .fill(0)
    .map((_, idx) => start + idx)
}

export const getWorkingDays = () => {
  const date = new Date()
  const firstDayOfNextMonth = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    1
  )
  const firstDay = startOfMonth(date)
  const lastDay = startOfMonth(firstDayOfNextMonth)

  return differenceInBusinessDays(lastDay, firstDay)
}

export const timezoneOffset = () => {
  return String(-new Date().getTimezoneOffset() / 60)
}

export const isDateInTheFuture = (date: string) =>
  new Date(date as string).getTime() > new Date().getTime()

export const getAlertType = (messageCode: string = '') =>
  messageCode === EXTERNAL_STORAGE_UPLOAD_FAILED_MESSAGE ? WARNING : DANGER

export const isExternalStorageUploadFailed = (
  status: number,
  code: number
): boolean => status === +STATUS_BAD_GATEWAY && code === +CODE_1011

export const getSimpleRoute = (routeName: string, query?: string) =>
  `${routeName}${query ? `?${query}` : ''}`

export const getRoute = (routeName: string, query?: string) =>
  `/${routeName}${query ? `?${query}` : ''}`

export const getCurrentYear = (monthIndex: number = APP_MONTH_INDEX) => {
  const date = new Date()

  return date.getMonth() < monthIndex
    ? date.getFullYear()
    : date.getFullYear() + 1
}

export const getNextCalendarYear = () => {
  const date = new Date()

  return date.getFullYear() + 1
}

export const getFirstDayOfCurrentYear = () => {
  const initialStartDate = new Date()
  initialStartDate.setMonth(0)
  initialStartDate.setDate(1)

  return initialStartDate
}

export const getStartOfDay = (date: string) =>
  format(startOfDay(new Date(date)), DATE_REQUEST_FORMAT)

export const getEndOfDay = (date: string) =>
  format(endOfDay(new Date(date)), DATE_REQUEST_FORMAT)

export const getFullName = (
  user: Partial<IUser>,
  toUppercase: boolean = false
) =>
  toUppercase
    ? `${user.lastName?.toUpperCase()} ${user.firstName}`
    : `${user.lastName} ${user.firstName}`

export const getArraySequence = (n: number) =>
  [...Array(n)].map((_, index) => index + 1)

export const currentYearValue = new Date().getFullYear()

export const isNumberFloat = (value: string) =>
  value && parseInt(value, 10) && parseInt(value, 10) !== +value

export const isNumberOutOfRange = (value: string, min: number, max: number) =>
  +value > max || (value && +value < min)

export const isFloatOrOutOfRange = (value: string, min: number, max: number) =>
  isNumberOutOfRange(value, min, max) || isNumberFloat(value)

export const getDynamicButtonDefaultValue = (
  field: TDynamicButton | undefined
) => (field?.length ? field[0] : '')

export const matchOptionsValue = <T>(
  options: IArrayOptions[],
  optionToMatch: T
) =>
  options?.length
    ? options.find((option) => option.value === `${optionToMatch}`)
    : undefined

export const getFileForDownload = (
  responseBody: Blob,
  fileDetails: IFileDetails,
  setControlDownload: Dispatch<SetStateAction<IControlDownload>>
) => {
  const { creationDate, cipCode, sectorCode, contractType } = fileDetails

  const file = new Blob([responseBody], {
    type: CONTENT_TYPE_APP_PDF,
  })
  const fileURL = URL.createObjectURL(file)

  const fileDate = formatDate(creationDate, CONTRACT_FILE_DOWNLOAD_TIME_FORMAT)
  const fileContractType = slugifyText(contractType)

  const fileName =
    joinData([fileContractType, fileDate, sectorCode, cipCode], '-').replace(
      /\s/g,
      ''
    ) + FILE_EXTENSION_PDF

  setControlDownload({
    isDownloadModalOpen: true,
    url: fileURL,
    fileName,
  })
}

export const getFileExtension = (extension: string) =>
  extension.substring(1).toUpperCase()

export const slugifyText = (text: string) => text.replace(/\s+/g, '_')
