import { useEffect } from 'react'
import { ICustomGeolocation } from '../../interfaces/IFilters'
import {
  getGeolocationCurrentPosition,
  setGeolocationToLocalStorage,
} from '../../utils/filters'

/**
 * Hook used to get geolocation and to set button disable status.
 */
export const useGeolocation = ({
  isFocused,
  setIsRayonGeolocationDisabled,
}: ICustomGeolocation) => {
  useEffect(() => {
    if (!isFocused) {
      return
    }

    const updateCoordinates = (data: Position) => {
      setGeolocationToLocalStorage(data)

      if (data.coords.latitude && data.coords.longitude) {
        setIsRayonGeolocationDisabled(false)
      }
    }

    const errorCallback = () => {
      setIsRayonGeolocationDisabled(true)
    }

    getGeolocationCurrentPosition(updateCoordinates, errorCallback)
  }, [isFocused, setIsRayonGeolocationDisabled])
}
