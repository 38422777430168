import React, { FC, useMemo } from 'react'
import { Link } from 'react-router-dom'
import Button from './common/ui/Button'
import DynamicFormattedMessage from './common/ui/DynamicFormattedMessage'
import { iconLocation } from '../assets/images/images'
import useSimpleSearchFormHook from '../hooks/UseSimpleSearchFormHook'
import TextInput from './form/TextInput'
import { TPageFiltersSection } from '../interfaces/IFilters'
import { ROUTES } from '../enums/routes'
import { FIELD_SEARCH } from '../constants/form'
import { ActiveFiltersSection } from './pageFilters/ActiveFiltersSection'
import { ADVANCED_SEARCH_SELECT_INPUT_FIELDS } from '../constants/filters'
import { isFiltersSet } from '../utils/filters'

export const PageFiltersSection: FC<
  TPageFiltersSection<typeof ADVANCED_SEARCH_SELECT_INPUT_FIELDS>
> = ({
  setFilters,
  changePage,
  toggleModal,
  isModalOpen,
  filters,
  fieldsData,
  routes,
  resetRoute,
}) => {
  const { register, errors } = useSimpleSearchFormHook({
    setFilters,
    filters,
    changePage,
    fieldToWatch: FIELD_SEARCH,
    isModalOpen,
  })

  const showFilters = useMemo(() => (filters ? isFiltersSet(filters) : false), [
    filters,
  ])

  return (
    <section className="pageFiltersSection mb2 row alignItemsStart">
      <div className="pageFiltersSimpleSearch col12 colXl7 colMd6">
        <form>
          <TextInput
            name={FIELD_SEARCH}
            id={FIELD_SEARCH}
            register={register({ maxLength: 120 })}
            error={errors.name}
            placeholder="searchClient"
            data-qa="inputName"
            inputClass="simpleSearchName"
          />
        </form>
      </div>
      <div className="pageFiltersAdvancedCtaWrapper col12 colXl5 colMd6 pl0md dFlex alignItemsCenter justifyContentBetween">
        <Button
          className="btn btnPrimary py15"
          data-qa="ctaAdvancedSearch"
          onClick={toggleModal}
        >
          <DynamicFormattedMessage id="cta.advancedSearch.label" />
        </Button>
        <Link
          to={routes?.map ?? ROUTES.map}
          className="link linkBasic fontMedium textCenter"
          data-qa="ctaSeeMap"
        >
          <img src={iconLocation} alt="location icon" className="mr05" />
          <DynamicFormattedMessage id="cta.seeMap.label" />
        </Link>
      </div>
      {filters && showFilters && (
        <ActiveFiltersSection
          setFilters={setFilters}
          changePage={changePage}
          filters={filters}
          fieldsData={fieldsData}
          resetRoute={resetRoute}
        />
      )}
    </section>
  )
}
