import React, { FC, useMemo } from 'react'
import BarChart from '../charts/BarChart'
import { IChartSection } from '../../../interfaces/IDashboard'
import { ChartTitle } from '../common/ChartTitle'
import { ChartPlaceholder } from '../common/ChartPlaceholder'
import Alerts from '../../alerts/Alerts'
import { isEmptyObj } from '../../../utils/helpers'
import { STATUS } from '../../../enums/common'

const ChartSection: FC<IChartSection> = ({
  title,
  className,
  chartData,
  formState,
}) => {
  const shouldChartRender = useMemo(
    () => !isEmptyObj(chartData) && formState.status === STATUS.SUCCESS,
    [formState.status, chartData]
  )

  return (
    <div className={`chartContainerCa ${className}`}>
      {shouldChartRender ? (
        <>
          <ChartTitle
            title={title ?? ''}
            customClass="chartTitle textSecondary py1"
          />
          <div className="dBlock chartContainerCa">
            <BarChart title={title ?? ''} chartData={chartData} />
          </div>
        </>
      ) : (
        <>
          <ChartTitle
            title={title ?? ''}
            customClass="chartTitle textSecondary py1"
          />
          <ChartPlaceholder />
        </>
      )}
      <Alerts status={formState.status} message={formState.message} />
    </div>
  )
}

export default ChartSection
