import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { TApiResponse } from '../../interfaces/IClientDetails'
import { STATUS } from '../../enums/common'
import { getClientVisitReport } from '../../services/visitReportsService'
import { visitReportSelectors } from '../../store/selectors'
import { visitReportActions } from '../../store/reducers/visitReportReducer'
import { IVisitReportData } from '../../interfaces/IVisitReports'
import { handleError } from '../../utils/api'

/**
 * Used to fetch the client's last visit report.
 */

export const useClientVisitReport: (reportId: string) => IVisitReportData = (
  reportId
) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const [responseState, setResponseState] = useState<TApiResponse>({
    status: STATUS.IDLE,
    message: '',
    messageCode: '',
  })

  const { status, message } = responseState
  const visitReportData = useSelector(visitReportSelectors.getVisitReportvalue)
  const { id } = visitReportData

  useEffect(() => {
    const getVisitReport = async () => {
      setResponseState({ status: STATUS.PENDING, message: '' })
      try {
        const { body } = await getClientVisitReport(reportId)
        setResponseState({ status: STATUS.SUCCESS, message: '' })
        dispatch(visitReportActions.setVisitReport(body))
      } catch (e) {
        const handledError = handleError(e, intl)
        setResponseState(handledError)
      }
    }

    if (parseInt(reportId, 10) !== id) {
      getVisitReport()
    } else {
      setResponseState({ status: STATUS.SUCCESS, message: '' })
    }
  }, [reportId, dispatch, id, intl])
  return { status, message, visitReportData }
}
