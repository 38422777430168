import React, { FC, useMemo } from 'react'
import { IContractDetailsFieldData } from '../../../interfaces/IContracts'
import SelectInput from '../../form/SelectInput'
import { prepareRangeOptions } from '../../../utils/contracts'
import { range } from '../../../utils/helpers'

export const RangeInputField: FC<IContractDetailsFieldData> = ({
  field,
  register,
  errors,
}) => {
  const { id, name, mandatory, valueStart, valueEnd, label } = field
  const options = useMemo(
    () => prepareRangeOptions(range(valueStart!, valueEnd!)),
    [valueStart, valueEnd]
  )

  return (
    <div className="row dFlex alignItemsCenter">
      <div key={id} className="mb2 colMd12">
        <SelectInput
          name={name}
          id={id.toString()}
          register={register({ required: mandatory })}
          renderLabel={!!label}
          options={options}
          error={errors[name]}
          defaultPlaceholder={true}
          isRequired={mandatory}
        />
      </div>
    </div>
  )
}
