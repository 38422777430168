import { IFilters } from '../interfaces/IFilters'

export const advancedFiltersInitialState: IFilters = {
  name: '',
  address: '',
  phoneNumber: '',
  lastVisitStart: '',
  lastVisitEnd: '',
  range: '',
  rangeCip: '',
  cipCode: '',
  targetType2Ids: [],
  zipCode: '',
  city: '',
  noAppointments: false,
  search: '',
  sectoLabo: '',
  typeCible: '',
  clusterIds: [],
  uga: '',
  palier2022: '',
  palier2021: '',
  decile: '',
  potentialCa: '',
  potentialBoites: '',
  capping: '',
  subscribeComptoir: null,
  subscribeCoach: null,
  visiteReport: 0,
  appointment: 0,
  date: '',
  recentColaborationCode: [],
  previousColaborationCode: [],
  noActionError: '',
}

export const FILTER_NAME_FIELD = 'name'
export const FILTER_ADDRESS_FIELD = 'address'
export const FILTER_PHONE_NUMBER_FIELD = 'phoneNumber'
export const FILTER_LAST_VISIT_START_FIELD = 'lastVisitStart'
export const FILTER_LAST_VISIT_END_FIELD = 'lastVisitEnd'
export const FILTER_RANGE_FIELD = 'range'
export const FILTER_RANGE_CIP_FIELD = 'rangeCip'
export const FILTER_CIP_CODE_FIELD = 'cipCode'
export const FILTER_TARGET_TYPE_2_FIELD = 'targetType2Ids'
export const FILTER_ZIPCODE_FIELD = 'zipCode'
export const FILTER_CITY_FIELD = 'city'
export const FILTER_APPOINTMENTS_FIELD = 'noAppointments'
export const FILTER_DEFAULT_SEARCH_FIELD = 'search'
export const FILTER_SECTO_LABO_FIELD = 'sectoLabo'
export const FILTER_TYPE_CIBLE_FIELD = 'typeCible'
export const FILTER_CLUSTER_FIELD = 'clusterIds'
export const FILTER_UGA_FIELD = 'uga'
export const FILTER_PALIER_2022_FIELD = 'palier2022'
export const FILTER_PALIER_2021_FIELD = 'palier2021'
export const FILTER_DECILE_FIELD = 'decile'
export const FILTER_POTENTIAL_CA_FIELD = 'potentialCa'
export const FILTER_POTENTIAL_BOITES_FIELD = 'potentialBoites'
export const FILTER_CAPPING_FIELD = 'capping'
export const FILTER_SUBSCRIBE_COMPTOIR_FIELD = 'subscribeComptoir'
export const FILTER_SUBSCRIBE_COACH_FIELD = 'subscribeCoach'
export const FILTER_VISITE_REPORT_FIELD = 'visiteReport'
export const FILTER_APPOINTMENT_FIELD = 'appointment'
export const FILTER_DATE_FIELD = 'date'

export const SERVICE_QUESTIONNAIRE_COMPTOIR = 'serviceQuestionnaireComptoir'
export const SERVICE_QUESTIONNAIRE_COACH = 'serviceCoach'

export const FILTER_RECENT_COLABORATION_CODE = 'recentColaborationCode'
export const FILTER_PREVIOUS_COLABORATION_CODE = 'previousColaborationCode'

export const SERVICE_QUESTIONNAIRE = [
  { key: FILTER_SUBSCRIBE_COMPTOIR_FIELD, className: 'mb1' },
  { key: FILTER_SUBSCRIBE_COACH_FIELD, className: '' },
]

export const DEFAULT_COUNT_VALUE = -1

export const PAGE_PARAM = 'page'

export const ADVANCED_SEARCH_QUERY_MAPPING = {
  page: PAGE_PARAM,
  name: FILTER_NAME_FIELD,
  address: FILTER_ADDRESS_FIELD,
  phoneNumber: FILTER_PHONE_NUMBER_FIELD,
  lastVisitStart: FILTER_LAST_VISIT_START_FIELD,
  lastVisitEnd: FILTER_LAST_VISIT_END_FIELD,
  range: FILTER_RANGE_FIELD,
  rangeCip: FILTER_RANGE_CIP_FIELD,
  cipCode: FILTER_CIP_CODE_FIELD,
  targetType2Ids: FILTER_TARGET_TYPE_2_FIELD,
  zipCode: FILTER_ZIPCODE_FIELD,
  city: FILTER_CITY_FIELD,
  noAppointments: FILTER_APPOINTMENTS_FIELD,
  search: FILTER_DEFAULT_SEARCH_FIELD,
  sectoLabo: FILTER_SECTO_LABO_FIELD,
  uga: FILTER_UGA_FIELD,
  palier2022: FILTER_PALIER_2022_FIELD,
  palier2021: FILTER_PALIER_2021_FIELD,
  decile: FILTER_DECILE_FIELD,
  potentialCa: FILTER_POTENTIAL_CA_FIELD,
  potentialBoites: FILTER_POTENTIAL_BOITES_FIELD,
  capping: FILTER_CAPPING_FIELD,
  typeCible: FILTER_TYPE_CIBLE_FIELD,
  clusterIds: FILTER_CLUSTER_FIELD,
  appointment: FILTER_APPOINTMENT_FIELD,
  visiteReport: FILTER_VISITE_REPORT_FIELD,
  date: FILTER_DATE_FIELD,
  subscribeComptoir: FILTER_SUBSCRIBE_COMPTOIR_FIELD,
  subscribeCoach: FILTER_SUBSCRIBE_COACH_FIELD,
  recentColaborationCode: FILTER_RECENT_COLABORATION_CODE,
  previousColaborationCode: FILTER_PREVIOUS_COLABORATION_CODE,
} as const

export const ADVANCED_SEARCH_INPUT_FIELDS = {
  name: FILTER_NAME_FIELD,
  address: FILTER_ADDRESS_FIELD,
  phoneNumber: FILTER_PHONE_NUMBER_FIELD,
  lastVisitStart: FILTER_LAST_VISIT_START_FIELD,
  lastVisitEnd: FILTER_LAST_VISIT_END_FIELD,
  range: FILTER_RANGE_FIELD,
  rangeCip: FILTER_RANGE_CIP_FIELD,
  cipCode: FILTER_CIP_CODE_FIELD,
  zipCode: FILTER_ZIPCODE_FIELD,
  city: FILTER_CITY_FIELD,
  noAppointments: FILTER_APPOINTMENTS_FIELD,
  sectoLabo: FILTER_SECTO_LABO_FIELD,
  uga: FILTER_UGA_FIELD,
  palier2022: FILTER_PALIER_2022_FIELD,
  palier2021: FILTER_PALIER_2021_FIELD,
  decile: FILTER_DECILE_FIELD,
  potentialCa: FILTER_POTENTIAL_CA_FIELD,
  potentialBoites: FILTER_POTENTIAL_BOITES_FIELD,
  capping: FILTER_CAPPING_FIELD,
  targetType2Ids: FILTER_TARGET_TYPE_2_FIELD,
  typeCible: FILTER_TYPE_CIBLE_FIELD,
  appointment: FILTER_APPOINTMENT_FIELD,
  visiteReport: FILTER_VISITE_REPORT_FIELD,
  date: FILTER_DATE_FIELD,
  subscribeComptoir: FILTER_SUBSCRIBE_COMPTOIR_FIELD,
  subscribeCoach: FILTER_SUBSCRIBE_COACH_FIELD,
  recentColaborationCode: FILTER_RECENT_COLABORATION_CODE,
  previousColaborationCode: FILTER_PREVIOUS_COLABORATION_CODE,
} as const

export const ADVANCED_SEARCH_SELECT_INPUT_FIELDS = {
  clusterIds: FILTER_CLUSTER_FIELD,
} as const

export const ADVANCED_SEARCH_DATE_FIELDS = {
  lastVisitStart: FILTER_LAST_VISIT_START_FIELD,
  lastVisitEnd: FILTER_LAST_VISIT_END_FIELD,
  date: FILTER_DATE_FIELD,
} as const

export const ADVANCED_SEARCH_BOOLEAN_FIELDS = {
  appointment: FILTER_APPOINTMENT_FIELD,
  visiteReport: FILTER_VISITE_REPORT_FIELD,
} as const

export const ADVANCED_FILTERS_MODAL_FORM_KEY = 'advancedFiltersModalForm'
export const MAP_FORM_KEY = 'mapForm'

export const INPUT_SEARCH_ACCOUNTS_PORTFOLIO = 'simpleSearchName'

export const INPUT_SEARCH_MAP = 'simpleSearchText'
