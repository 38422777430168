import React, { FC } from 'react'
import { IconUploadImage } from '../../../assets/images/images'
import DynamicFormattedMessage from '../../common/ui/DynamicFormattedMessage'

export const ImagePlaceholder: FC = () => {
  return (
    <div className="customImageUploadItem row mx0 justifyContentCenter alignItemsCenter withBackgroundColorNeutral hasBorderRadius textCenter py15">
      <div className="col4">
        <IconUploadImage
          title="IconCamera"
          stroke="currentColor"
          fill="currentColor"
        />
      </div>
      <DynamicFormattedMessage
        tag="p"
        id="upload.image.label"
        className="textPrimary dBlock"
      />
    </div>
  )
}
