import {
  IActivity,
  IActivityItemsData,
  IActivityTypes,
  IUser,
} from '../interfaces/IUsers'
import { AuthToken } from '../interfaces/IRequest'

export const processUserActivityData = (
  activity: IActivity[] = [],
  type: keyof IActivityTypes
) => {
  const defaultData: number[] = []
  const activityData: IActivityItemsData = {
    visits: [...defaultData],
    appointments: [...defaultData],
    appointmentsWithVisitReportPercentage: [...defaultData],
    appointmentsWithoutVisitReportPercentage: [...defaultData],
    visitsPercentage: [...defaultData],
    opportunityVisitsPercentage: [...defaultData],
  }

  if (!activity?.length) return activityData

  Object.keys(activityData).map((key) =>
    activity.forEach((monthActivity) => {
      activityData[key as keyof IActivityItemsData].push(
        monthActivity[type][key as keyof IActivityItemsData]
      )
    })
  )

  return activityData
}

export const isUserAvailable = (users: IUser[], userId: string) =>
  users?.some((user) => user?.id?.toString() === userId)

export const getJwtUsername = (jwt: AuthToken) =>
  jwt.username || `${jwt.lastName} ${jwt.firstName}`

const getMatchedUser = (users: IUser[], userId: string) =>
  users?.find((user) => user?.id?.toString() === userId)

export const getLoggedUsername = (users: IUser[], userId: string) => {
  const matchedUser = getMatchedUser(users, userId)

  return (
    matchedUser?.username ||
    `${matchedUser?.lastName} ${matchedUser?.firstName}`
  )
}
