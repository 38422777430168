import React, { Dispatch, FC, SetStateAction } from 'react'
import { FormProvider } from 'react-hook-form'
import { IClientDetailsData } from '../../interfaces/IClientDetails'
import { getAlertType } from '../../utils/helpers'
import { StickyWrapper } from '../common/ui/StickyWrapper'
import Alerts from '../alerts/Alerts'
import DestructionCertificateFirstSection from './destructionCertificateFormComponents/DestructionCertificateFirstSection'
import useNewDestructionCertificateFormHook from '../../hooks/UseNewDestructionCertificateFormHook'
import DestructionCertificateSecondSection from './destructionCertificateFormComponents/DestructionCertificateSecondSection'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'
import Button from '../common/ui/Button'
import { InfoMessageAlerts } from '../alerts/InfoMessageAlerts'
import { STATUS } from '../../enums/common'
import Loader from '../loader/Loader'
import { TStatus } from '../../interfaces/INavigation'

const DestructionCertificateForm: FC<{
  setIsModalOpen: Dispatch<SetStateAction<boolean>>
  clientDetailsData: IClientDetailsData
  dataLoadStatus: TStatus
  dataLoadMessage?: string
}> = ({
  setIsModalOpen,
  clientDetailsData,
  dataLoadStatus,
  dataLoadMessage,
}) => {
  const {
    form: { handleSubmit },
    form,
    formState,
    onSubmit,
    contactPersons,
    newContactPerson,
    setNewContactPerson,
    products,
    setProducts,
    formProductsCount,
    isSubmitDisabled,
    missingEmailWarning,
    emailMissingContact,
    missingEmailDanger,
    handleModal,
    handleReturn,
  } = useNewDestructionCertificateFormHook(
    clientDetailsData,
    setIsModalOpen,
    dataLoadStatus
  )

  return (
    <>
      {dataLoadStatus === STATUS.DANGER && (
        <StickyWrapper>
          <Alerts status={dataLoadStatus} message={dataLoadMessage} />
        </StickyWrapper>
      )}
      {dataLoadStatus === STATUS.SUCCESS && (
        <StickyWrapper>
          <Alerts
            status={contactPersons.status}
            message={contactPersons.message}
          />
          {missingEmailWarning && (
            <InfoMessageAlerts
              message="form.destructionCertificate.missingEmailWarning"
              type="warning"
              data={emailMissingContact}
              joinSeparator="/"
            />
          )}
          {missingEmailDanger && (
            <InfoMessageAlerts
              message="form.destructionCertificate.missingEmailDanger"
              type="danger"
            />
          )}
          <Alerts
            status={formState.status}
            message={formState.message}
            type={getAlertType(formState.messageCode)}
          />
        </StickyWrapper>
      )}
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)} onChange={() => handleModal()}>
          <DestructionCertificateFirstSection
            clientDetails={clientDetailsData}
            contactPersons={contactPersons.data}
            newContactPerson={newContactPerson}
            setNewContactPerson={setNewContactPerson}
            products={products}
            setProducts={setProducts}
            formProductsCount={formProductsCount}
          />
          <DestructionCertificateSecondSection
            clientDetails={clientDetailsData}
          />
          {formState.status === STATUS.PENDING && (
            <div className="mb3">
              <Loader />
            </div>
          )}
          <div className="formButtonsWrapper justifyContentBetween dFlex">
            <DynamicFormattedMessage
              id="form.button.cancel"
              tag={Button}
              className="btn btnOutline linkUnstyled withBackgroundColorDefault px15 py15 cancelNewDestructionCertificate"
              data-qa="cancelNewDestructionCertificate"
              onClick={() => handleReturn()}
            />
            <DynamicFormattedMessage
              id="form.button.submit"
              type="submit"
              tag={Button}
              className="btn btnPrimary px15 py15 submitNewDestructionCertificate"
              data-qa="submitNewDestructionCertificate"
              disabled={isSubmitDisabled}
            />
          </div>
        </form>
      </FormProvider>
    </>
  )
}

export default DestructionCertificateForm
