import React from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { ContractList } from '../contractList/ContractList'
import { statusNetworkSelector } from '../../store/selectors'
import { useLoadData } from '../../hooks/UseLoadData'
import { getContractHistoryData } from '../../services/contractsServices'
import { getClientContractsData } from '../../store/selectors/clientContractsSelectors'
import SelectInput from '../form/SelectInput'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'
import { useClientForms } from '../../hooks/clientForms/UseClientForms'
import { MixedFormType } from '../mixedForms/MixedFormType'
import { MIXED_FORM_TYPE_DESTRUCTION_CERTIFICATE } from '../../constants/mixedForms'

export const ClientForms = ({ clientId }: { clientId: number }) => {
  const intl = useIntl()
  const { hasNetwork } = useSelector(
    statusNetworkSelector.getStatusNetworkValue
  )

  const { data, status, message } = useLoadData(
    () => getContractHistoryData(clientId),
    {
      dependencies: [clientId],
      fetchFromRedux: !!clientId && !hasNetwork,
      reduxSelector: getClientContractsData,
      reduxStorePath: `${clientId}.contractHistoryData`,
    }
  )

  const {
    selectedYear,
    contractTypeList,
    year,
    listContractTypes,
    years,
    showHistorySection,
    onSelectYearChange,
  } = useClientForms(data, status)

  return (
    <section className="contractsSection mb2">
      <div className="mixedFormTypeSection px0">
        <ul className="mixedFormTypeList unstyledList">
          <li>
            <MixedFormType
              clientId={clientId}
              mixedFormType={MIXED_FORM_TYPE_DESTRUCTION_CERTIFICATE}
              label={intl.formatMessage({
                id: `forms.${MIXED_FORM_TYPE_DESTRUCTION_CERTIFICATE}.title.label`,
              })}
            />
          </li>
        </ul>
      </div>
      <div className="contractTypeSection">
        <ContractList
          clientId={clientId}
          data={listContractTypes}
          status={status}
          message={message}
          year={year}
        />
      </div>
      {showHistorySection && (
        <div className="contractTypeSection">
          <div className="mb1 mt2 px0">
            <DynamicFormattedMessage
              id="page.forms.contracts.history.year"
              className="textSecondaryDarker textMd fontBold textUppercase my2 alignSelfCenter dInlineBlock"
              tag="span"
            />
            <div className="historyYears ml1sm dInlineBlock">
              <SelectInput
                name="historyYear"
                id="historyYear"
                data-qa="historyYear"
                options={years}
                defaultValue={`${selectedYear}`}
                onChange={onSelectYearChange}
                renderLabel={false}
                className="withBackgroundColorDefault"
              />
            </div>
            {!!selectedYear && contractTypeList && !!contractTypeList.length && (
              <div className="pt2">
                <ContractList
                  clientId={clientId}
                  data={contractTypeList}
                  status={status}
                  message={message}
                  showSectionTitle={false}
                  year={selectedYear}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </section>
  )
}
