import React, { FC, useMemo } from 'react'
import { Link, generatePath } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { IContractType } from '../../interfaces/IContracts'
import { iconReclamation, iconContracts } from '../../assets/images/images'
import { getFormattedString } from '../../utils/helpers'
import { getContractRoute } from '../../utils/contracts'

export const ContractType: FC<IContractType> = ({
  contract,
  contractName,
  contractNo,
  isMixedForm,
  mixedFormType,
  clientId,
  year,
}) => {
  const intl = useIntl()
  const imageSrc = mixedFormType ? iconReclamation : iconContracts
  const imageAlt = mixedFormType
    ? intl.formatMessage({ id: `page.icon.${mixedFormType}.altText` })
    : 'contract'
  const route = useMemo(
    () => getContractRoute(isMixedForm, clientId, year, mixedFormType),
    [year, isMixedForm, mixedFormType, clientId]
  )
  const contractNoStatus = contractNo && contractNo > 0 ? 'active' : ''

  return (
    <Link
      to={generatePath(route, {
        year,
        clientId,
        contractId: contract?.id,
        contractLabel: getFormattedString(contract?.label),
      })}
      className={`contractName dFlex flexNowrap alignItemsCenter withBackgroundColorDefault hasBorderRadius hasBorderTop px15 pt2 pb15 linkUnstyled ${
        isMixedForm && 'reclamationType'
      }`}
      data-qa="ctaSeeContract"
    >
      <img src={imageSrc} alt={`${imageAlt} icon`} className="mr1" />
      <h4 className="contractName textPrimary fontRegular">{contractName}</h4>
      {!isMixedForm && (
        <span
          className={`fontMedium textCenter ml05 contractNoStatus ${contractNoStatus}`}
        >
          {contractNo}
        </span>
      )}
    </Link>
  )
}
