import React, { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import Checkbox from '../../../form/Checkbox'
import { IVisitReportForm } from '../../../../interfaces/IVisitReports'
import TextInput from '../../../form/TextInput'
import {
  VISIT_EVENTS_TYPE_1,
  VISIT_EVENTS_TYPE_1_INPUTS,
  VISIT_EVENTS_TYPE_1_SIGNED,
  VISIT_EVENTS_TYPE_1_SUBSCRIPTION,
} from '../../../../constants/visitReport'
import { useIsTabDisabled } from '../../../../hooks/clientVisitReport/UseIsTabDisabled'
import { validateNumberInput } from '../../../../utils/visitReports'
import { getNWord } from '../../../../utils/helpers'

export const VisitReportEventsExtraFields = ({
  defaultData,
  dataType = '',
}: {
  defaultData: IVisitReportForm
  dataType?: string
}) => {
  const isDisabled = useIsTabDisabled()
  const { register, errors, watch } = useFormContext()
  const watchedFields = watch([VISIT_EVENTS_TYPE_1])

  const isVisible = useMemo(() => !!watchedFields[VISIT_EVENTS_TYPE_1], [
    watchedFields,
  ])

  return (
    <div>
      <div className="row mx0">
        {VISIT_EVENTS_TYPE_1_INPUTS.map((inputKey) => (
          <TextInput
            key={inputKey}
            dataType={dataType}
            name={inputKey}
            id={inputKey}
            register={register({
              required: isVisible && !isDisabled,
              validate: (value) =>
                validateNumberInput(value, isVisible && !isDisabled),
            })}
            error={errors[inputKey]}
            placeholder="typeIn"
            defaultValue={
              defaultData[inputKey as keyof IVisitReportForm] as string
            }
            customClass="mr2 my05 textXs rowInputField mandatory"
            labelText={`visitReport.${getNWord(inputKey).toLowerCase()}`}
          />
        ))}
      </div>
      <div className="row mx0">
        <TextInput
          key={VISIT_EVENTS_TYPE_1_SUBSCRIPTION}
          dataType={dataType}
          name={VISIT_EVENTS_TYPE_1_SUBSCRIPTION}
          id={VISIT_EVENTS_TYPE_1_SUBSCRIPTION}
          register={register({
            required: isVisible && !isDisabled,
            validate: (value) =>
              validateNumberInput(value, isVisible && !isDisabled),
          })}
          error={errors[VISIT_EVENTS_TYPE_1_SUBSCRIPTION]}
          placeholder="typeIn"
          defaultValue={
            defaultData[
              VISIT_EVENTS_TYPE_1_SUBSCRIPTION as keyof IVisitReportForm
            ] as string
          }
          customClass="mr2 my05 textXs rowInputField mandatory"
          labelText={`visitReport.${getNWord(
            VISIT_EVENTS_TYPE_1_SUBSCRIPTION
          ).toLowerCase()}`}
        />
        <Checkbox
          dataType={dataType}
          name={VISIT_EVENTS_TYPE_1_SIGNED}
          id={VISIT_EVENTS_TYPE_1_SIGNED}
          register={register({ required: isVisible && !isDisabled })}
          defaultValue={false}
          error={errors[VISIT_EVENTS_TYPE_1_SIGNED]}
          customClass="my05 customCheckbox signedCheckbox dFlex flexColumn alignSelfCenter noBorder mandatory"
          labelText={`visitReport.${getNWord(
            VISIT_EVENTS_TYPE_1_SIGNED
          ).toLowerCase()}`}
        />
      </div>
    </div>
  )
}
