import React, { FC, useState } from 'react'
import { useUserMedia } from '../../../hooks/UseUserMedia'
import { ITabForm } from '../../../interfaces/IVisitReports'
import {
  VISIT_SELLOUT,
  VISIT_SELLOUT_ROWS,
  VISIT_SELLOUT_TYPE_3,
  VISIT_SELLOUT_TYPE_3_ROW,
  VISIT_SELLOUT_TYPE_6,
  VISIT_SELLOUT_TYPE_6_ROW,
  VISIT_SELLOUT_TYPE_3_INPUTS_DETAILS,
  VISIT_SELLOUT_TYPE_6_INPUTS_DETAILS,
} from '../../../constants/visitReport'
import { VisitReportSection } from './commonComponents/VisitReportSection'
import { VisitReportEngagementExtraFields } from './thirdPanelComponents/VisitReportEngagementExtraFields'
import { VisitReportSectionRow } from './commonComponents/VisitReportSectionRow'
import { UserMediaSection } from '../../common/ui/UserMediaSection'

export const VisitReportThirdPanel: FC<ITabForm> = ({
  defaultData,
  updateForm,
}) => {
  const [isCameraOpen, setIsCameraOpen] = useState(false)
  const camera = useUserMedia()

  return (
    <>
      <UserMediaSection
        camera={camera}
        isCameraOpen={isCameraOpen}
        setIsCameraOpen={setIsCameraOpen}
      />
      <VisitReportSection
        section={VISIT_SELLOUT}
        rows={VISIT_SELLOUT_ROWS}
        camera={camera}
        isCameraOpen={isCameraOpen}
        defaultData={defaultData}
        updateForm={updateForm}
      >
        {/* Sellout Engagement QAC with extra fields */}
        <VisitReportSectionRow
          data={VISIT_SELLOUT_TYPE_3_ROW}
          customClass={VISIT_SELLOUT_TYPE_3}
          camera={camera}
          isCameraOpen={isCameraOpen}
          defaultData={defaultData}
          updateForm={updateForm}
        >
          <VisitReportEngagementExtraFields
            parentSection={VISIT_SELLOUT_TYPE_3}
            extraFields={VISIT_SELLOUT_TYPE_3_INPUTS_DETAILS}
            defaultData={defaultData}
            dataType={VISIT_SELLOUT_TYPE_3}
          />
        </VisitReportSectionRow>
        {/* Sellout Engagement FSL with extra fields */}
        <VisitReportSectionRow
          data={VISIT_SELLOUT_TYPE_6_ROW}
          camera={camera}
          isCameraOpen={isCameraOpen}
          defaultData={defaultData}
          updateForm={updateForm}
        >
          <VisitReportEngagementExtraFields
            parentSection={VISIT_SELLOUT_TYPE_6}
            extraFields={VISIT_SELLOUT_TYPE_6_INPUTS_DETAILS}
            defaultData={defaultData}
            dataType={VISIT_SELLOUT_TYPE_6}
          />
        </VisitReportSectionRow>
      </VisitReportSection>
    </>
  )
}
