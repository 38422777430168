import React, { FC, useEffect, useMemo, useState } from 'react'
import { format } from 'date-fns'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { IMapDirections } from '../../interfaces/IMap'
import Button from '../common/ui/Button'
import {
  DATE_REQUEST_FORMAT,
  DATEPICKER_FORMAT2,
  STATUS,
} from '../../enums/common'
import { InfoIconWithTooltip } from '../common/Tooltip/InfoIconWithTooltip'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'
import Alerts from '../alerts/Alerts'
import { TStatus } from '../../interfaces/INavigation'
import {
  BASE_URL,
  MAP_TOOLTIP_OFFLINE,
  MAP_TOOLTIP_ONLINE,
  MAX_MAP_POINTS,
  ZOOM_VALUE,
} from '../../constants/map'
import { getMapAppointments } from '../../services/appointmentService'
import { statusNetworkSelector } from '../../store/selectors'

export const MapDirectionsSection: FC<IMapDirections> = ({ filters }) => {
  const intl = useIntl()

  const [mapDirectionsStatus, setMapDirectionsStatus] = useState<TStatus>(
    STATUS.IDLE
  )

  const { hasNetwork } = useSelector(
    statusNetworkSelector.getStatusNetworkValue
  )
  const [mapTooltip, setMapTooltip] = useState(
    hasNetwork ? MAP_TOOLTIP_ONLINE : MAP_TOOLTIP_OFFLINE
  )

  useEffect(() => {
    setMapTooltip(hasNetwork ? MAP_TOOLTIP_ONLINE : MAP_TOOLTIP_OFFLINE)
  }, [hasNetwork])

  useEffect(() => {
    setMapDirectionsStatus(STATUS.IDLE)
  }, [filters])

  const disabled = useMemo(
    () => !filters.appointment || !filters.date || !hasNetwork,
    [hasNetwork, filters]
  )

  const generateRouteMap = async () => {
    const currentDate = new Date()
    const formatedCurrentDate = format(currentDate, DATEPICKER_FORMAT2)
    const formatedCurrentDateTime = format(currentDate, DATE_REQUEST_FORMAT)
    const selectedDate = format(new Date(filters.date), DATEPICKER_FORMAT2)

    const {
      body: { data: appointments },
    } = await getMapAppointments(selectedDate)

    if (appointments.length > 0) {
      let url = BASE_URL
      let mapPoints = 0

      appointments.forEach(({ gpsLongitude, gpsLatitude, appointmentDate }) => {
        if (
          ((formatedCurrentDate === selectedDate &&
            !!appointmentDate &&
            appointmentDate > formatedCurrentDateTime) ||
            formatedCurrentDate !== selectedDate) &&
          mapPoints <= MAX_MAP_POINTS
        ) {
          mapPoints += 1
          url += `${gpsLatitude},${gpsLongitude}/`
        }
      })

      if (url === BASE_URL) {
        setMapDirectionsStatus(STATUS.DANGER)
      } else {
        url += `@${appointments[0].gpsLatitude},${appointments[0].gpsLongitude},${ZOOM_VALUE}`
        window.open(url, '_blank')
      }
    } else {
      setMapDirectionsStatus(STATUS.DANGER)
    }
  }

  return (
    <>
      <Button
        className={`link btn linkBasic fontMedium textCenter btnLink pl0 ${
          disabled ? 'textNeutralLightest' : ''
        }`}
        onClick={() => generateRouteMap()}
        disabled={disabled}
      >
        <DynamicFormattedMessage id="map.mapFilters.mapDirections.label" />
      </Button>
      <InfoIconWithTooltip
        tooltipId="mapToolTip"
        tooltipLabel={mapTooltip.label}
        customClass="alignSelfCenter pr1"
        fillColor={mapTooltip.color}
        backgroundColor={mapTooltip.backgroundColor}
      />
      <Alerts
        status={mapDirectionsStatus}
        message={intl.formatMessage({
          id: 'map.mapFilters.mapDirections.error',
        })}
      />
    </>
  )
}
