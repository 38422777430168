import {
  mapMarker,
  mapMarkerGrey,
  mapMarkerRed,
  mapMarkerYellow,
  mapMarkerPurple,
  mapMarkerDarkGreen,
} from '../assets/images/images'
import { TClientDetailsItem } from '../interfaces/IClientDetails'

export const getMapMarker = (client: TClientDetailsItem): string => {
  const { nextAppointmentDate, withVisitReport, withAppointment } = client
  const currentDate = new Date().setHours(0, 0, 0, 0)
  const nextAppointment = nextAppointmentDate
    ? new Date(nextAppointmentDate).setHours(0, 0, 0, 0)
    : null

  if (withAppointment || withVisitReport) {
    return withVisitReport
      ? withAppointment
        ? mapMarkerDarkGreen
        : mapMarkerPurple
      : mapMarkerRed
  }

  if (!nextAppointment) {
    return mapMarkerGrey
  }

  if (nextAppointment && nextAppointment === currentDate) {
    return mapMarkerRed
  }

  if (nextAppointment && nextAppointment > currentDate) {
    return mapMarkerYellow
  }

  return mapMarker
}
