import React, { ChangeEvent, ReactElement } from 'react'
import { FieldError } from 'react-hook-form'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'
import ErrorMessage from './ErrorMessage'

function Checkbox({
  name,
  id,
  register,
  error,
  customClass = '',
  defaultValue,
  values,
  labelText = '',
  hasLabel = true,
  onChange,
  disabled = false,
  dataType = '',
  isLabelTranslated = true,
  wrapperClass,
}: {
  name: string
  id: string
  register: (ref: HTMLInputElement) => void
  error?: FieldError | undefined
  customClass?: string
  defaultValue?: boolean
  values?: {}
  labelText?: string
  hasLabel?: boolean
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
  dataType?: string
  isLabelTranslated?: boolean
  wrapperClass?: string
}): ReactElement {
  return (
    <div className={`inputGroup ${customClass}`}>
      <div className={`inputCheckboxWrapper ${wrapperClass} dInlineFlex`}>
        {isLabelTranslated ? (
          <DynamicFormattedMessage
            tag="label"
            id={`${
              hasLabel ? `form.field.${labelText || name}.label` : 'no.label'
            }`}
            className="inputLabel"
            htmlFor={id}
            values={values}
          />
        ) : (
          <label className="inputLabel textXs" htmlFor={id}>
            {labelText}
          </label>
        )}

        <input
          name={name}
          type="checkbox"
          id={id}
          ref={register}
          className="inputCheckboxField"
          defaultChecked={defaultValue}
          onChange={onChange}
          data-parent={dataType}
          disabled={disabled}
        />
        <span
          className={`inputCheckboxControl ${disabled ? 'inputDisabled' : ''}`}
        />
      </div>

      <ErrorMessage error={error} />
    </div>
  )
}

export default Checkbox
