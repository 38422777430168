import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { FormProvider } from 'react-hook-form'
import { IUsersOptions } from '../../interfaces/IUsers'
import { CurrentDayAppointments } from './CurrentDayAppointments'
import { VisitReports } from './VisitReports'
import { ActivityReports } from './ActivityReports'
import { WorkingDays } from './WorkingDays'
import { useDashboardHook } from '../../hooks/dashboard/UseDashboardHook'
import {
  sharedDataSelectors,
  statusNetworkSelector,
} from '../../store/selectors'
import { UsersSelect } from '../users/UsersSelect'
import { CLIENTS_ACTIVITY, PROSPECTS_ACTIVITY } from '../../constants/dashboard'

export const DashboardContent: FC = () => {
  const { hasNetwork } = useSelector(
    statusNetworkSelector.getStatusNetworkValue
  )
  const userId = useSelector(sharedDataSelectors.getUserSavedData).selectedUser
  const {
    users,
    appointments,
    contactTypes,
    userActivity,
    onUserChange,
    dataStatusPending,
    isUsersLoadingError,
    isUserNotAvailableError,
    form,
    setShouldRefetchUserActivity,
  } = useDashboardHook()

  return (
    <>
      <UsersSelect
        name="userFilter"
        users={users?.data as IUsersOptions[]}
        onUserChange={onUserChange}
        defaultUser={userId}
        disabled={!hasNetwork || dataStatusPending}
        wrapperClass="colMd5 mb1 px0"
        isUserNotAvailableError={isUserNotAvailableError}
        isUsersLoadingError={isUsersLoadingError}
      />
      <CurrentDayAppointments {...appointments} />
      <WorkingDays />
      <FormProvider {...form}>
        <VisitReports
          {...{ ...userActivity, contactTypes, setShouldRefetchUserActivity }}
        />
      </FormProvider>
      <ActivityReports {...{ ...userActivity, type: CLIENTS_ACTIVITY }} />
      <ActivityReports {...{ ...userActivity, type: PROSPECTS_ACTIVITY }} />
    </>
  )
}
