import React, { FC } from 'react'
import { IconUploadImage } from '../../../assets/images/images'
import DynamicFormattedMessage from './DynamicFormattedMessage'

export const ImagePlaceholder: FC<{ className?: string }> = ({ className }) => {
  return (
    <div
      className={`${
        className || 'customImageUploadItem justifyContentCenter'
      } row`}
    >
      <div className="row col8 justifyContentCenter withBackgroundColorNeutral hasBorderRadius py15">
        <div className="row col4">
          <IconUploadImage
            title="IconCamera"
            stroke="currentColor"
            fill="currentColor"
          />
        </div>
        <div className="row col12 justifyContentCenter textCenter">
          <DynamicFormattedMessage
            tag="p"
            id="upload.image.label"
            className="textPrimary"
          />
        </div>
      </div>
    </div>
  )
}
