import React, { FC } from 'react'
import { useUserMedia } from '../../hooks/UseUserMedia'
import { IPhotoCapture } from '../../interfaces/IVisitReports'
import ImageUploadButton from './photoCapture/ImageUploadButton'
import ImageDeleteButton from './photoCapture/ImageDeleteButton'

export const PhotoCapture: FC<IPhotoCapture> = ({
  type,
  isCameraOpen,
  photoRef,
  isDisabled,
  wrapperClassName,
  onPhotoUpdateCallback,
  onImageDeleteCallback,
  onImageUploadCallback,
  showSimpleUploadButton = true,
}) => {
  const camera = useUserMedia()
  const { isCanvasEmpty, takePhoto, handleClearPhoto } = camera

  return (
    <div
      className={`customImageUploadWrapper dInlineBlock ${wrapperClassName}`}
    >
      <div className="customImageUploadBtnWrapper">
        {isCanvasEmpty ? (
          <ImageUploadButton
            isSimpleUploadButton={showSimpleUploadButton}
            isDisabled={!isCameraOpen || isDisabled}
            onClick={() => {
              takePhoto(
                photoRef,
                type,
                onPhotoUpdateCallback,
                onImageUploadCallback
              )
            }}
            className={!isCameraOpen || isDisabled ? 'btnDisabled' : ''}
          />
        ) : (
          <ImageDeleteButton
            labelId="clearPhoto.button.label"
            showSimpleUploadButton={showSimpleUploadButton}
            onClick={() => {
              handleClearPhoto(type, onPhotoUpdateCallback)
              if (onImageDeleteCallback) {
                onImageDeleteCallback()
              }
            }}
            isDisabled={isDisabled}
            className={isDisabled ? 'btnDisabled' : ''}
          />
        )}
      </div>
    </div>
  )
}
