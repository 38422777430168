import React, { FC } from 'react'
import {
  ICustomTabForm,
  IVisitReportInformation,
} from '../../../interfaces/IVisitReports'
import { LastVisitReportInformation } from './firstPanelComponents/LastVisitReportInformation'
import { VisitReportDefaultFields } from './firstPanelComponents/VisitReportDefaultFields'

export const VisitReportFirstPanel: FC<ICustomTabForm> = ({
  informationData,
  clientId,
  defaultData,
  visitDate,
  setVisitDate,
}) => {
  return (
    <>
      <LastVisitReportInformation
        data={informationData as IVisitReportInformation}
      />
      <VisitReportDefaultFields
        clientId={clientId}
        defaultData={defaultData}
        visitDate={visitDate}
        setVisitDate={setVisitDate}
      />
    </>
  )
}
