import React, { FC } from 'react'
import Button from '../../common/ui/Button'
import DynamicFormattedMessage from '../../common/ui/DynamicFormattedMessage'

const ImageUploadButton: FC<{
  isSimpleUploadButton: boolean
  isDisabled: boolean
  onClick: () => void
  className?: string
}> = ({ isSimpleUploadButton, isDisabled, onClick, className }) => {
  return isSimpleUploadButton ? (
    <Button
      className={`btn btnPrimary btnUpload btnCameraUpload ${className}`}
      onClick={onClick}
      disabled={isDisabled}
    />
  ) : (
    <DynamicFormattedMessage
      id="uploadPhoto.button.label"
      tag={Button}
      className={`btn btnPrimary btnUpload btnCameraUploadRegular ${className}`}
      onClick={onClick}
      disabled={isDisabled}
    />
  )
}

export default ImageUploadButton
