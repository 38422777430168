import React, {
  ReactElement,
  KeyboardEvent,
  useMemo,
  useEffect,
  useCallback,
} from 'react'
import { FieldError, useForm } from 'react-hook-form'
import Button from '../common/ui/Button'
import {
  IAdvancedSearchFormData,
  TModalFilters,
} from '../../interfaces/IFilters'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'
import TextInput from '../form/TextInput'
import FormGroupDate from '../form/FormGroupDate'
import {
  FILTERS_RADIO_OPTIONS_LIST,
  ON_KEY_PRESS,
  RANGE_LABEL,
  TRUE_FALSE_OPTIONS,
} from '../../constants/form'
import { useGeolocation } from '../../hooks/map/UseGeolocation'
import {
  ADVANCED_SEARCH_SELECT_INPUT_FIELDS,
  advancedFiltersInitialState,
  SERVICE_QUESTIONNAIRE,
} from '../../constants/filters'
import MultipleSelect from '../form/MultipleSelect'
import {
  DATEPICKER_FORMAT,
  DYNAMIC_BUTTONS_INPUT_TYPE,
  INPUT_MAX_LENGTH,
  RAYON_CIP_LENGTH,
} from '../../enums/common'
import DynamicButtons from '../form/DynamicButtons'
import Checkbox from '../form/Checkbox'
import SelectInput from '../form/SelectInput'
import { prepareCappingOptions } from '../../utils/filters'
import { InfoIconWithTooltip } from '../common/Tooltip/InfoIconWithTooltip'

import { FILTER_CLIENT, FILTER_CLIENT_YEARS } from '../../constants/dashboard'
import {
  currentYearValue,
  getDynamicButtonDefaultValue,
} from '../../utils/helpers'
import { TDynamicButton } from '../../interfaces/ICommonComponents'
import { useFiltersHook } from '../../hooks/accountsPortfolio/UseFiltersHook'

export const FiltersModal = ({
  setFilters,
  changePage,
  filters,
  toggleModal,
  fieldsData,
}: TModalFilters<typeof ADVANCED_SEARCH_SELECT_INPUT_FIELDS>): ReactElement => {
  const {
    register,
    reset,
    handleSubmit,
    errors,
    watch,
    control,
    setValue,
    getValues,
    clearErrors,
  } = useForm<IAdvancedSearchFormData>({
    mode: 'onChange',
  })

  const { clusterIds: clusters } = fieldsData

  const watchFields = watch(['rangeCip', 'range'])

  const {
    isFocused,
    setIsFocused,
    lastVisitDate,
    setLastVisitDate,
    onSubmit,
    resetFilters,
    mapDate,
    setMapDate,
    validateSubmitCheckbox,
    isRayonGeolocationDisabled,
    setIsRayonGeolocationDisabled,
    formKey,
  } = useFiltersHook(
    { setFilters, filters, toggleModal, changePage, fieldsData },
    () => {
      setValue('groupIds', [])
      setLastVisitDate({
        startDate: '',
        endDate: '',
      })
    }
  )
  useGeolocation({ isFocused, setIsRayonGeolocationDisabled })

  const minDate = new Date()
  minDate.setMonth(minDate.getMonth() - 6)
  const maxDate = new Date()
  maxDate.setMonth(maxDate.getMonth() + 6)

  const mapStartDate = useMemo(() => {
    return {
      id: 'date',
      value: mapDate.startDate ? new Date(mapDate.startDate) : '',
      label: 'form.field.mapDate.label',
      setValue: setMapDate,
      classes: 'inputField inputDate datepickerLeft',
      control,
      minDate,
      maxDate,
      filterTime: true,
      displayTime: false,
      format: DATEPICKER_FORMAT,
      error: errors.date,
    }
  }, [mapDate.startDate, errors.date, control, minDate, maxDate, setMapDate])

  const startDate = useMemo(() => {
    return {
      id: 'lastVisitStart',
      value: lastVisitDate.startDate ? new Date(lastVisitDate.startDate) : '',
      label: 'form.field.lastVisit',
      setValue: setLastVisitDate,
      classes: 'inputField inputDate datepickerLeft',
      maxDate: new Date(),
      control,
      error: errors.lastVisitStart,
    }
  }, [
    lastVisitDate.startDate,
    control,
    errors.lastVisitStart,
    setLastVisitDate,
  ])

  const endDate = useMemo(() => {
    return {
      id: 'lastVisitEnd',
      value: lastVisitDate.endDate ? new Date(lastVisitDate.endDate) : '',
      setValue: setLastVisitDate,
      classes: 'inputField inputDate datepickerRight',
      control,
      error: errors.lastVisitEnd,
    }
  }, [lastVisitDate.endDate, control, errors.lastVisitEnd, setLastVisitDate])

  const triggerSubmit = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === ON_KEY_PRESS && !e.shiftKey) {
      handleSubmit(onSubmit)()
    }
  }

  const onChangeForMapDate = () => {
    clearErrors('visiteReport')
    clearErrors('appointment')
    clearErrors('date')
  }

  const isRayonDisabled = useMemo(
    () => !!(watchFields.rangeCip || isRayonGeolocationDisabled),
    [watchFields.rangeCip, isRayonGeolocationDisabled]
  )

  const isRayonCipDisabled = useMemo(() => !!watchFields.range, [
    watchFields.range,
  ])

  const resetFormFields = useCallback(() => {
    reset({
      ...advancedFiltersInitialState,
      appointment: 0,
      visiteReport: 0,
      date: '',
    })
    setMapDate({
      startDate: '',
      endDate: '',
    })
    resetFilters()
  }, [reset, resetFilters, setMapDate])

  useEffect(() => {
    if (!watchFields.rangeCip) {
      clearErrors('cipCode')
    }
  }, [watchFields.rangeCip, clearErrors])

  return (
    <div onKeyUp={triggerSubmit}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="row modalFiltersForm"
        onReset={() => resetFormFields()}
        key={formKey}
      >
        <div className="px1 mb15 colMd4">
          <TextInput
            name="name"
            id="name"
            labelText="raisonSociale"
            register={register({
              required: false,
              maxLength: INPUT_MAX_LENGTH,
            })}
            error={errors.name}
            placeholder="typeIn"
            defaultValue={filters?.name}
          />
        </div>
        <div className="px1 mb15 colMd5">
          <TextInput
            name="address"
            id="address"
            register={register({ required: false, maxLength: 100 })}
            error={errors.address}
            placeholder="typeIn"
            defaultValue={filters?.address}
          />
        </div>
        <div className="px1 mb15 colMd3">
          <TextInput
            name="zipCode"
            id="zipCode"
            register={register({
              required: false,
              pattern: {
                value: /^[0-9]*$/,
                message: 'number',
              },
              minLength: {
                value: 2,
                message: 'number.minLength',
              },
              maxLength: {
                value: 5,
                message: 'number.maxLength',
              },
              min: {
                value: 0,
                message: 'number',
              },
              max: {
                value: 99999,
                message: 'number.maxLength',
              },
            })}
            error={errors.zipCode}
            placeholder="typeIn"
            defaultValue={filters?.zipCode}
          />
        </div>
        <div className="px1 mb15 colMd4">
          <TextInput
            name="city"
            id="city"
            register={register({
              required: false,
              maxLength: INPUT_MAX_LENGTH,
            })}
            error={errors.city}
            placeholder="typeIn"
            defaultValue={filters?.city}
          />
        </div>
        <div className="px1 mb15 colMd4">
          <TextInput
            name="phoneNumber"
            id="phoneNumber"
            register={register({
              required: false,
              pattern: {
                value: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/,
                message: 'phoneNumber',
              },
            })}
            error={errors.phoneNumber}
            placeholder="typeIn"
            defaultValue={filters?.phoneNumber}
          />
        </div>
        <div className="px1 mb15 colMd4">
          <FormGroupDate
            startDate={startDate}
            endDate={endDate}
            startDateOnly={true}
            isClearable={true}
          />
        </div>
        <div className="px1 colMd4 relative inputRange">
          <TextInput
            name="range"
            id="range"
            register={register({
              required: false,
              pattern: {
                value: /^[0-9]*$/,
                message: 'number',
              },
            })}
            error={errors.range}
            placeholder="typeIn"
            defaultValue={filters?.range}
            onFocus={() => setIsFocused(true)}
            disabled={isRayonDisabled}
            customLabel={RANGE_LABEL}
            hasInfoLabel
          />
        </div>
        <div className="px1 colMd4 relative inputRangeCip">
          <TextInput
            name="rangeCip"
            id="rangeCip"
            register={register({
              required: false,
              maxLength: RAYON_CIP_LENGTH,
              pattern: {
                value: /^[0-9]*$/,
                message: 'number',
              },
            })}
            error={errors.rangeCip}
            placeholder="typeIn"
            defaultValue={filters?.rangeCip}
            disabled={isRayonCipDisabled}
            customLabel={RANGE_LABEL}
            hasInfoLabel
          />
        </div>
        <div className="px1 mb15 colMd4">
          <TextInput
            name="cipCode"
            id="cipCode"
            register={register({
              required: {
                value: !!watchFields.rangeCip,
                message: 'cipCode.error',
              },
              maxLength: INPUT_MAX_LENGTH,
            })}
            error={errors.cipCode}
            placeholder="typeIn"
            defaultValue={filters?.cipCode}
          />
        </div>
        <div className="px1 mb15 colMd4">
          <TextInput
            name="sectoLabo"
            id="sectoLabo"
            register={register({
              required: false,
              maxLength: INPUT_MAX_LENGTH,
            })}
            error={errors.sectoLabo}
            placeholder="typeIn"
            defaultValue={filters?.sectoLabo}
          />
        </div>
        <div className="px1 mb15 colMd4">
          <TextInput
            name="typeCible"
            id="typeCible"
            register={register({
              required: false,
              maxLength: INPUT_MAX_LENGTH,
            })}
            error={errors.typeCible}
            placeholder="typeIn"
            defaultValue={filters?.typeCible}
          />
        </div>
        <div className="px1 mb15 colMd4">
          <MultipleSelect
            id="clusterIds"
            control={control}
            error={errors.clusterIds as FieldError | undefined}
            options={clusters.data}
            defaultValue={filters?.clusterIds || []}
            inModalContainer={true}
          />
        </div>
        <div className="px1 mb15 colMd4">
          <TextInput
            name="uga"
            id="uga"
            register={register({
              required: false,
              maxLength: INPUT_MAX_LENGTH,
            })}
            error={errors.uga}
            placeholder="typeIn"
            defaultValue={filters?.uga}
          />
        </div>
        <div className="px1 mb15 colMd4">
          <TextInput
            name="palier2022"
            id="palier2022"
            register={register({
              required: false,
              maxLength: INPUT_MAX_LENGTH,
            })}
            error={errors.palier2022}
            placeholder="typeIn"
            defaultValue={filters?.palier2022}
          />
        </div>
        <div className="px1 mb15 colMd4">
          <TextInput
            name="palier2021"
            id="palier2021"
            register={register({
              required: false,
              maxLength: INPUT_MAX_LENGTH,
            })}
            error={errors.palier2021}
            placeholder="typeIn"
            defaultValue={filters?.palier2021}
          />
        </div>
        <div className="px1 mb15 colMd4">
          <TextInput
            name="decile"
            id="decile"
            type="number"
            register={register({
              pattern: {
                value: /^[0-9]*$/,
                message: 'number',
              },
              max: {
                value: 10,
                message: 'number.max10',
              },
            })}
            placeholder="typeIn"
            defaultValue={filters?.decile || ''}
            error={errors.decile}
          />
        </div>
        <div className="px1 mb15 colMd4">
          <SelectInput
            id="capping"
            name="capping"
            options={prepareCappingOptions()}
            register={register}
            error={errors.capping}
            defaultValue={filters?.capping || ''}
            defaultPlaceholder={false}
            hidePlaceholder={false}
          />
        </div>
        <div className="pl1 mb15 colMd4 dFlex flexWrap justifyContentBetween">
          {SERVICE_QUESTIONNAIRE.map(({ key, className }) => (
            <DynamicButtons
              key={key}
              optionsArray={TRUE_FALSE_OPTIONS}
              register={register}
              name={key}
              error={errors[key as keyof IAdvancedSearchFormData] as FieldError}
              defaultValue={getDynamicButtonDefaultValue(
                filters![key as keyof IAdvancedSearchFormData] as TDynamicButton
              )}
              customClass={className}
              inputType={DYNAMIC_BUTTONS_INPUT_TYPE.checkbox}
              setValue={setValue}
            />
          ))}
        </div>
        <div className="mapFilters pl1 col12 colXl8 dFlex flexWrap pb2">
          <div className="px0 pr1 colSm12 colMd6 dFlex">
            <FormGroupDate
              startDate={mapStartDate}
              startDateOnly={true}
              customClass="mr0 mt0 wFull"
              onDateChange={onChangeForMapDate}
              isClearable={true}
            />
            <InfoIconWithTooltip
              tooltipLabel="info.tooltipMapFilters.label"
              customClass="mt4"
            />
          </div>
          <div className="mapFiltersOptions dFlex flexWrap alignItemsCenter">
            <Checkbox
              name="appointment"
              id="appointment"
              register={register({
                validate: (value) =>
                  validateSubmitCheckbox(
                    value,
                    getValues().visiteReport,
                    'withAppointment'
                  ),
              })}
              onChange={onChangeForMapDate}
              error={errors.appointment}
              defaultValue={Boolean(filters?.appointment)}
              customClass="mt25 mr05"
            />
            <Checkbox
              name="visiteReport"
              id="visiteReport"
              register={register({
                validate: (value) =>
                  validateSubmitCheckbox(
                    value,
                    getValues().appointment,
                    'withVisiteReport'
                  ),
              })}
              defaultValue={Boolean(filters?.visiteReport)}
              onChange={onChangeForMapDate}
              error={errors.visiteReport}
              customClass="mt25"
            />
          </div>
        </div>
        <div className="px1 mb15 mt1 mt25xl col12 colXl4">
          <Checkbox
            name="noAppointments"
            id="noAppointments"
            register={register}
            defaultValue={filters?.noAppointments}
          />
        </div>
        <div className="row wFull mx0">
          {FILTER_CLIENT_YEARS.map((filterClient, index) => (
            <DynamicButtons
              key={filterClient}
              optionsArray={FILTERS_RADIO_OPTIONS_LIST}
              register={register({ required: false })}
              error={errors[filterClient]}
              name={filterClient}
              defaultValue={getDynamicButtonDefaultValue(
                filters![filterClient]
              )}
              labelName={FILTER_CLIENT}
              labelValues={{ year: currentYearValue - index }}
              customClass="colMd4 px1 pt15 pt05xl mb3"
              inputType={DYNAMIC_BUTTONS_INPUT_TYPE.checkbox}
              setValue={setValue}
            />
          ))}
        </div>
        <div className="col12 modalFiltersCtaRow px1 pb2">
          <div className="modalFiltersCtaWrapper">
            <Button
              onClick={toggleModal}
              type="submit"
              className="btn btnOutline textUppercase"
              data-qa="cancelFilters"
            >
              <DynamicFormattedMessage id="form.button.cancel" />
            </Button>
          </div>
          <div className="modalFiltersCtaWrapper">
            <Button
              type="reset"
              className="btn btnLink mr3"
              data-qa="resetFilters"
            >
              <DynamicFormattedMessage id="form.button.resetFilters" />
            </Button>
            <Button
              type="submit"
              className="btn btnPrimary textUppercase"
              data-qa="submitAdvancedFilters"
            >
              <DynamicFormattedMessage id="form.button.submit" />
            </Button>
          </div>
        </div>
      </form>
    </div>
  )
}
