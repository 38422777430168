import React, { FC } from 'react'
import DynamicFormattedMessage from '../../../common/ui/DynamicFormattedMessage'
import {
  IVisitReportInformation,
  IVisitReportSection,
} from '../../../../interfaces/IVisitReports'
import { VisitReportSectionRow } from './VisitReportSectionRow'

export const VisitReportSection: FC<IVisitReportSection> = ({
  section,
  rows,
  contractTypes,
  defaultData,
  camera,
  isCameraOpen,
  updateForm,
  informationData,
  children,
}) => {
  return (
    <section
      key={section}
      className="visitReportSection dFlex flexColumn customBorderTop textPrimary mb2"
    >
      <DynamicFormattedMessage
        id={`form.section.${section}.title.label`}
        tag="h3"
        className="wFull textUppercase mb15 pt1"
      />
      {children}
      {rows.map((item, index) => (
        <VisitReportSectionRow
          key={`row${index + 1}`}
          contractTypes={contractTypes}
          data={item}
          camera={camera}
          isCameraOpen={isCameraOpen}
          defaultData={defaultData}
          updateForm={updateForm}
          informationData={informationData as IVisitReportInformation}
        />
      ))}
    </section>
  )
}
