import React from 'react'
import ReactTooltip from 'react-tooltip'
import { useIntl } from 'react-intl'
import { ReactComponent as IconInfo } from '../../../assets/images/iconInfo.svg'
import { PRIMARY_COLOR, SECONDARY_COLOR } from '../../../constants/colors'

export const InfoIconWithTooltip = ({
  tooltipLabel,
  customClass,
  fillColor,
  backgroundColor,
  tooltipId,
}: {
  tooltipLabel: string
  customClass?: string
  fillColor?: string
  backgroundColor?: string
  tooltipId?: string
}) => {
  const intl = useIntl()

  return (
    <div className={`tooltipInfo dInlineBlock ${customClass}`}>
      <IconInfo
        stroke="currentColor"
        fill={fillColor ?? PRIMARY_COLOR}
        className="ml05"
        data-tip={intl.formatMessage({
          id: tooltipLabel,
        })}
        data-for={tooltipId}
      />
      <ReactTooltip
        id={tooltipId}
        className="fontMediumSize"
        type="info"
        effect="solid"
        backgroundColor={backgroundColor ?? SECONDARY_COLOR}
      />
    </div>
  )
}
