import { Dispatch, SetStateAction, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { FILE_TYPES_BY_SEND_EMAIL_API_TYPE, STATUS } from '../../enums/common'
import { statusNetworkSelector } from '../../store/selectors'
import { statusNetworkReducerActions } from '../../store/reducers/statusNetworkReducer'
import { TApiResponse } from '../../interfaces/IClientDetails'
import {
  handleNewContractAddToIndexedDb,
  sendEmail,
} from '../../services/contractsServices'
import { prepareManualSendEmailRequestBody } from '../../utils/contracts'
import { TStatus } from '../../interfaces/INavigation'
import { API_PATHS } from '../../enums/apiPaths'
import { TSendEmailData } from '../../interfaces/ICommonComponents'
import { getFileExtension } from '../../utils/helpers'

export const useSendEmailForm = (
  data: TSendEmailData,
  setIsAreaOpen: Dispatch<SetStateAction<boolean>>,
  setAvailableEmails: Dispatch<SetStateAction<string[]>>,
  sendEmailType: number,
  inputEmailKey?: string
) => {
  const intl = useIntl()
  const form = useForm({ mode: 'onChange' })
  const { reset, setValue } = form
  const [emailFormState, setEmailFormState] = useState<TApiResponse>({
    status: STATUS.IDLE,
    message: '',
  })
  const dispatch = useDispatch()
  const { hasNetwork } = useSelector(
    statusNetworkSelector.getStatusNetworkValue
  )

  const resetForm = (status: TStatus, message: string) => {
    reset()
    setValue('email', '')
    setIsAreaOpen(false)
    setEmailFormState({
      status,
      message,
    })
  }

  const handleSendEmailFail = () => {
    setEmailFormState({
      status: STATUS.DANGER,
      message: intl.formatMessage(
        {
          id: `form.file.${STATUS.DANGER}`,
        },
        {
          intlAdditionalData: getFileExtension(
            FILE_TYPES_BY_SEND_EMAIL_API_TYPE[sendEmailType] as string
          ),
        }
      ),
    })
    setIsAreaOpen(false)
  }

  const onSubmit = async (values: any) => {
    setEmailFormState({ status: STATUS.PENDING, message: '' })

    const emailData: TSendEmailData = prepareManualSendEmailRequestBody(
      data,
      values.email,
      inputEmailKey
    )

    if (!hasNetwork && data.timestamp) {
      resetForm(STATUS.IDLE, '')
      dispatch(statusNetworkReducerActions.submitedForm())
      // add in storage only when the email was sent for a new offline created contract
      if (data.timestamp) {
        handleNewContractAddToIndexedDb(
          emailData,
          'new-contract-emails',
          data.timestamp,
          `${data.timestamp}-${Date.now()}`,
          API_PATHS.sendEmail
        )
      }
      return
    }

    try {
      const response = await sendEmail(emailData)

      if (response.body.success) {
        setAvailableEmails([data.kamEmail, data.dcrEmail, values.email])
        resetForm(STATUS.SUCCESS, '')

        return
      }

      handleSendEmailFail()
    } catch (error) {
      if (!hasNetwork) {
        resetForm(STATUS.IDLE, '')
        dispatch(statusNetworkReducerActions.submitedForm())
      } else {
        handleSendEmailFail()
      }
    }
  }

  return { form, onSubmit, emailFormState }
}
