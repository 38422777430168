import { OptionsType } from 'react-select'
import { IRequest } from '../interfaces/IRequest'
import { api } from '../utils/api'
import { IClientDetails } from '../interfaces/IClientDetails'
import {
  IAppointmentDefault,
  IAppointmentFormParams,
  IAppointmentList,
  IArrayOptions,
  TAppointmentAction,
} from '../interfaces/IAppointments'
import {
  prepareAppointmentDetailsResponse,
  prepareAppointmentTypesOptions,
  prepareOptionsForContactPersons,
  createIntervalQuery,
} from '../utils/appointments'
import { IUsersOptions } from '../interfaces/IUsers'
import { API_PATHS } from '../enums/apiPaths'
import { ISelectOption } from '../interfaces/IFilters'
import { CONTENT_TYPE_APP_JSON } from '../constants/api'

export async function getUserAppoinments(
  userId?: number | undefined
): Promise<IAppointmentList[]> {
  if (!userId) {
    return []
  }

  const query = createIntervalQuery()
  const params: IRequest = {
    endpoint: `${API_PATHS.users}/${userId}${API_PATHS.appointments}${query}`,
  }

  const {
    body: { data },
  } = await api(params)

  return data
}

export async function getAppointmentTypes(): Promise<IArrayOptions[]> {
  const params: IRequest = {
    endpoint: `${API_PATHS.appointmentTypes}`,
  }

  const {
    body: { data },
  } = await api(params)

  return prepareAppointmentTypesOptions(data)
}

export async function getContactPersons(
  clientId?: number
): Promise<IUsersOptions[]> {
  if (!clientId) {
    return []
  }

  const params: IRequest = {
    endpoint: `${API_PATHS.clients}/${clientId}${API_PATHS.contactPersons}`,
  }

  const {
    body: { data },
  } = await api(params)

  return prepareOptionsForContactPersons(data)
}

export const getClientsListForSelect = async (
  searchParam: string
): Promise<IClientDetails[]> => {
  let query = ''
  if (searchParam) {
    query = `?filters[search][]=${searchParam}`
  }

  const params: IRequest = {
    endpoint: `${API_PATHS.clients}${query}`,
  }

  const {
    body: { data },
  }: any = await api(params)

  return data
}

export const createNewAppointment = (formData: IAppointmentFormParams) => {
  const params: IRequest = {
    endpoint: `${API_PATHS.appointments}`,
    method: 'POST',
    body: JSON.stringify(formData),
  }

  return api(params)
}

export async function getAppointmentDetails(
  id: number,
  action: TAppointmentAction
): Promise<IAppointmentDefault> {
  if (!id) {
    return {} as IAppointmentDefault
  }

  const query = `?action=${action}`
  const params: IRequest = {
    endpoint: `${API_PATHS.appointments}/${id}${query}`,
  }

  const { body } = await api(params)

  return prepareAppointmentDetailsResponse(body)
}

export const editAppointment = (
  formData: IAppointmentFormParams,
  id: number
) => {
  const params: IRequest = {
    endpoint: `${API_PATHS.appointments}/${id}`,
    method: 'PATCH',
    body: JSON.stringify(formData),
  }

  return api(params)
}

export const searchApi = (
  query: string,
  callBack: (parameter: OptionsType<ISelectOption>) => void
) => {
  getClientsListForSelect(query)
    .then((response: IClientDetails[]) => {
      callBack(
        response.map(({ name, id, cipCode }: IClientDetails) => {
          const cipCodeLabel = `(${cipCode})`
          return {
            label: `${name} ${cipCode ? cipCodeLabel : ''}`,
            value: id.toString(),
          }
        })
      )
    })
    .catch(() => callBack([]))
}

export const deleteAppointment = (id: number) => {
  const params: IRequest = {
    endpoint: `${API_PATHS.appointments}/${id}`,
    method: 'DELETE',
  }

  return api(params)
}

export const downloadAppointmentIcs = (
  appointmentId: number,
  clientPageUrl: string
) => {
  const query = `?clientPageUrl=${clientPageUrl}`
  const params: IRequest = {
    endpoint: `${API_PATHS.appointments}/${appointmentId}/ics${query}`,
  }

  return api(params, CONTENT_TYPE_APP_JSON, true)
}
