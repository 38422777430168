import React, { FC } from 'react'
import {
  IClientFileItemData,
  IClientTitulaireData,
} from '../../interfaces/IClientDetails'
import ClientCipCode from '../clientCipcode/ClientCipcode'
import LinkTo from '../common/ui/LinkTo'
import { EMAIL, MAP, PHONE } from '../../enums/common'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'
import { convertYesNoAnswer } from '../../utils/data'
import { CLIENT_FIELDS } from '../../constants/userData'
import { joinData } from '../../utils/helpers'
import {
  FILTER_SUBSCRIBE_COACH_FIELD,
  FILTER_SUBSCRIBE_COMPTOIR_FIELD,
} from '../../constants/filters'

const ClientFileItemData: FC<IClientFileItemData> = ({
  clientDetailsData,
  item,
}) => {
  if (item === 'phoneNumber')
    return (
      <LinkTo type={PHONE} hrefContent={clientDetailsData[item]}>
        {clientDetailsData[item]}
      </LinkTo>
    )

  if (item === 'email')
    return (
      <LinkTo type={EMAIL} hrefContent={clientDetailsData[item]}>
        {clientDetailsData[item]}
      </LinkTo>
    )

  if (item === 'address1') {
    const hasCoordinates =
      clientDetailsData.gpsLatitude && clientDetailsData.gpsLongitude
    const clientLocation = `${clientDetailsData.address1}${
      clientDetailsData.zipCode ? `, ${clientDetailsData.zipCode}` : ''
    }${clientDetailsData.city ? `, ${clientDetailsData.city}` : ''}`

    return (
      <LinkTo
        type={MAP}
        hrefContent={
          hasCoordinates
            ? `${clientDetailsData.gpsLatitude},${clientDetailsData.gpsLongitude}`
            : clientLocation
        }
      >
        {clientDetailsData[item]}
      </LinkTo>
    )
  }

  if (item === 'capping') {
    return <p>C{clientDetailsData[item] || '0'}</p>
  }

  if (item === 'decile') {
    return <p>{clientDetailsData[item] || '0'}</p>
  }

  if (
    (item === FILTER_SUBSCRIBE_COMPTOIR_FIELD ||
      item === FILTER_SUBSCRIBE_COACH_FIELD) &&
    clientDetailsData[item] !== null
  ) {
    return (
      <DynamicFormattedMessage
        tag="p"
        id={`radioButton.${convertYesNoAnswer(clientDetailsData, item)}.label`}
      />
    )
  }

  if (item === 'cipCode')
    return (
      <ClientCipCode
        hasParenthesis={[false, false]}
        cipCode={clientDetailsData[item]}
      />
    )

  if (item === CLIENT_FIELDS.titulaire && clientDetailsData[item]) {
    const {
      civiliteTitulaire,
      prenomTitulaire,
      nomTitulaire,
    } = clientDetailsData[item] as IClientTitulaireData

    return (
      <p>
        {joinData([civiliteTitulaire!, nomTitulaire!, prenomTitulaire!], ' ') ||
          '-'}
      </p>
    )
  }

  return <p>{clientDetailsData[item] || '-'}</p>
}

export default ClientFileItemData
