import React, { FC } from 'react'
import Button from '../../common/ui/Button'
import DynamicFormattedMessage from '../../common/ui/DynamicFormattedMessage'

const ImageUploadButton: FC<{
  isSimpleUploadButton: boolean
  isDisabled: boolean
  onImageUpload: () => void
}> = ({ isSimpleUploadButton, isDisabled, onImageUpload }) => {
  return isSimpleUploadButton ? (
    <Button
      className={`btn btnPrimary btnUpload btnUploadImage ${
        isDisabled ? 'btnDisabled' : ''
      }`}
      onClick={onImageUpload}
      disabled={isDisabled}
    />
  ) : (
    <DynamicFormattedMessage
      id="upload.button.label"
      tag={Button}
      className={`btn btnPrimary btnUploadRegular btnUploadImage mr15 ${
        isDisabled ? 'btnDisabled' : ''
      }`}
      onClick={onImageUpload}
      disabled={isDisabled}
    />
  )
}

export default ImageUploadButton
