import { useForm } from 'react-hook-form'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import set from 'lodash.set'
import { generatePath, useHistory } from 'react-router-dom'
import {
  EXTERNAL_STORAGE_UPLOAD_FAILED_MESSAGE,
  INITIAL_ACTIVE_PAGE,
  INITIAL_OFFSET,
  SEND_EMAIL_API_TYPE_CONTRACT_PDF,
  STATUS,
} from '../../enums/common'
import {
  IContractForm,
  IEmailData,
  TCreateContractResponse,
} from '../../interfaces/IContracts'
import { IUsersOptions } from '../../interfaces/IUsers'
import {
  prepareContractRequestBody,
  prepareSendEmailRequestBody,
} from '../../utils/contracts'
import {
  createNewContract,
  handleNewContractAddToIndexedDb,
} from '../../services/contractsServices'
import { statusNetworkSelector } from '../../store/selectors'
import { statusNetworkReducerActions } from '../../store/reducers/statusNetworkReducer'
import { clientContractsSendEmailActions } from '../../store/reducers/clientContractsSendEmailReducer'
import { clientContractsActions } from '../../store/reducers/clientContractsReducer'
import { API_PATHS } from '../../enums/apiPaths'
import { ROUTES } from '../../enums/routes'
import { getFormattedString } from '../../utils/helpers'
import { handleError } from '../../utils/api'
import { ResponseError } from '../../models/errors/ResponseError'

export const useCreateContractForm = (
  data: IContractForm,
  clientId: string,
  contractId: string,
  contactPersons: IUsersOptions[],
  newContactPerson: IUsersOptions,
  setIsModalOpen: (value: boolean) => void,
  setIsContactSectionOpen: (value: boolean) => void,
  emailDanger?: boolean
) => {
  const intl = useIntl()
  const form = useForm()
  const [formState, setFormState] = useState<TCreateContractResponse>({
    status: STATUS.IDLE,
    message: '',
    messageCode: '',
  })
  const dispatch = useDispatch()
  const history = useHistory()
  const { hasNetwork } = useSelector(
    statusNetworkSelector.getStatusNetworkValue
  )

  const onSubmit = async (values: any) => {
    setFormState({ status: STATUS.PENDING, message: '' })
    setIsContactSectionOpen(false)

    const bodyField = prepareContractRequestBody(
      values,
      data,
      clientId,
      contactPersons,
      newContactPerson
    )
    const emailData: IEmailData = prepareSendEmailRequestBody(
      values?.emailCheckbox,
      data,
      SEND_EMAIL_API_TYPE_CONTRACT_PDF
    )
    const timestamp = Date.now()
    const returnToPreviousPage = () =>
      window.history.state
        ? window.history.back()
        : history.replace(
            generatePath(ROUTES.clientEngagements, {
              clientId,
              contractId,
              contractLabel: getFormattedString((data as IContractForm).label),
            }),
            {
              offset: INITIAL_OFFSET,
              currentPage: INITIAL_ACTIVE_PAGE,
            }
          )

    try {
      const { body } = await createNewContract({
        ...bodyField,
        createdAt: new Date().toISOString(),
      })

      if (!emailDanger && hasNetwork) {
        dispatch(
          clientContractsSendEmailActions.setContractsSendEmailValues({
            ...emailData,
            entityId: body?.contractId,
          })
        )
      }

      setIsModalOpen(false)
      setFormState({ status: STATUS.SUCCESS, message: '' })
      setTimeout(() => {
        return returnToPreviousPage()
      }, 2000)
    } catch (error) {
      if (!hasNetwork) {
        dispatch(
          clientContractsActions.addClientContractsPerType(
            set({}, `${clientId}.contracts.${contractId}.data`, [
              {
                id: '',
                clientName: data.client.clientName,
                cipCode: data.client.cipCode,
                creationDate: Date.now(),
                dcrEmail: data.client.dcrEmail,
                kamEmail: data.client.kamEmail,
                deleted: false,
                timestamp,
                contractDetailsFields: data.contractDetailsFields,
                fields: data.fields,
              },
            ])
          )
        )
        handleNewContractAddToIndexedDb(
          bodyField,
          'new-contract-background-sync',
          timestamp,
          timestamp,
          API_PATHS.contracts
        )

        if (!emailDanger) {
          handleNewContractAddToIndexedDb(
            emailData,
            'new-contract-emails',
            timestamp,
            timestamp,
            API_PATHS.sendEmail
          )
        }

        setIsModalOpen(false)
        dispatch(statusNetworkReducerActions.submitedForm())
        return returnToPreviousPage()
      }

      const handledError = handleError(error, intl)
      setFormState(handledError)

      if (
        error instanceof ResponseError &&
        error.hasMessageCode(EXTERNAL_STORAGE_UPLOAD_FAILED_MESSAGE)
      ) {
        setIsModalOpen(false)
        setTimeout(() => {
          return returnToPreviousPage()
        }, 5000)
      }
    }
  }

  return { form, onSubmit, formState }
}
