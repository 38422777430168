import React, { FC } from 'react'
import { IContractDetailsFieldData } from '../../interfaces/IContracts'
import { TextInputField } from './contractDetailsFields/TextInputField'
import { GroupedInputField } from './contractDetailsFields/GroupedInputField'
import { RangeInputField } from './contractDetailsFields/RangeInputField'
import {
  ALPHA_NUMERIC_REGEX,
  CONTRACT_DETAILS_GROUPED_INPUT,
  CONTRACT_DETAILS_RANGE_INPUT,
  CONTRACT_DETAILS_TEXT_INPUT,
} from '../../constants/form'

export const ContractDetailsField: FC<IContractDetailsFieldData> = ({
  field,
  register,
  errors,
}) => {
  switch (field.type) {
    case CONTRACT_DETAILS_TEXT_INPUT:
      return (
        <TextInputField
          field={field}
          register={register}
          errors={errors}
          validation={{ value: ALPHA_NUMERIC_REGEX, message: 'alphaNumeric' }}
        />
      )
    case CONTRACT_DETAILS_GROUPED_INPUT:
      return (
        <GroupedInputField field={field} register={register} errors={errors} />
      )
    case CONTRACT_DETAILS_RANGE_INPUT:
      return (
        <RangeInputField field={field} register={register} errors={errors} />
      )
    default:
      return (
        <TextInputField field={field} register={register} errors={errors} />
      )
  }
}
