import { IRequest } from '../interfaces/IRequest'
import { api, getAuthHeader } from '../utils/api'
import { API_PATHS } from '../enums/apiPaths'
import { IAppointmentsMap } from '../interfaces/IClientDetails'

export function getMapAppointments(
  date: string
): Promise<{
  body: { data: IAppointmentsMap[] }
}> {
  const params: IRequest = {
    endpoint: `${API_PATHS.appointments}${API_PATHS.appointmentMap}?date=${date}`,
    method: 'GET',
    headers: getAuthHeader(),
  }

  return api(params)
}
