import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useHistory, generatePath } from 'react-router-dom'
import { useState, Dispatch, SetStateAction } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  IVisitReportForm,
  TVisitReportResponse,
} from '../../interfaces/IVisitReports'
import {
  prepareRequestForNewVisitReport,
  prepareRequestForUploadImage,
  resetFieldsWithParentUnchecked,
  resetVisitReportFields,
} from '../../utils/visitReports'
import {
  createNewVisitReport,
  uploadImage,
} from '../../services/visitReportsService'
import {
  API_VERSION,
  BASE_BACKEND_URL,
  EXTERNAL_STORAGE_UPLOAD_FAILED_MESSAGE,
  STATUS,
} from '../../enums/common'
import { newVisitReportActions } from '../../store/reducers/newVisitReportReducer'
import { mockNewVisitReportFormInitialState } from '../../__mocks__/dataMock'
import { ROUTES } from '../../enums/routes'
import { statusNetworkReducerActions } from '../../store/reducers/statusNetworkReducer'
import { statusNetworkSelector } from '../../store/selectors'
import { addDataInIndexedDb } from '../../utils/offline'
import { API_PATHS } from '../../enums/apiPaths'
import {
  IMAGE_UPLOAD_DEFAULT_ERROR_MESSAGE,
  VISIT_ACTION_TYPE_3,
  VISIT_ACTION_TYPE_ID,
  VISIT_REPORT_IMAGES,
} from '../../constants/visitReport'
import { handleError } from '../../utils/api'
import { ResponseError } from '../../models/errors/ResponseError'

export const useVisitReportFormHook = (
  clientId: number,
  setVisitDate: any,
  setIsModalOpen: Dispatch<SetStateAction<boolean>>,
  state: Location
) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const history = useHistory()
  const form = useForm<IVisitReportForm>({ mode: 'onChange' })
  const [formState, setFormState] = useState<TVisitReportResponse>({
    status: STATUS.IDLE,
    message: '',
    messageCode: '',
  })
  const { hasNetwork } = useSelector(
    statusNetworkSelector.getStatusNetworkValue
  )

  const { reset, setValue, getValues } = form
  const isDisabled = getValues(VISIT_ACTION_TYPE_ID) === VISIT_ACTION_TYPE_3

  const resetFormToInitialState = () => {
    // remove all data from form if visit report call succeed
    resetVisitReportFields(reset, setVisitDate, setValue)

    dispatch(
      newVisitReportActions.setNewVisitReport(
        mockNewVisitReportFormInitialState
      )
    )
  }

  const returnToPreviousPage = () =>
    window.history.state
      ? history.go(-1)
      : history.replace(generatePath(ROUTES.clientDetails, { clientId }))

  const handleImagesAddToIndexedDb = (
    values: IVisitReportForm,
    timestamp: Date
  ) => {
    VISIT_REPORT_IMAGES.map(async (type: string, index) => {
      const data = prepareRequestForUploadImage(values, type)
      if (!data) return

      const imageKey = +timestamp.getTime() + (index + 1)

      addDataInIndexedDb(
        'visit-report-images',
        {
          image: data,
          timestamp: timestamp.getTime(),
          requestData: {
            url: `${BASE_BACKEND_URL}${API_PATHS.file}${API_PATHS.upload}`,
            method: 'POST',
          },
          imageKey,
        },
        () => {},
        imageKey
      )
    })
  }

  const handleVisitReportAddToIndexedDb = (
    visitReportFormData: any,
    timestamp: Date
  ) => {
    const postData = {
      ...visitReportFormData,
      clientId,
    }
    addDataInIndexedDb(
      'visit-report-background-sync',
      {
        data: postData,
        timestamp: timestamp.getTime(),
        requestData: {
          url: `${BASE_BACKEND_URL}${API_VERSION}${API_PATHS.visitReport}`,
          method: 'POST',
        },
      },
      () => {},
      +timestamp.getTime()
    )
  }

  const uploadImagesRequest = async (
    values: IVisitReportForm,
    reportId: number
  ) => {
    try {
      await Promise.all(
        VISIT_REPORT_IMAGES.map(async (type: string) => {
          const data = prepareRequestForUploadImage(values, type)
          if (!data) return

          await uploadImage({
            entityId: reportId.toString(),
            ...data,
          })
        })
      ).then(() => {
        resetFormToInitialState()
      })
    } catch (error) {
      const isExternalStorageUploadFailed =
        error instanceof ResponseError &&
        error.hasMessageCode(EXTERNAL_STORAGE_UPLOAD_FAILED_MESSAGE)
      const messageCode = isExternalStorageUploadFailed
        ? EXTERNAL_STORAGE_UPLOAD_FAILED_MESSAGE
        : IMAGE_UPLOAD_DEFAULT_ERROR_MESSAGE

      setIsModalOpen(false)
      resetFormToInitialState()
      return history.replace(
        {
          pathname: generatePath(ROUTES.clientDetails, { clientId }),
        },
        {
          ...state,
          uploadImageState: true,
          messageCode,
        }
      )
    }
  }

  const onSubmit = async (submitValues: IVisitReportForm) => {
    setIsModalOpen(false)
    resetFieldsWithParentUnchecked(submitValues, setValue)

    const values = getValues()
    const visitReportFormData = prepareRequestForNewVisitReport(values)

    const visitReportFinalData = {
      ...visitReportFormData,
      formations: submitValues.formations,
    }
    const valuesWithFormations = {
      ...values,
      formations: submitValues.formations,
    }
    setFormState({ status: STATUS.PENDING, message: '' })

    if (!hasNetwork) {
      const timestamp = new Date()
      handleVisitReportAddToIndexedDb(visitReportFinalData, timestamp)

      if (!isDisabled) {
        handleImagesAddToIndexedDb(
          valuesWithFormations as IVisitReportForm,
          timestamp
        )
      }
    }

    try {
      const reportId = await createNewVisitReport({
        ...visitReportFinalData,
        clientId,
      })

      if (!isDisabled) {
        await uploadImagesRequest(valuesWithFormations, reportId)
      }

      resetFormToInitialState()
      setIsModalOpen(false)
      setFormState({ status: STATUS.SUCCESS, message: '' })
      return returnToPreviousPage()
    } catch (error) {
      if (!hasNetwork) {
        resetFormToInitialState()
        setIsModalOpen(false)
        dispatch(statusNetworkReducerActions.submitedForm())
        return returnToPreviousPage()
      }

      const handledError = handleError(error, intl)
      setFormState(handledError)
    }
  }

  return { form, onSubmit, formState }
}
