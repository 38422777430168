import React, { ReactElement } from 'react'
import { FieldError } from 'react-hook-form'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'

/**
 * Used to display error message for components that doesn't do that by default (ex. radio button).
 */
function ErrorMessage({
  error,
  className,
}: {
  error?: FieldError | undefined
  className?: string
}): ReactElement {
  return (
    <>
      {error && (
        <DynamicFormattedMessage
          tag="span"
          id={`form.validation.${
            ['pattern', 'validate'].includes(error.type)
              ? error.message
              : error.message || error.type
          }`}
          className={`errorMessage ${className}`}
        />
      )}
    </>
  )
}

export default ErrorMessage
