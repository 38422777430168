import React from 'react'
import { format } from 'date-fns'
import { REPORT_DATETIME_FORMAT } from '../../../../enums/common'
import { ROUTES } from '../../../../enums/routes'
import { InformationDataRow } from './InformationDataRow'
import { VisitReportNewTabButton } from '../commonComponents/VisitReportNewTabButton'

export const InformationFutureRdv = ({
  rdvDate,
  renderRdvButton,
}: {
  rdvDate: string
  renderRdvButton: boolean
}) => {
  return (
    <InformationDataRow label="date" customClass="mb2 alignItemsCenter">
      <p className="px15 mr2">
        {(rdvDate && format(new Date(rdvDate), REPORT_DATETIME_FORMAT)) || '-'}
      </p>
      {renderRdvButton && (
        <VisitReportNewTabButton
          name="newAppointment"
          route={ROUTES.newAppointmentAgenda}
        />
      )}
    </InformationDataRow>
  )
}
