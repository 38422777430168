import React, { FC } from 'react'
import { IContractDetailsFieldData } from '../../../interfaces/IContracts'
import DynamicFormattedMessage from '../../common/ui/DynamicFormattedMessage'
import TextInput from '../../form/TextInput'
import { CONTRACT_DETAILS_TEXT_MAX_LENGTH } from '../../../constants/form'

export const GroupedInputField: FC<IContractDetailsFieldData> = ({
  field,
  register,
  errors,
}) => {
  const { name, items, mandatory } = field

  const hasError = items?.some((item) => !!errors[item.name])

  return (
    <div
      className={`row dFlex alignItemsCenter justifyContentLgStart groupedInput ${
        hasError ? 'mb2xl mb2lg ' : ''
      }`}
    >
      <div className="colXl2 colLg12 colMd12 px15 py0 groupedInputLabel">
        <DynamicFormattedMessage
          id={`form.field.${name}.label`}
          tag="h4"
          className={`inputLabel pb05 ${mandatory ? 'required' : ''}`}
        />
      </div>
      {items?.map(
        ({
          id: idItem,
          name: nameItem,
          placeholder: placeholderItem,
          label: labelItem,
        }) => (
          <TextInput
            name={nameItem}
            id={idItem.toString()}
            key={idItem}
            customClass="colXl5 colLg6 colMd12"
            register={register({
              required: mandatory,
              maxLength: CONTRACT_DETAILS_TEXT_MAX_LENGTH,
            })}
            error={errors[nameItem]}
            placeholder={placeholderItem}
            hasLabel={!!labelItem}
          />
        )
      )}
    </div>
  )
}
