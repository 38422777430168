import React, { lazy, FC, Suspense, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useClientVisitReport } from '../../hooks/clientVisitReport/UseClientVisitReport'
import Loader from '../../components/loader/Loader'
import { VISIT_REPORT_VERSIONS } from '../../enums/common'

const ViewVisitReportVersioning: FC = () => {
  const { reportId, clientId } = useParams()
  const { status, message, visitReportData } = useClientVisitReport(reportId)
  const { version } = visitReportData
  const versionDirectory = VISIT_REPORT_VERSIONS[version]
  const ViewVisitReport = useMemo(
    () =>
      lazy(
        () =>
          import(
            `../../components/clientVisitReport/${versionDirectory}/ViewVisitReport`
          )
      ),
    [versionDirectory]
  )

  return (
    <Suspense fallback={<Loader />}>
      <ViewVisitReport
        visitReportData={visitReportData}
        clientId={clientId}
        status={status}
        message={message}
      />
    </Suspense>
  )
}

export default ViewVisitReportVersioning
