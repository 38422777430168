import React, { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { VISIT_DOCUMENTS_TYPE_2 } from '../../../../constants/visitReport'
import TextInput from '../../../form/TextInput'
import {
  IVisitReport,
  IVisitReportForm,
} from '../../../../interfaces/IVisitReports'
import { useIsTabDisabled } from '../../../../hooks/clientVisitReport/UseIsTabDisabled'
import { validateTextInput } from '../../../../utils/visitReports'
import DynamicFormattedMessage from '../../../common/ui/DynamicFormattedMessage'
import { getNWord } from '../../../../utils/helpers'

export const VisitReportExtraFields = ({
  fields,
  defaultData,
  dataType = '',
}: {
  fields: (keyof IVisitReport)[]
  defaultData: IVisitReportForm
  dataType?: string
}) => {
  const isDisabled = useIsTabDisabled()
  const { register, errors, watch } = useFormContext()
  const watchedFields = watch([VISIT_DOCUMENTS_TYPE_2])

  const isVisible = useMemo(() => !!watchedFields[VISIT_DOCUMENTS_TYPE_2], [
    watchedFields,
  ])

  return (
    <section>
      <div className="mb2">
        <DynamicFormattedMessage
          tag="p"
          id={`form.visitReport.${getNWord(fields[0], 3).toLowerCase()}.label`}
          className="textXs fontBold"
        />
      </div>
      <div className="row col12 mx0">
        {fields?.map((field) => (
          <TextInput
            key={field}
            dataType={dataType}
            name={field}
            id={field}
            register={register({
              required: false,
              validate: (value) =>
                validateTextInput(value, isVisible && !isDisabled, 50),
            })}
            error={errors[field]}
            placeholder="typeIn"
            defaultValue={
              defaultData[field as keyof IVisitReportForm] as string
            }
            customClass="mr15 textXs"
            labelText={`visitReport.${getNWord(field, 3)
              .replace(/[0-9]/g, '')
              .toLowerCase()}`}
          />
        ))}
      </div>
    </section>
  )
}
