import React, { FC } from 'react'
import Alerts from '../../alerts/Alerts'
import { IChartVisitsCoverage } from '../../../interfaces/IDashboard'
import { useDashboardVisitsCoverageChart } from '../../../hooks/dashboard/dashboardVisitsCoverage/UseDashboardVisitsCoverageChart'
import { PieChart } from '../charts/PieChart'

export const ChartSection: FC<IChartVisitsCoverage> = ({
  chartDataResponse,
  selectedUserName,
  targetType = '',
  customClass = '',
}) => {
  const {
    title,
    subtitle,
    subtitleDetails,
    labels,
    chartData,
    shouldChartRender,
  } = useDashboardVisitsCoverageChart(
    selectedUserName,
    chartDataResponse,
    targetType!
  )

  const chartOptions = {
    title,
    subtitle: [subtitle, subtitleDetails],
    labels,
    chartData,
    shouldChartRender,
  }

  return (
    <div className={`chartWrapper ${customClass}`}>
      <PieChart {...chartOptions} />
      <Alerts
        status={chartDataResponse.status}
        message={chartDataResponse.message}
      />
    </div>
  )
}
