import React from 'react'
import { useSelector } from 'react-redux'
import Button from '../../../common/ui/Button'
import { ROUTES } from '../../../../enums/routes'
import DynamicFormattedMessage from '../../../common/ui/DynamicFormattedMessage'
import { InfoIconWithTooltip } from '../../../common/Tooltip/InfoIconWithTooltip'
import { statusNetworkSelector } from '../../../../store/selectors'
import { capitaliseString } from '../../../../utils/helpers'

export const VisitReportNewTabButton = ({
  name,
  route = ROUTES.newAppointmentAgenda,
  className = 'col',
}: {
  name: string
  route: string
  className?: string
}) => {
  const { hasNetwork } = useSelector(
    statusNetworkSelector.getStatusNetworkValue
  )

  return (
    <div className={className}>
      <Button
        className={`btn btnPrimary textCenter linkUnstyled ${
          !hasNetwork ? 'btnDisabled' : ''
        }`}
        data-qa={`cta${capitaliseString(name)}`}
        onClick={() => window.open(route)}
        disabled={!hasNetwork}
      >
        <DynamicFormattedMessage id={`cta.${name}.label`} />
      </Button>
      {!hasNetwork && (
        <InfoIconWithTooltip
          tooltipLabel="info.tooltipText.label"
          customClass="py05"
        />
      )}
    </div>
  )
}
