const ENTERED_DATE = 'enteredDate'
const DELIVERY_DATE = 'deliveryDate'
const ORDER_NUMBER = 'orderNumber'
const ORIGIN = 'origin'
const AMOUNT = 'amount'

const MARKET_LABEL = 'marketLabel'
const RANGE = 'range'
const ENGAGEMENT_DATE = 'engagementDate'
const DISCOUNT = 'discount'
const DETAILS = 'details'
const S1 = 's1'
const S2 = 's2'
const Q1 = 'q1'
const Q2 = 'q2'
const Q3 = 'q3'
const T1 = 't1'
const T2 = 't2'
const T3 = 't3'
const T4 = 't4'

export enum VIEW_TYPE {
  TOTAL = 'total',
  SEMESTER = 'semester',
  TRIMESTER = 'trimester',
  QUARTERLY = 'quarterly',
}

export const PRODUCT_RANGE = 'productRange'
export const QUANTITY_CURRENT_YEAR = 'quantity'
export const FISCAL_VALUE_CURRENT_YEAR = 'fiscalValue'
export const QUANTITY_LAST_YEAR = 'quantityPreviousYear'
export const FISCAL_VALUE_LAST_YEAR = 'fiscalValuePreviousYear'
export const RAF = 'raf'

export const KEY_CURRENT_YEAR = 'currentYear'
export const KEY_PREVIOUS_YEAR = 'previousYear'
export const TYPE_SUMMARY = 'summary'
export const TYPE_PRODUCT = 'product'

export const TRAINING_PROGRAMME = 'trainingProgramme'
export const TRAINING_DATE = 'trainingDate'
export const TRAINING_STATUS = 'trainingStatus'

export const TRAINING_PHASE_0 = 'phase0'
export const TRAINING_PHASE_1 = 'phase1'
export const TRAINING_PHASE_2 = 'phase2'
export const TRAINING_PHASE_3 = 'phase3'
export const TRAINING_PHASE_4 = 'phase4'

export const VIEW_TYPES_LIST = [
  VIEW_TYPE.TOTAL,
  VIEW_TYPE.SEMESTER,
  VIEW_TYPE.QUARTERLY,
  VIEW_TYPE.TRIMESTER,
]

export const ORDER_HISTORY_TABLE_HEADERS = [
  ENTERED_DATE,
  DELIVERY_DATE,
  ORDER_NUMBER,
  ORIGIN,
  AMOUNT,
]

export const ENGAGEMENTS_HISTORY_TABLE_HEADERS = [
  MARKET_LABEL,
  RANGE,
  ENGAGEMENT_DATE,
  DISCOUNT,
  DETAILS,
]

export const ANNUAL_RECAP_TABLE_HEADER = [
  PRODUCT_RANGE,
  QUANTITY_CURRENT_YEAR,
  FISCAL_VALUE_CURRENT_YEAR,
  QUANTITY_LAST_YEAR,
  FISCAL_VALUE_LAST_YEAR,
  RAF,
]

export const PERIOD_RECAP_TABLE_HEADER = [
  QUANTITY_CURRENT_YEAR,
  FISCAL_VALUE_CURRENT_YEAR,
  QUANTITY_LAST_YEAR,
  FISCAL_VALUE_LAST_YEAR,
]

export const SEMESTER_PERIOD = [S1, S2, S1, S2, S1, S2, S1, S2]
export const QUARTER_PERIOD = [Q1, Q2, Q3, Q1, Q2, Q3, Q1, Q2, Q3, Q1, Q2, Q3]
export const TRIMESTER_PERIOD = [
  T1,
  T2,
  T3,
  T4,
  T1,
  T2,
  T3,
  T4,
  T1,
  T2,
  T3,
  T4,
  T1,
  T2,
  T3,
  T4,
]

export const CURRENT_YEAR_JANUARY = 'January'
export const CURRENT_YEAR_FEBRUARY = 'February'
export const CURRENT_YEAR_MARCH = 'March'
export const CURRENT_YEAR_APRIL = 'April'
export const CURRENT_YEAR_MAY = 'May'
export const CURRENT_YEAR_JUNE = 'June'
export const CURRENT_YEAR_JULY = 'July'
export const CURRENT_YEAR_AUGUST = 'August'
export const CURRENT_YEAR_SEPTEMBER = 'September'
export const CURRENT_YEAR_OCTOBER = 'October'
export const CURRENT_YEAR_NOVEMBER = 'November'
export const CURRENT_YEAR_DECEMBER = 'December'
export const NEXT_YEAR_JANUARY = 'nextYear.January'
export const NEXT_YEAR_FEBRUARY = 'nextYear.February'
export const NEXT_YEAR_MARCH = 'nextYear.March'

export const MONTHS_PERIOD = [
  CURRENT_YEAR_JANUARY,
  CURRENT_YEAR_FEBRUARY,
  CURRENT_YEAR_MARCH,
  CURRENT_YEAR_APRIL,
  CURRENT_YEAR_MAY,
  CURRENT_YEAR_JUNE,
  CURRENT_YEAR_JULY,
  CURRENT_YEAR_AUGUST,
  CURRENT_YEAR_SEPTEMBER,
  CURRENT_YEAR_OCTOBER,
  CURRENT_YEAR_NOVEMBER,
  CURRENT_YEAR_DECEMBER,
  NEXT_YEAR_JANUARY,
  NEXT_YEAR_FEBRUARY,
  NEXT_YEAR_MARCH,
]

export const VIEW_TYPES_HEADERS: { [key: string]: string[] } = {
  total: ANNUAL_RECAP_TABLE_HEADER,
  semester: PERIOD_RECAP_TABLE_HEADER,
  trimester: PERIOD_RECAP_TABLE_HEADER,
  quarterly: PERIOD_RECAP_TABLE_HEADER,
}

export const VIEW_TYPES_SUB_HEADERS: { [key: string]: string[] } = {
  total: [],
  semester: SEMESTER_PERIOD,
  trimester: TRIMESTER_PERIOD,
  quarterly: QUARTER_PERIOD,
}

export const COL_SPAN_BY_VIEW: { [key: string]: number } = {
  total: 1,
  semester: 2,
  quarterly: 3,
  trimester: 4,
}

const OFFLINE_NO = 'no'
const OFFLINE_CREATION_DATE = 'creationDate'
const OFFLINE_PAGE_ENDPOINT = 'pageEndpoint'
const OFFLINE_STATUS = 'status'

export const OFFLINE_SYNCHRO_HEADERS = [
  OFFLINE_NO,
  OFFLINE_CREATION_DATE,
  OFFLINE_PAGE_ENDPOINT,
  OFFLINE_STATUS,
]

export const TRAINING_TABLE_HEADERS = [
  TRAINING_PROGRAMME,
  TRAINING_DATE,
  TRAINING_STATUS,
]

export const TRAINING_TABLE_CONTENT = [
  TRAINING_PHASE_0,
  TRAINING_PHASE_1,
  TRAINING_PHASE_2,
  TRAINING_PHASE_3,
  TRAINING_PHASE_4,
]
