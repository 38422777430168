import React, { FC, useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { IClientDetailsData } from '../../../interfaces/IClientDetails'
import TextInput from '../../form/TextInput'
import {
  INPUT_LENGTH,
  INPUT_EXPIRED_PRODUCTS,
  INPUT_DAMAGED_PRODUCTS,
  INPUT_REMISE_MARCHE,
  INPUT_CITY,
  INPUT_DATE_SIGNED_AT,
  INPUT_PHOTO,
  INPUT_SIZE_MIN,
  INPUT_SIZE_MAX,
} from '../../../constants/destructionCertificate'
import { CustomImageUpload } from '../../form/CustomImageUpload'

import { PhotoCapture } from '../../form/PhotoCapture'
import { useUserMedia } from '../../../hooks/UseUserMedia'
import { ImagePlaceholder } from '../../common/ui/ImagePlaceholder'
import { UserMediaSection } from '../../common/ui/UserMediaSection'
import FormGroupDate from '../../form/FormGroupDate'
import {
  COMPRESSED_PHOTO_FILE_NAME,
  DATEPICKER_FORMAT,
} from '../../../enums/common'
import SignatureSection from '../../form/SignatureSection'
import { compressImage } from '../../../utils/imageCompression'
import { MediaImageType } from '../../../interfaces/IForms'
import {
  IMAGE_TYPE_CAMERA,
  IMAGE_TYPE_UPLOAD,
} from '../../../constants/generalData'

const DestructionCertificateSecondSection: FC<{
  clientDetails: IClientDetailsData
}> = ({ clientDetails }) => {
  const [isCameraOpen, setIsCameraOpen] = useState(false)
  const camera = useUserMedia()
  const { photoRef, hasUserMedia, grantedPermission, isVideoPlaying } = camera
  const { register, errors, watch, control, clearErrors } = useFormContext()
  const { city } = clientDetails
  const [compressedImage, setCompressedImage] = useState<string>()
  const [compressStatus, setCompressStatus] = useState<boolean>(false)
  const [imageType, setImageType] = useState<MediaImageType>()

  const watchFields = watch(['photo'])

  const image = useMemo(
    () => watchFields.photo && JSON.parse(watchFields.photo),
    [watchFields]
  )

  const isImgDisabled = useMemo(
    () =>
      imageType === IMAGE_TYPE_UPLOAD ||
      !hasUserMedia ||
      !grantedPermission ||
      !isVideoPlaying,
    [hasUserMedia, grantedPermission, isVideoPlaying, imageType]
  )

  const [signetAt, setSignedAt] = useState({
    startDate: new Date(),
  })

  const signedAtDateInit = {
    id: `${INPUT_DATE_SIGNED_AT}`,
    label: `form.field.${INPUT_DATE_SIGNED_AT}.label`,
    value: signetAt.startDate,
    setValue: setSignedAt,
    classes: 'inputField inputDate datepickerLeft',
    maxDate: new Date(),
    error: errors[INPUT_DATE_SIGNED_AT],
    displayTime: false,
    format: DATEPICKER_FORMAT,
    control,
  }

  const resetImageSelection = () => {
    setCompressedImage(undefined)
    setCompressStatus(false)
    setImageType(undefined)
  }

  useEffect(() => {
    if (!watchFields.photo || compressStatus) return
    compressImage(watchFields.photo, 0.8, 0.8)
      .then((img) => {
        setCompressedImage(
          JSON.stringify({
            dataURL: img as string,
            fileName: COMPRESSED_PHOTO_FILE_NAME,
          })
        )
        setCompressStatus(true)
      })
      .catch(() => {})
  }, [watchFields.photo, compressStatus])

  return (
    <div className="secondSection mt2">
      <div className="row ml0">
        <TextInput
          name={`${INPUT_EXPIRED_PRODUCTS}`}
          id={`${INPUT_EXPIRED_PRODUCTS}`}
          customClass="colMd6 pl0"
          register={register({
            required: true,
            pattern: {
              value: /^[0-9]*$/,
              message: 'number',
            },
            min: {
              value: INPUT_SIZE_MIN[INPUT_EXPIRED_PRODUCTS],
              message: 'destructionCertificate.minNo1',
            },
            max: {
              value: INPUT_SIZE_MAX[INPUT_EXPIRED_PRODUCTS],
              message: 'destructionCertificate.maxNo99',
            },
          })}
          error={errors[INPUT_EXPIRED_PRODUCTS]}
          type="number"
        />
      </div>
      <div className="row ml0">
        <TextInput
          name={`${INPUT_DAMAGED_PRODUCTS}`}
          id={`${INPUT_DAMAGED_PRODUCTS}`}
          customClass="colMd6 pl0"
          register={register({
            required: true,
            pattern: {
              value: /^[0-9]*$/,
              message: 'number',
            },
            min: {
              value: INPUT_SIZE_MIN[INPUT_DAMAGED_PRODUCTS],
              message: 'destructionCertificate.minNo1',
            },
            max: {
              value: INPUT_SIZE_MAX[INPUT_DAMAGED_PRODUCTS],
              message: 'destructionCertificate.maxNo99',
            },
          })}
          error={errors[INPUT_DAMAGED_PRODUCTS]}
          type="number"
        />
      </div>
      <div className="mediaSection">
        <UserMediaSection
          camera={camera}
          isCameraOpen={isCameraOpen}
          setIsCameraOpen={setIsCameraOpen}
        />
        <div className="row ml0">
          <CustomImageUpload
            type="photo"
            key="photo"
            updateForm={() => {
              clearErrors(INPUT_PHOTO)
            }}
            isDisabled={image && imageType === IMAGE_TYPE_CAMERA}
            onUploadCallback={() => {
              setImageType(IMAGE_TYPE_UPLOAD)
            }}
            showUploadBtn={!isImgDisabled}
            btnDeleteClass="mr1"
            onImageDeleteCallback={() => resetImageSelection()}
            showSimpleUploadButton={false}
          />
          <PhotoCapture
            type="photo"
            isCameraOpen={isCameraOpen}
            photoRef={photoRef}
            isDisabled={isImgDisabled}
            onImageUploadCallback={() => {
              setImageType(IMAGE_TYPE_CAMERA)
              clearErrors(INPUT_PHOTO)
            }}
            onImageDeleteCallback={() => resetImageSelection()}
            showSimpleUploadButton={false}
          />
        </div>
        <div className="row ml0">
          <TextInput
            type="hidden"
            name={INPUT_PHOTO}
            id={INPUT_PHOTO}
            customClass="colMd6 pl0"
            register={register({
              required: true,
            })}
            error={errors[INPUT_PHOTO]}
            hasLabel={false}
            defaultValue={compressedImage || watchFields.photo}
          />
        </div>
        {!image?.dataURL ? (
          <div className="colMd6 ml15">
            <ImagePlaceholder className="imagePlaceholder" />
          </div>
        ) : (
          <div className="row colMd12 pl0 ml0">
            <div className="customImageUploadImagePreviewRegular colMd6 pl0">
              <img src={image?.dataURL} alt="Capture Preview" />
            </div>
          </div>
        )}
      </div>
      <div className="row ml0 mt1">
        <TextInput
          name={`${INPUT_REMISE_MARCHE}`}
          id={`${INPUT_REMISE_MARCHE}`}
          customClass="colMd6 pl0"
          register={register({
            required: false,
            maxLength: INPUT_LENGTH[INPUT_REMISE_MARCHE],
          })}
          error={errors[INPUT_REMISE_MARCHE]}
        />
      </div>
      <div className="row ml0">
        <TextInput
          name={`${INPUT_CITY}`}
          id={`${INPUT_CITY}`}
          customClass="colMd6 pl0"
          register={register({
            required: true,
            maxLength: INPUT_LENGTH[INPUT_CITY],
          })}
          defaultValue={city}
          error={errors[INPUT_CITY]}
          labelText="destructionCertificate.city"
        />
      </div>
      <div className="row ml0">
        <FormGroupDate startDate={signedAtDateInit} startDateOnly={true} />
      </div>
      <div className="mt2">
        <SignatureSection label="contractSignature" />
      </div>
    </div>
  )
}

export default DestructionCertificateSecondSection
