import React, { FC, useEffect } from 'react'
import { FormProvider } from 'react-hook-form'
import { EMAIL_REGEX } from '../../../constants/form'
import { STATUS } from '../../../enums/common'
import { useSendEmailForm } from '../../../hooks/clientContracts/UseSendEmailForm'
import {
  IDropdownSection,
  ISendEmailAdditionalData,
  TSendEmailData,
} from '../../../interfaces/ICommonComponents'
import TextInput from '../../form/TextInput'
import Button from './Button'
import DynamicFormattedMessage from './DynamicFormattedMessage'

export const DropdownSection: FC<IDropdownSection> = ({
  entityId,
  sendEmailType,
  sendEmailAdditionalData,
  setIsAreaOpen,
  setSendEmailState,
  setAvailableEmails,
  inputEmailKey,
  timestamp,
}) => {
  const data: TSendEmailData = {
    ...(sendEmailAdditionalData as ISendEmailAdditionalData),
    entityId,
    type: sendEmailType,
    timestamp,
  }
  const { emailFormState, form, onSubmit } = useSendEmailForm(
    data,
    setIsAreaOpen,
    setAvailableEmails,
    sendEmailType,
    inputEmailKey as string
  )
  const { register, errors, handleSubmit, formState, watch } = form
  const watchFields = watch(['email'])
  const isDisabled =
    emailFormState.status === STATUS.PENDING ||
    !formState.isValid ||
    watchFields.email?.length === 0

  useEffect(() => {
    if (setSendEmailState === undefined) return

    setSendEmailState(emailFormState)
  }, [emailFormState, setSendEmailState])

  return (
    <>
      <FormProvider {...form}>
        <form className="sendEmailForm" onSubmit={handleSubmit(onSubmit)}>
          <div className="row flexMdNowrap flexWrap alignItemsStart justifyContentBetween mx0">
            <TextInput
              name="email"
              id="email"
              customClass="mr15"
              register={register({
                required: false,
                pattern: {
                  value: EMAIL_REGEX,
                  message: 'email',
                },
              })}
              error={errors.email}
              placeholder="email"
              hasLabel={false}
            />
            <DynamicFormattedMessage
              id="form.button.sendEmail"
              type="submit"
              tag={Button}
              className={`btn btnPrimary mb15 mx0 px2 py15 ${
                isDisabled ? 'btnDisabled' : ''
              }`}
              data-qa="sendEmail"
              disabled={isDisabled}
            />
          </div>
        </form>
      </FormProvider>
    </>
  )
}
