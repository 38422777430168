import React, { FC, useEffect } from 'react'
import { generatePath } from 'react-router-dom'
import { PageTitleSection } from '../components/common/ui/PageTitleSection'
import Alerts from '../components/alerts/Alerts'
import { STATUS } from '../enums/common'
import Loader from '../components/loader/Loader'
import { ClientDetailsInfo } from '../components/clientDetailsPage/ClientDetailsInfo'
import { ClientDetailsActions } from '../components/clientDetailsPage/ClientDetailsActions'
import { ClientDetailsMap } from '../components/map/ClientDetailsMap'
import { ClientEventsSection } from '../components/clientEventsSection/ClientEventsSection'
import ModalContainer from '../components/modal/Modal'
import { ErrorMessageModal } from '../components/modal/ErrorMessageModal'
import { ROUTES } from '../enums/routes'
import { InfoMessageAlerts } from '../components/alerts/InfoMessageAlerts'
import { capitaliseString, getAlertType } from '../utils/helpers'
import { IMAGE_UPLOAD_DEFAULT_ERROR_MESSAGE } from '../constants/visitReport'
import { StickyWrapper } from '../components/common/ui/StickyWrapper'
import { useClientDetailsPage } from '../hooks/clientDetails/UseClientDetailsPage'
import { getFormations } from '../services/visitReportsService'
import { IFormationResponse } from '../interfaces/IVisitReports'
import { TRAINING_TABLE_CONTENT } from '../constants/tables'
import { Row } from '../interfaces/IClientDetails'

const ClientDetails: FC = () => {
  const {
    history,
    state,
    clientId,
    name,
    cipCode,
    gpsLatitude,
    gpsLongitude,
    status,
    message,
    clientInfo,
    clientLocation,
    clientAppointments,
    clientReports,
    clientLastOrder,
    contactPersons,
    pageTitleProps,
    isClientCached,
    cachedVisitReports,
    cachedAppointments,
    lastClientDetailsData,
    isModalOpen,
    setIsModalOpen,
    hasOnlyOneInfoColumn,
    dataStatusPendingByAction,
  } = useClientDetailsPage()

  const fetchFormations = async () => {
    try {
      const apiResponse = await getFormations(clientId)
      const apiData: IFormationResponse[] = apiResponse.body

      const newRows: Row[] = []
      const phasesInApi = new Map<
        string,
        { partiel: boolean; complete: boolean }
      >()

      apiData.forEach((item) => {
        const phase = item.phase === '5' ? '0' : item.phase
        const phaseStr = phase.toString()

        if (!phasesInApi.has(phaseStr)) {
          phasesInApi.set(phaseStr, { partiel: false, complete: false })
        }

        const phaseInfo = phasesInApi.get(phaseStr)
        if (phaseInfo) {
          if (item.state === 'partiel') {
            phaseInfo.partiel = true
          } else if (item.state === 'complete') {
            phaseInfo.complete = true
          }
        }

        newRows.push({
          id: newRows.length,
          phase,
          label: `${phase}.`,
          date: new Date(item.startDate).toLocaleDateString('en-GB'),
          state: phase === '0' ? '' : item.state,
          checkboxPresent: false,
          checkboxChecked: false,
        })
      })

      TRAINING_TABLE_CONTENT.forEach((_, index) => {
        const phaseStr = index.toString()
        if (phaseStr === '0') {
          newRows.push({
            id: newRows.length,
            phase: phaseStr,
            label: `${phaseStr}.`,
            date: '',
            state: '',
            checkboxPresent: true,
            checkboxChecked: false,
          })
        } else {
          const phaseInfo = phasesInApi.get(phaseStr)
          if (!phaseInfo || !phaseInfo.partiel || !phaseInfo.complete) {
            newRows.push({
              id: newRows.length,
              phase: phaseStr,
              label: `${phaseStr}.`,
              date: '',
              state: '',
              checkboxPresent: true,
              checkboxChecked: false,
            })
          }
        }
      })

      newRows.sort((a, b) => {
        const phaseComparison = parseInt(a.phase, 10) - parseInt(b.phase, 10)
        if (phaseComparison !== 0) return phaseComparison

        if (a.state === 'partiel' && b.state === 'complete') return -1
        if (a.state === 'complete' && b.state === 'partiel') return 1

        if (a.checkboxPresent && !b.checkboxPresent) return 1
        if (!a.checkboxPresent && b.checkboxPresent) return -1

        if (a.phase === '0' && b.phase === '0') {
          const dateA = a.date
            ? new Date(a.date.split('/').reverse().join('-')).getTime()
            : 0
          const dateB = b.date
            ? new Date(b.date.split('/').reverse().join('-')).getTime()
            : 0
          return dateA - dateB
        }

        return 0
      })

      localStorage.setItem('formations', JSON.stringify(newRows))
    } catch (error) {
      console.error('Error fetching formations:', error)
    }
  }

  useEffect(() => {
    fetchFormations()
    // eslint-disable-next-line
  }, [clientId])

  return (
    <section className="pageContentWrapper clientDetailsPage pl75">
      <div className="px3 py3">
        <PageTitleSection
          backRoute={state?.backRoute ?? generatePath(ROUTES.accountsPortfolio)}
          backData={state?.offset}
          {...pageTitleProps}
        />
        <StickyWrapper>
          <Alerts status={status} message={message} />
          {!isClientCached && (
            <InfoMessageAlerts
              type="warning"
              message="network.offline.message.client.dataUncached"
              data={[lastClientDetailsData?.name]}
            />
          )}
        </StickyWrapper>
        {status && status === STATUS.PENDING && <Loader />}
        {name && name.length > 0 && isClientCached && (
          <div className="clientDetailsPageContent">
            <div
              className={`mb15 ${
                hasOnlyOneInfoColumn
                  ? 'clientDetailsPageContentBottom'
                  : 'clientDetailsPageContentTop'
              }`}
            >
              <ClientDetailsInfo
                {...clientInfo}
                contactPerson={contactPersons}
                hasOnlyOneColumn={hasOnlyOneInfoColumn}
              />
              <ClientDetailsActions
                clientId={clientId}
                offset={state?.offset}
                currentPage={state?.currentPage}
                client={{ id: +clientId, name, cipCode }}
                dataStatusPendingByAction={dataStatusPendingByAction}
                fetchFormations={fetchFormations}
              />
            </div>
            <div className="clientDetailsPageContentBottom">
              <ClientEventsSection
                clientAppointments={clientAppointments}
                clientReports={clientReports}
                clientLastOrder={clientLastOrder}
                clientId={clientId}
                cachedClientAppointments={cachedAppointments}
                cachedClientReports={cachedVisitReports}
              />
              <ClientDetailsMap
                clientLatitude={gpsLatitude}
                clientLongitude={gpsLongitude}
                clientAddress={clientLocation}
              />
            </div>
          </div>
        )}
      </div>
      <ModalContainer
        isOpen={(isModalOpen && state?.messageCode !== undefined) || false}
        portalClassName={`errorMessageModalWrapper background${capitaliseString(
          getAlertType(state?.messageCode)
        )}`}
      >
        <ErrorMessageModal
          closeModal={() => {
            setIsModalOpen(false)
            history.replace(
              { pathname: generatePath(ROUTES.clientDetails, { clientId }) },
              { offset: state?.offset, currentPage: state?.currentPage }
            )
          }}
          message={state?.messageCode || IMAGE_UPLOAD_DEFAULT_ERROR_MESSAGE}
        />
      </ModalContainer>
    </section>
  )
}

export default ClientDetails
