import { IRequest } from '../interfaces/IRequest'
import { api, getAuthHeader } from '../utils/api'
import { IUser, IUserActivity } from '../interfaces/IUsers'
import { API_PATHS } from '../enums/apiPaths'

export function getUsersOptions(): Promise<{
  body: { data: IUser[] }
}> {
  const params: IRequest = {
    endpoint: `${API_PATHS.users}`,
    method: 'GET',
    headers: getAuthHeader(),
  }

  return api(params)
}

export const getUserActivity = async (
  userId?: number | undefined,
  queryParams: string = ''
): Promise<IUserActivity> => {
  if (!userId) {
    return {} as IUserActivity
  }

  const params: IRequest = {
    endpoint: `${API_PATHS.users}/${userId}${API_PATHS.activity}${
      queryParams ? '?' : ''
    }${queryParams}`,
  }

  const { body } = await api(params)

  return body
}
