import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useFormContext } from 'react-hook-form'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'
import Button from '../common/ui/Button'
import { newVisitReportActions } from '../../store/reducers/newVisitReportReducer'
import { mockNewVisitReportFormInitialState } from '../../__mocks__/dataMock'
import { STATUS } from '../../enums/common'
import { objEqual } from '../../utils/helpers'
import { TStatus } from '../../interfaces/INavigation'
import { ROUTES } from '../../enums/routes'
import { useClientsDetails } from '../../hooks/clientDetails/UseClientDetails'
import { VISIT_REPORT_CURRENT_VERSION } from '../../constants/visitReport'

export const VisitReportFormNav: FC<{
  setIsModalOpen: Dispatch<SetStateAction<boolean>>
  formIsChanged: boolean
  status: TStatus
}> = ({ setIsModalOpen, status, formIsChanged }) => {
  const history = useHistory()
  const { clientId } = useParams()
  const dispatch = useDispatch()
  const { getValues } = useFormContext()
  const initialFormData = {
    ...mockNewVisitReportFormInitialState,
    visitDate: getValues().visitDate ? new Date(getValues().visitDate) : '',
    duration: getValues().duration,
    productNeoComment: getValues().productNeoComment,
    productVisionComment: getValues().productVisionComment,
    productLibreComment: getValues().productLibreComment,
    servicesQuizComment: getValues().servicesQuizComment,
    servicesCoachComment: getValues().servicesCoachComment,
    eventsGroupPatientComment: getValues().eventsGroupPatientComment,
    eventsEasyCollectComment: getValues().eventsEasyCollectComment,
    eventsSsmComment: getValues().eventsSsmComment,
    eventsGroupConvergenceComment: getValues().eventsGroupConvergenceComment,
  }
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)

  const handleModal = () => {
    const values = getValues()

    const {
      formations: __,
      ...initialFormDataWithoutFormations
    } = initialFormData

    setIsModalOpen(
      !objEqual(
        { ...values, ...{ userId: '', version: VISIT_REPORT_CURRENT_VERSION } },
        { ...initialFormDataWithoutFormations }
      )
    )
  }

  useEffect(handleModal, [handleModal, formIsChanged])

  const { status: clientStatus } = useClientsDetails(clientId)

  useEffect(() => {
    const submitDisabled =
      status === STATUS.PENDING ||
      status === STATUS.SUCCESS ||
      status === STATUS.DANGER ||
      clientStatus === STATUS.DANGER

    setIsSubmitDisabled(submitDisabled)
  }, [status, clientStatus, formIsChanged])

  return (
    <div className="clientFileButtonsWrapper dFlex">
      <DynamicFormattedMessage
        id="form.button.cancel"
        tag={Button}
        onClick={() => {
          handleModal()
          setTimeout(() => {
            dispatch(
              newVisitReportActions.setNewVisitReport(
                mockNewVisitReportFormInitialState
              )
            )
          }, 1000)
          return clientStatus === STATUS.DANGER
            ? history.replace(generatePath(ROUTES.dashboard))
            : window.history.state
            ? window.history.back()
            : history.replace(
                generatePath(ROUTES.clientDetails, {
                  clientId,
                })
              )
        }}
        className="btn btnOutline linkUnstyled withBackgroundColorDefault px15 py15 cancelVisitReport"
        data-qa="backButton"
      />
      <DynamicFormattedMessage
        id="form.button.newVisitReport.submit"
        tag={Button}
        type="submit"
        className={`btn btnPrimary px15 py15 submitVisitReport ${
          isSubmitDisabled ? 'btnDisabled' : ''
        }`}
        data-qa="submitComment"
        disabled={isSubmitDisabled}
      />
    </div>
  )
}
