import React, { FC, useMemo, useState } from 'react'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'
import { STATUS } from '../../enums/common'
import Loader from '../loader/Loader'
import Alerts from '../alerts/Alerts'
import { IActivityData } from '../../interfaces/IUsers'
import { VisitReportsContactTypesForm } from './VisitReportsContactTypesForm'

export const VisitReports: FC<IActivityData> = ({
  data,
  status,
  message,
  contactTypes,
  setShouldRefetchUserActivity,
}) => {
  const { visitReportsSummary } = data
  const [isErrorMessageVisible, setIsErrorMessageVisible] = useState(false)

  const showLoader = useMemo(
    () => status === STATUS.PENDING && contactTypes?.status === STATUS.PENDING,
    [status, contactTypes]
  )

  return (
    <section className="visitReports col12 hasBorderRadius px15 py1 mx0 mb2">
      <Alerts status={status} message={message} />
      <div className="row mx0 mb05 alignItemsCenter">
        <DynamicFormattedMessage
          id="dashboard.visitReports.label"
          tag="h3"
          className="textPrimary fontRegular"
        />
        <DynamicFormattedMessage
          id="form.validation.minOneField.error"
          tag="p"
          className="textDanger fontRegular pl2"
          shouldRender={isErrorMessageVisible}
        />
      </div>
      {showLoader && <Loader />}
      <div className="row mx0 alignItemsCenter justifyContentBetween flexWrap">
        {!!contactTypes?.data.length && (
          <VisitReportsContactTypesForm
            contactTypes={contactTypes}
            setIsErrorMessageVisible={setIsErrorMessageVisible}
            setShouldRefetchUserActivity={setShouldRefetchUserActivity!}
          />
        )}

        {visitReportsSummary && (
          <ul className="px0 unstyledList dFlex alignItemsCenter justifyContentStart flexWrap visitReportsList">
            <li className="px05 py05 mr25 my05 dFlex textSecondary fontMedium">
              <DynamicFormattedMessage
                id="dashboard.visitReports.month.label"
                tag="h3"
                className=""
              >
                <span className="monthValue pl05">
                  {visitReportsSummary?.currentMonth || '-'}
                </span>
              </DynamicFormattedMessage>
            </li>
            <li className="px05 py05 mr25 my05 dFlex textSecondary fontMedium">
              <DynamicFormattedMessage
                id="dashboard.visitReports.trim.label"
                tag="h3"
                className=""
              >
                <span className=" pl05">
                  {visitReportsSummary?.currentTrimester || '-'}
                </span>
              </DynamicFormattedMessage>
            </li>
            <li className="px05 py05 my05 dFlex textSecondary fontMedium">
              <DynamicFormattedMessage
                id="dashboard.visitReports.year.label"
                tag="h3"
                className=""
              >
                <span className=" pl05">
                  {visitReportsSummary?.currentYear || '-'}
                </span>
              </DynamicFormattedMessage>
            </li>
          </ul>
        )}
      </div>
    </section>
  )
}
