import React, { FC } from 'react'
import DynamicFormattedMessage from '../../../common/ui/DynamicFormattedMessage'
import { InformationDataRow } from './InformationDataRow'
import { IInformationTarget } from '../../../../interfaces/IVisitReports'

export const InformationTarget: FC<IInformationTarget> = ({
  numberOfAppels,
  numberOfVisite,
}) => {
  return (
    <InformationDataRow label="target">
      <div className="colMd9 py05">
        <div className="mb1">
          <DynamicFormattedMessage
            id="form.visitReport.information.noVisits.label"
            tag="p"
            className="dInlineBlock fontMedium pr15"
          />
          <p className="dInlineBlock">{numberOfVisite || '-'}</p>
        </div>
        <div className="mb1">
          <DynamicFormattedMessage
            id="form.visitReport.information.noCalls.label"
            tag="p"
            className="dInlineBlock fontMedium pr15"
          />
          <p className="dInlineBlock">{numberOfAppels || '-'}</p>
        </div>
      </div>
    </InformationDataRow>
  )
}
