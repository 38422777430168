import React, { FC } from 'react'
import { NavLink } from 'react-router-dom'
import { TNavbarToggleButtonProps } from '../../interfaces/INavigation'
import { ROUTES } from '../../enums/routes'
import { concentrixLogoWhite } from '../../assets/images/images'
import { LoggedUser } from '../common/ui/LoggedUser'

export const NavbarToggleButton: FC<TNavbarToggleButtonProps> = ({
  isMenuOpen,
  toggleMenu,
}) => {
  return (
    <div className="navbarToggleWrapper">
      <div
        className={`navbarToggleButton ${
          isMenuOpen ? 'navbarToggleIsActive' : ''
        }`}
        onClick={() => toggleMenu()}
        data-qa="toggleButton"
      />
      <div className="navbarBrandWrapper mb25">
        <NavLink
          to={ROUTES.dashboard}
          className="navbarBrand"
          data-qa="linkLogo"
        >
          <img src={concentrixLogoWhite} className="dBlock" alt="Logo" />
        </NavLink>
        <LoggedUser />
      </div>
    </div>
  )
}
