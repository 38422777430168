import { format, utcToZonedTime } from 'date-fns-tz'
import {
  IEngagementHistory,
  IHistoryTableRow,
  IOrderHistory,
  IRecapHistory,
  IRecapProducts,
  IRecapProductSum,
  IRecapSummary,
} from '../interfaces/IHistory'
import { HISTORY_DATE_FORMAT, HISTORY_DATE_TIME_FORMAT } from '../enums/common'
import {
  KEY_CURRENT_YEAR,
  KEY_PREVIOUS_YEAR,
  TYPE_PRODUCT,
  TYPE_SUMMARY,
} from '../constants/tables'
import { roundTwoDecimals, currentYearValue } from './helpers'

export const prepareEngagementHistoryResponse = (data: IEngagementHistory[]) =>
  data.map(({ contractLabel, category, date, discountRate, details }) => ({
    contractLabel,
    category,
    date: date
      ? format(utcToZonedTime(date, 'UTC'), HISTORY_DATE_TIME_FORMAT)
      : '',
    discountRate: `${discountRate}%`,
    details,
  }))

export const prepareOrderHistoryResponse = (data: IOrderHistory[]) =>
  data.map(({ date, deliveryDate, orderNumber, source, amount }) => ({
    date: date ? format(utcToZonedTime(date, 'UTC'), HISTORY_DATE_FORMAT) : '',
    deliveryDate: deliveryDate
      ? format(utcToZonedTime(deliveryDate, 'UTC'), HISTORY_DATE_FORMAT)
      : '',
    orderNumber,
    source,
    amount,
  }))

export const processSummaryData = (summary: IRecapSummary[]) =>
  summary.map(({ year, periods }) => {
    const key = year === currentYearValue ? KEY_CURRENT_YEAR : KEY_PREVIOUS_YEAR

    return {
      [key]: periods.reduce((previousValue, { name, quantity, value }) => {
        // eslint-disable-next-line no-param-reassign
        previousValue[name] = {
          quantity:
            (previousValue[name]?.quantity || 0) + roundTwoDecimals(quantity),
          value: (previousValue[name]?.value || 0) + roundTwoDecimals(value),
        }

        return previousValue
      }, {} as IRecapProductSum),
    }
  })

export const processProductsData = (products: IRecapProducts[]) =>
  products.map(({ name, summary }) => ({
    name,
    type: TYPE_PRODUCT,
    ...Object.assign({}, ...processSummaryData(summary)),
  }))

export const processRecapHistoryTableData = (data: IRecapHistory[]) => {
  const tableRows: IHistoryTableRow[] = []
  if (!(data && data.length !== 0)) {
    return []
  }

  data.forEach(({ name, summary, products }: IRecapHistory) => {
    tableRows.push(
      {
        name,
        type: TYPE_SUMMARY,
        ...Object.assign({}, ...processSummaryData(summary)),
      },
      ...processProductsData(products)
    )
  })

  return tableRows
}
