import React, { FC, useState } from 'react'
import {
  IVisitReportInformation,
  SecondPanelProps,
} from '../../../interfaces/IVisitReports'
import { useUserMedia } from '../../../hooks/UseUserMedia'
import { VisitReportSection } from './commonComponents/VisitReportSection'
import {
  VISIT_EVENTS,
  VISIT_EVENTS_ROWS,
  VISIT_EVENTS_TYPE_1_ROW,
  VISIT_SECOND_PANEL_FIRST_SECTIONS,
  VISIT_EVENTS_TYPE_1,
} from '../../../constants/visitReport'
import { VisitReportSectionRow } from './commonComponents/VisitReportSectionRow'
import { VisitReportEventsExtraFields } from './secondPanelComponents/VisitReportEventsExtraFields'
import { UserMediaSection } from '../../common/ui/UserMediaSection'
import VisitReportFormationTable from '../../clientVisitReport/VisitReportFormationTable'

export const VisitReportSecondPanel: FC<SecondPanelProps> = ({
  defaultData,
  updateForm,
  informationData,
  clientId,
  onFormationChange,
  errorTrigger,
  onFormationSubmit,
}) => {
  const [isCameraOpen, setIsCameraOpen] = useState(false)
  const camera = useUserMedia()

  return (
    <>
      <UserMediaSection
        camera={camera}
        isCameraOpen={isCameraOpen}
        setIsCameraOpen={setIsCameraOpen}
      />
      {clientId && (
        <VisitReportFormationTable
          onFormationChange={onFormationChange}
          errorTrigger={errorTrigger}
          onFormationSubmit={onFormationSubmit}
        />
      )}
      {VISIT_SECOND_PANEL_FIRST_SECTIONS.map(({ section, rows }) => (
        <VisitReportSection
          key={section}
          section={section}
          rows={rows}
          camera={camera}
          isCameraOpen={isCameraOpen}
          defaultData={defaultData}
          updateForm={updateForm}
          informationData={informationData as IVisitReportInformation}
        />
      ))}
      <VisitReportSection
        section={VISIT_EVENTS}
        rows={VISIT_EVENTS_ROWS}
        camera={camera}
        isCameraOpen={isCameraOpen}
        defaultData={defaultData}
        updateForm={updateForm}
        informationData={informationData as IVisitReportInformation}
      >
        <VisitReportSectionRow
          data={VISIT_EVENTS_TYPE_1_ROW}
          customClass={VISIT_EVENTS_TYPE_1}
          camera={camera}
          isCameraOpen={isCameraOpen}
          defaultData={defaultData}
          updateForm={updateForm}
          informationData={informationData as IVisitReportInformation}
        >
          <VisitReportEventsExtraFields
            defaultData={defaultData}
            dataType={VISIT_EVENTS_TYPE_1}
          />
        </VisitReportSectionRow>
      </VisitReportSection>
    </>
  )
}
