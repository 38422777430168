import { useDispatch, useSelector } from 'react-redux'
import { ChangeEvent, useMemo } from 'react'
import { sharedDataSelectors } from '../../store/selectors'
import { useGetUsers } from '../UseGetUsers'
import { sharedDataActions } from '../../store/reducers/sharedDataReducer'
import { getJwtUserDetails, userIsAdmin } from '../../utils/api'
import { getFullName } from '../../utils/helpers'

export const useUsersDashboardHook = () => {
  const dispatch = useDispatch()
  const jwt = getJwtUserDetails()
  const userSavedData = useSelector(sharedDataSelectors.getUserSavedData)
  const {
    users,
    usersStatusPending,
    isUserNotAvailableError,
    isUsersLoadingError,
    setIsUserNotAvailableError,
    setInitialLoad,
  } = useGetUsers()
  const isAdmin = userIsAdmin()
  const usersData = useSelector(sharedDataSelectors.getUsers)

  const statusPending = useMemo(() => (isAdmin ? usersStatusPending : false), [
    isAdmin,
    usersStatusPending,
  ])
  const userId = useMemo(
    () => (isAdmin ? userSavedData.selectedUser : jwt?.id?.toString()),
    [isAdmin, userSavedData.selectedUser, jwt]
  )

  const selectedUserName = useMemo(() => {
    if (!isAdmin || isUsersLoadingError) return userSavedData.fullName

    const user = usersData.filter(
      (u) => u.id?.toString() === userSavedData.selectedUser
    )

    return user.length ? getFullName(user[0], true) : ''
  }, [usersData, userSavedData, isAdmin, isUsersLoadingError])

  const onUserChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setInitialLoad(false)
    setIsUserNotAvailableError(false)
    dispatch(sharedDataActions.setSelectedUser(event.target.value))
  }

  return {
    users,
    userId,
    statusPending,
    onUserChange,
    selectedUserName,
    isUsersLoadingError,
    isUserNotAvailableError,
    setIsUserNotAvailableError,
  }
}
