import {
  DANGER_COLOR,
  OFFLINE_COLOR,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
} from './colors'

export const BASE_URL = 'https://www.google.ro/maps/dir/'
export const ZOOM_VALUE = '15z'
export const MAX_MAP_POINTS = 10
export const MAP_TOOLTIP_ONLINE = {
  color: PRIMARY_COLOR,
  label: 'map.mapFilters.mapDirections.info',
  backgroundColor: SECONDARY_COLOR,
}
export const MAP_TOOLTIP_OFFLINE = {
  color: OFFLINE_COLOR,
  label: 'map.mapFilters.mapDirectionsOffline.info',
  backgroundColor: DANGER_COLOR,
}

export const FIELD_ACTION_DATE = 'date'
export const FIELD_ACTION_APPOINTMENT = 'appointment'
export const FIELD_ACTION_VISITE_REPORT = 'visiteReport'
export const NO_ACTION_ERROR = 'noActionError'
