import { FieldName, SetFieldValue, SetValueConfig } from 'react-hook-form'
import { ChangeEvent } from 'react'
import { DYNAMIC_BUTTONS_INPUT_TYPE } from '../../enums/common'
import { TDynamicButtonsInputType } from '../../interfaces/ICommonComponents'

export const useDynamicButtons = (
  name: string,
  inputType: TDynamicButtonsInputType,
  multipleCheck: boolean,
  onChange: (value: ChangeEvent<HTMLInputElement>) => void,
  setValue: (
    name: FieldName<Record<string, any>>,
    value: SetFieldValue<Record<string, any>>,
    config?: SetValueConfig
  ) => void
) => {
  const avoidMultipleCheck = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.checked) setValue(name, [event.currentTarget.value])
  }

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event)
    if (inputType === DYNAMIC_BUTTONS_INPUT_TYPE.checkbox && !multipleCheck) {
      avoidMultipleCheck(event)
    }
  }

  return {
    handleOnChange,
  }
}
