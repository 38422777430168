import React from 'react'
import { handleHtmlContent } from '../../../../utils/helpers'
import { InformationDataRow } from './InformationDataRow'

export const InformationComment = ({
  label,
  comment,
  customClass = '',
}: {
  label: string
  comment: string
  customClass?: string
}) => {
  return (
    <InformationDataRow label={label} customClass={customClass}>
      {(comment && (
        <div
          className="colMd9 py05 wordBreakAll"
          dangerouslySetInnerHTML={handleHtmlContent(comment)}
        />
      )) || <span className="px15">-</span>}
    </InformationDataRow>
  )
}
