import React, { FC, useState } from 'react'
import { usePosition } from 'use-position'
import { MAP } from '../constants/routes'
import { PageTitleSection } from '../components/common/ui/PageTitleSection'
import { ClientListMap } from '../components/map/ClientListMap'
import Loader from '../components/loader/Loader'
import Alerts from '../components/alerts/Alerts'
import { STATUS } from '../enums/common'
import { MapSearchSection } from '../components/map/MapSearchSection'
import { PlaceholderMessage } from '../components/common/ui/PlaceholderMessage'
import { MapFiltersForm } from '../components/forms/MapFiltersForm'
import { StickyWrapper } from '../components/common/ui/StickyWrapper'
import { useLoadClientsHook } from '../hooks/UseLoadClientsHook'
import { ROUTES } from '../enums/routes'
import { MAP_FORM_KEY } from '../constants/filters'

const Map: FC = () => {
  const {
    filters,
    status,
    message,
    setFilters,
    changePage,
    fieldsData,
    offset,
    currentPage,
    waitToLoadClientData,
    placeholderMessageProps,
    clients,
    search,
    getAccountsPortfolioRoute,
    backRoute,
  } = useLoadClientsHook()

  const [mapFormKey, setMapFormKey] = useState(MAP_FORM_KEY)

  const geolocation = usePosition()
  const { latitude, longitude } = geolocation
  const mapProps = {
    clients,
    latitude,
    longitude,
    hasSearchData: !!filters.search || !!search,
    backRoute: backRoute ?? '',
    offset,
    currentPage,
  }

  const resetMap = () => {
    setMapFormKey(`${MAP_FORM_KEY}Reset`)
  }

  return (
    <section className="pageContentWrapper portfolioPage pl75">
      <div className="dFlex flexColumn px3 py3">
        <PageTitleSection pageName={MAP} />
        <StickyWrapper>
          <Alerts status={status} message={message} />
        </StickyWrapper>
        <MapSearchSection
          setFilters={setFilters}
          filters={filters}
          fieldsData={fieldsData}
          changePage={changePage}
          routes={{
            accountsPortfolio: getAccountsPortfolioRoute(),
          }}
          resetRoute={ROUTES.map}
          callback={resetMap}
        />
        <MapFiltersForm
          key={mapFormKey}
          setFilters={setFilters}
          filters={filters}
          clients={clients}
          fieldsData={fieldsData}
          changePage={changePage}
        />
        {(waitToLoadClientData || (status && status === STATUS.PENDING)) && (
          <Loader />
        )}
        {clients && clients.length > 0 && <ClientListMap {...mapProps} />}
        {!waitToLoadClientData && (
          <PlaceholderMessage {...placeholderMessageProps} />
        )}
      </div>
    </section>
  )
}

export default Map
